import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DefaultTabPageComponent } from './default-tab-page.component';
import { SkeletonLoaderModule } from '../skeleton-loader/skeleton-loader.module';
import { HomeCardModule } from '../home-card/home-card.module';

@NgModule({
  declarations: [DefaultTabPageComponent],
  imports: [CommonModule, SkeletonLoaderModule, HomeCardModule],
  exports: [DefaultTabPageComponent],
})
export class DefaultTabPageModule {}
