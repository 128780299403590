import { AbstractControl, ValidatorFn } from '@angular/forms';

/**
 * The function return the ValidatorFn for email.
 * The regular expression describes:
 * Any combination of letters (upper and lower case), numbers, underscores, period, plus, and hyphen.
 *
 * @return ValidatorFn
 */

export function emailValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    const value = control.value;

    if (value && !emailPattern.test(value)) {
      return { email: true };
    }

    return null;
  };
}
