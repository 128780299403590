import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EmptyObjectService {
  isEmptyObject(object: any): boolean {
    return (
      object &&
      Object.keys(object).length === 0 &&
      Object.getPrototypeOf(object) === Object.prototype
    );
  }
}
