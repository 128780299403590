import { BaseModel } from './base.model';
import { FocusValueModel } from './focus-value.model';

export class FocusModel extends BaseModel {
  key: string;
  value: FocusValueModel[];

  constructor(data: any = {}) {
    super();
    this.key = data.key;
    this.value = FocusValueModel.transformCollection(data.value || []);
  }

  static override transform(data: any): FocusModel {
    return new FocusModel(data);
  }

  sortByName(): FocusModel {
    this.sortBy<FocusValueModel>('name', 'asc', this.value);

    return this;
  }

  protected sortBy<T>(
    propertyName: string,
    order: 'asc' | 'desc',
    items: T[]
  ): T[] {
    items.sort((a: any, b: any) => {
      if (order === 'asc' ? a.name < b.name : a.name > b.name) {
        return -1;
      }
      if (order === 'asc' ? a.name > b.name : a.name < b.name) {
        return 1;
      }
      return 0;
    });

    return items;
  }
}
