import { ActivitiesListModel } from '../../models/activities-list.model';
import {
  EVENT_MEETING_ACTIVITY_KEY,
  LOG_ACTION_ACTIVITY_KEY,
  MEETING_ACTIVITY_KEY,
} from '@bdam/shared';

export const getLogActionNavigationParams = (
  activity: ActivitiesListModel
): Record<string, string> | undefined => {
  if (activity.activityKey === LOG_ACTION_ACTIVITY_KEY) {
    return { logActionId: `${activity.id}` };
  }

  if (activity.activityKey === MEETING_ACTIVITY_KEY) {
    return { meetingId: `${activity.id}` };
  }

  if (activity.activityKey === EVENT_MEETING_ACTIVITY_KEY) {
    return { eventMeetingId: `${activity.id}` };
  }

  return void 0;
};
