import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  setRecentSearchesFor(name: string, value: string): void {
    const str = localStorage.getItem('recentSearches');
    let obj: { [key: string]: string[] } = {};

    if (str) {
      const data = JSON.parse(str);

      if (data[name]) {
        const uniqueList = new Set(data[name]);
        uniqueList.add(value);
        data[name] = Array.from(uniqueList);
      } else {
        data[name] = [value];
      }

      obj = data;
    } else {
      obj[name] = [value];
    }

    localStorage.setItem('recentSearches', JSON.stringify(obj));
  }

  getResentSearchesFor(name: string): string[] {
    const str = localStorage.getItem('recentSearches');

    if (str) {
      const data = JSON.parse(str);
      return data[name] ? data[name] : [];
    }

    return [];
  }

  clearRecentSearchesFor(name: string): void {
    const str = localStorage.getItem('recentSearches');
    if (str) {
      const data = JSON.parse(str);
      data[name] = [];
      localStorage.setItem('recentSearches', JSON.stringify(data));
    }
  }

  setUrl(pageUrl: string, url: string): void {
    localStorage.setItem(pageUrl, url);
  }

  getUrl(pageUrl: string): string {
    const url = localStorage.getItem(pageUrl);

    return url ? url : '';
  }

  removeUrl(pageUrl: string): void {
    localStorage.removeItem(pageUrl);
  }

  isLocalStorageEmpty(): boolean {
    return localStorage.length === 0;
  }

  setRecentReviewedItemsFor<T>(name: string, uId: string, item: T): void {
    const str = localStorage.getItem(name);
    let collection: T[] = [];
    str && (collection = JSON.parse(str));
    // @ts-ignore
    const index = collection.findIndex((coll: T) => coll[uId] === item[uId]);
    index === -1 && collection.push(item);

    localStorage.setItem(name, JSON.stringify(collection));
  }

  getRecentReviewedFor<T>(name: string): T[] {
    const str = localStorage.getItem(name);

    return str ? JSON.parse(str) : [];
  }

  clearRecentReviewedFor(name: string): void {
    localStorage.removeItem(name);
  }
}
