import { TimeZoneModel } from './time-zone.model';
import { BaseModel } from './base.model';

export class UserPreferencesModel extends BaseModel {
  culture: string;
  currency: string;
  currencyRate: number;
  language: string;
  shortDatePattern: string;
  shortTimePattern: string;
  showCurrencySymbol: boolean;
  dateTimePattern: string;
  dstTimeZone: TimeZoneModel;

  constructor(data: any = {}) {
    super();
    this.culture = data.culture;
    this.currency = data.currency;
    this.currencyRate = data.currencyRate;
    this.language = data.language;
    this.shortDatePattern = data.shortDatePattern?.toUpperCase();
    this.shortTimePattern = data.shortTimePattern;
    this.dateTimePattern = this.formattedDateTime();
    this.showCurrencySymbol = data.showCurrencySymbol;
    this.dstTimeZone = TimeZoneModel.transform(data.dstTimeZone);
  }

  static override transform(data: any): UserPreferencesModel {
    return new UserPreferencesModel(data);
  }

  private formattedDateTime(): string {
    const timePatternParts = this.shortTimePattern?.split(' ');
    let index = -1;

    if (timePatternParts === undefined) {
      return `${this.shortDatePattern}`;
    }

    index = timePatternParts.findIndex((part: string) => part === 'tt');

    if (index > -1) {
      timePatternParts.splice(index, 1, 'a');
      return `${this.shortDatePattern} ${timePatternParts.join(' ')}`;
    }

    return `${this.shortDatePattern} ${timePatternParts.join(' ')}`;
  }
}
