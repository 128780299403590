<div class="list-card-container">
  <ng-container *ngIf="list; else loading">
    <spr-cards>
      <div class="card-container">
        <div>
          <div class="list-info">
          <span class="list-name" [style.font-size]="!showInlineCount ? '22px': '16px'">
            {{ list.name }}
          </span>
            <span class="count" *ngIf="showInlineCount">{{ list.membersCount }}</span>
          </div>

          <div class="shared-info" *ngIf="isShared">
            <span>Shared by: </span>
            <span>{{ list.ownerFullName }}</span>
          </div>
        </div>

        <i (click)="toggleFavoriteStatus(); $event.stopPropagation();"
           [class]="list.favorite ? 'icon-star-full' : 'icon-star-empty'"></i>
      </div>

      <div class="total-count" *ngIf="!showInlineCount">
        {{ list.membersCount }} {{ itemsType }}
      </div>
    </spr-cards>
  </ng-container>

  <ng-template #loading>
    <app-skeleton-loader [size]="'middle'"
                         [numberOfRepetitions]="1"></app-skeleton-loader>
  </ng-template>

</div>
