import { BaseModel } from './base.model';
import { AccountModel } from './account.model';
import { ContactModel } from './contact.model';
import { LogActionAlertRecipient } from './log-action-alert.model';
import { TickerModel } from './ticker.model';

export interface Recipient {
  id: string;
  firstName: string;
  lastName: string;
  checked: boolean;
}
export interface LogAction {
  dateTimeUtc: string;
  duration: number | undefined;
  typeId: number | null;
  comment: string;
  creatorId: number | null;
  creatorTeamId: number | null;
  alertUserIds: number[];
  securitiesIds: number[];
  securitiesGuids: string[];
  recipients?: Recipient[];
  accounts: {
    prId?: number | undefined;
    crmId?: number | undefined;
  }[];
  contacts: {
    prId?: number | undefined;
    crmId?: number | undefined;
  }[];
}

export class LogActionModel extends BaseModel implements LogAction {
  dateTimeUtc: string;
  duration: number | undefined; // minutes
  typeId: number | null;
  comment: string;
  creatorId: number | null;
  creatorTeamId: number | null;
  alertUserIds: number[];
  securitiesIds: number[];
  securitiesGuids: string[];
  accounts: {
    prId?: number | undefined;
    crmId?: number | undefined;
  }[];
  contacts: {
    prId?: number | undefined;
    crmId?: number | undefined;
  }[];

  constructor(data: Partial<LogAction>) {
    super();

    this.dateTimeUtc = data?.dateTimeUtc || '';
    this.duration = data?.duration || void 0;
    this.typeId = data?.typeId || null;
    this.comment = data?.comment || '';
    this.creatorId = data?.creatorId || null;
    this.creatorTeamId = data?.creatorTeamId || null;
    this.alertUserIds = data?.alertUserIds || [];
    this.securitiesIds = data?.securitiesIds || [];
    this.securitiesGuids = data?.securitiesGuids || [];
    this.accounts = data?.accounts || [];
    this.contacts = data?.contacts || [];
  }

  setAccounts(values: AccountModel[]): void {
    const accountIds = this.getAccountIds(values);
    this.accounts =
      accountIds?.map((a) => ({
        prId: a.prCompanyID || void 0,
        crmId: a.crmAccountID || void 0,
      })) || [];
  }

  setContacts(values: ContactModel[]): void {
    const contactsIds = this.getContactsIds(values);

    this.contacts = contactsIds?.map((c) => ({
      prId: c.prContactID || void 0,
      crmId: c.crmContactID || void 0,
    }));
  }

  setAlertRecipients(recipients: LogActionAlertRecipient[]): void {
    this.alertUserIds = recipients?.map((r) => r.id);
  }

  setSecurities(securities: TickerModel[]): void {
    this.securitiesIds = securities
      ?.filter((s) => !!s.securityId)
      .map((s) => s.securityId);
    this.securitiesGuids = securities
      ?.filter((s) => !!s.securityGuid)
      .map((s) => s.securityGuid);
  }

  setPublicComment(comment: string): void {
    this.comment = comment;
  }

  asPOSTPayload(): LogAction {
    return {
      dateTimeUtc: this.dateTimeUtc,
      duration: this.duration,
      typeId: this.typeId,
      comment: this.comment,
      creatorId: this.creatorId,
      creatorTeamId: this.creatorTeamId,
      alertUserIds: [...this.alertUserIds],
      securitiesIds: [...this.securitiesIds],
      securitiesGuids: [...this.securitiesGuids],
      accounts: [...this.accounts],
      contacts: [...this.contacts],
    };
  }

  valid(): boolean {
    const contactsAndAccountsValid =
      !!this.contacts.length || !!this.accounts.length;
    return (
      !!this.dateTimeUtc &&
      !!this.duration &&
      !!this.typeId &&
      contactsAndAccountsValid
    );
  }

  private getAccountIds(values: AccountModel[]) {
    return values.map((item: AccountModel) => {
      const currentItem: string[] = item.id.split('-');

      return {
        prCompanyID: +currentItem[0] || null,
        crmAccountID: +currentItem[1] || null,
      };
    });
  }

  private getContactsIds(values: ContactModel[]) {
    return values.map((item: ContactModel) => {
      const currentItem: string[] = item.id.split('-');

      return {
        prContactID: +currentItem[0] || null,
        crmContactID: +currentItem[1] || null,
      };
    });
  }
}
