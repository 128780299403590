import { BaseModel } from './base.model';
import { FocusModel } from './focus.model';
import { AccountBaseFocus, DERIVATIVE_FOCUS } from '@bdam/shared';
import { convertFocusData } from '../shared/utils/convert-focus-data.utils';

export class AccountDerivativeFocusModel
  extends BaseModel
  implements AccountBaseFocus
{
  companyId: number;
  source: number;
  focusName: string;
  focuses: FocusModel[];

  constructor(data: any = {}) {
    super();
    this.companyId = data.companyId;
    this.source = data.source;
    this.focusName = data.focusName;
    this.focuses = convertFocusData(
      FocusModel.transformCollection(data.focuses || []),
      DERIVATIVE_FOCUS
    );
  }

  static override transform(data: any): AccountDerivativeFocusModel {
    return new AccountDerivativeFocusModel(data);
  }
}
