import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TickerComponent } from './ticker.component';
import {
  ButtonModule,
  CardsModule,
  CheckboxModule,
  IconModule,
  SPRFormsModule,
} from '@ipreo/ngx-sprinkles';
import { ReactiveFormsModule } from '@angular/forms';
import { SkeletonLoaderModule } from '../skeleton-loader/skeleton-loader.module';
import { ModalHeaderModule } from '../modal-header/modal-header.module';

@NgModule({
  declarations: [TickerComponent],
  imports: [
    CommonModule,
    CardsModule,
    SPRFormsModule,
    ButtonModule,
    CheckboxModule,
    IconModule,
    ReactiveFormsModule,
    SkeletonLoaderModule,
    ModalHeaderModule,
  ],
  exports: [TickerComponent],
})
export class TickerModule {}
