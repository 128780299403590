import { BaseModel } from './base.model';

export class MeetingTypeModel extends BaseModel {
  id: number;
  name: string;
  isDeleted: boolean;
  type: string;
  value: boolean;
  formControlName: string;

  constructor(data: any = {}) {
    super();
    this.id = data.id;
    this.name = data.name;
    this.type = 'checkbox';
    this.value = false;
    this.formControlName = data.name;
    this.isDeleted = data.isDeleted;
  }

  static override transform(data: any): MeetingTypeModel {
    return new MeetingTypeModel(data);
  }
}
