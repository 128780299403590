import { BaseModel } from './base.model';

export class AccountLocationModel extends BaseModel {
  countryId: number;
  country: string;
  stateId: number;
  state: string;
  cityName: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  postalCode: string;

  constructor(data: any = {}) {
    super();
    this.countryId = data.countryId;
    this.country = data.country;
    this.stateId = data.stateId;
    this.state = data.state;
    this.cityName = data.cityName;
    this.addressLine1 = data.addressLine1;
    this.addressLine2 = data.addressLine2;
    this.addressLine3 = data.addressLine3;
    this.postalCode = data.postalCode;
  }

  static override transform(data: any): AccountLocationModel {
    return new AccountLocationModel(data);
  }
}
