import { Component, Input } from '@angular/core';
import { BaseComponent } from '../../../../../base/base.component';
import { ICON_TYPES } from '../../../../constants/icon-types';
import { LinkCard } from '../../../../interfaces/link-card';

@Component({
  selector: 'app-profile-link-card',
  templateUrl: './profile-link-card.component.html',
  styleUrls: ['./profile-link-card.component.scss'],
})
export class ProfileLinkCardComponent extends BaseComponent {
  @Input() linkCard!: LinkCard;
  @Input() iconLink: string = ICON_TYPES.externalLink;
  @Input() backgroundColor = '#ffffff';
}
