import { Params } from '@angular/router';
import {
  IS_PARTICIPANT_KEY,
  MY_COVERAGE_KEY,
  ParamLengthCalculator,
} from '@bdam/shared';

export class SpecialFiltersParamLengthCalculatorDecorator
  implements ParamLengthCalculator
{
  constructor(private wrappedParamLengthCalculator: ParamLengthCalculator) {}

  calculateLength(params: Params): number {
    let selectedIdsLength: number =
      this.wrappedParamLengthCalculator.calculateLength(params);

    const isMyCoverage: boolean = params[MY_COVERAGE_KEY] || false;
    const isParticipant: boolean = params[IS_PARTICIPANT_KEY] || false;

    if (isMyCoverage) {
      selectedIdsLength++;
    }

    if (isParticipant) {
      selectedIdsLength++;
    }

    return selectedIdsLength;
  }
}
