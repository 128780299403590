<div class="account-card-container" *ngIf="account">
  <spr-cards>
    <div class="header flex-container">
      <div class="title">
        <span *ngIf="selected">(Selected)</span>
        {{ account.name }}
      </div>
      <div class="budges">
        <i class="icon-record-type" *ngIf="account.investorTypeId === accountIssuerTypes.issuer"></i>
        <spr-badge
          *ngIf="account.covered"
          class="small-budge"
          [size]="cupcakeSizes.md"
          [square]="true">C</spr-badge>
        <spr-badge
          *ngIf="account.prCompanyID || account.crmAccountID"
          class="middle-budge"
          [size]="cupcakeSizes.md"
          [square]="true"
          [ngClass]=" { 'middle-budge-bd-crm': account.prCompanyID && account.crmAccountID,
            'middle-budge-bd-only': account.prCompanyID && !account.crmAccountID,
            'middle-budge-crm-only': !account.prCompanyID && account.crmAccountID
          }"
        >
          <span
            class="middle-budge-bd"
            *ngIf="account.prCompanyID"
            [class.main-margin-small-right]="account.crmAccountID"
          >BD</span>
          <span
            class="middle-budge-crm"
            *ngIf="account.crmAccountID"
          >CRM</span>
        </spr-badge>
      </div>
    </div>

    <div class="content-container">
      <i class="icon-location"></i>
      <div class="ellipse-text">
        <span *ngIf="account.accountLocation.addressLine1">{{ account.accountLocation.addressLine1 }}, </span>
        <span *ngIf="account.accountLocation.country">{{ account.accountLocation.country }}, </span>
        <span>{{ account.accountLocation.state }}</span>
      </div>
    </div>

    <div class="footer flex-container">
      <div class="info-container flex-container">
        <i class="icon-coins"></i>
        <div>Assets ({{ authUser?.userPreferences.currency }}, mm)</div>
      </div>
      <div>{{ account.formattedTotalBestAssets }}</div>
    </div>
  </spr-cards>
</div>
