import { IconModule, SPRFormsModule } from '@ipreo/ngx-sprinkles';
import { ModalHeaderComponent } from './modal-header.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [ModalHeaderComponent],
  imports: [CommonModule, SPRFormsModule, ReactiveFormsModule, IconModule],
  exports: [ModalHeaderComponent],
})
export class ModalHeaderModule {}
