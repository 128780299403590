<div class="confirmation-modal-container">
  <div class="title" *ngIf="context.title">{{ context.title }}</div>
  <div class="text" *ngIf="context.text">{{ context.text }}</div>
  <div class="footer-buttons-set">
    <button
      class="button-neutral"
      (click)="onCancel()"
      spr-button
      *ngIf="context.cancelBtnText"
    >
      {{ context.cancelBtnText }}
    </button>
    <button
      class="button-primary"
      (click)="onOk()"
      spr-button
      *ngIf="context.okBtnText"
    >
      {{ context.okBtnText }}
    </button>
    <button
      class="button-delete"
      (click)="onOk()"
      spr-button
      *ngIf="context.deleteBtnText"
    >
      {{ context.deleteBtnText }}
    </button>
  </div>
</div>
