import { BaseModel } from './base.model';
import { FocusModel } from './focus.model';
import {
  AccountBaseFocus,
  FOCUS_ORDER_TEMPLATE_FOR_ACCOUNTS,
} from '@bdam/shared';
import { convertFocusData } from '../shared/utils/convert-focus-data.utils';

export class AccountEquityFocusModel
  extends BaseModel
  implements AccountBaseFocus
{
  companyId: number;
  source: number;
  focusName: string;
  focuses: FocusModel[];

  constructor(data: any = {}) {
    super();
    this.companyId = data.companyId;
    this.source = data.source;
    this.focusName = data.focusName;
    this.focuses = convertFocusData(
      FocusModel.transformCollection(data.focuses || []),
      FOCUS_ORDER_TEMPLATE_FOR_ACCOUNTS
    ).map((focus: FocusModel) => {
      if (focus.key === 'Industry') {
        focus.key = `${focus.key} Micro`;
      }

      return focus;
    });
  }

  static override transform(data: any): AccountEquityFocusModel {
    return new AccountEquityFocusModel(data);
  }
}
