import { HoldingSecurityItemCardComponent } from './holding-security-item-card.component';
import { CardsModule } from '@ipreo/ngx-sprinkles';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [HoldingSecurityItemCardComponent],
  imports: [CommonModule, CardsModule],
  exports: [HoldingSecurityItemCardComponent],
})
export class HoldingSecurityItemCardModule {}
