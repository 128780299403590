<spr-cards>
  <div class="card-area">
    <ng-container *ngTemplateOutlet="profileTextCard"></ng-container>
  </div>
  <form
    *ngIf="!isShowSupportTeamMessage"
    [formGroup]="profileForm"
    (ngSubmit)="(profileForm.valid)"
    novalidate
  >
    <div class="input-phone-number-container">
      <spr-input
        type="tel"
        [placeholder]="ADD_YOUR_PHONE_NUMBER_PLACEHOLDER"
        size="lg"
        formControlName="phoneNumber"
      ></spr-input>
    </div>
    <div class="input-email-container">
      <spr-input
        [placeholder]="ADD_YOUR_EMAIL_PLACEHOLDER"
        [type]="'email'"
        size="lg"
        formControlName="email"
      ></spr-input>
    </div>
    <div class="textarea-comment-container">
      <spr-textarea
        [placeholder]="SHARE_YOUR_ISSUE_OR_THOUGHTS_HERE_PLACEHOLDER"
        [charLimit]="'2000'"
        [height]="'7.5rem'"
        [color]="textAreaBorderColor"
        (focusout)="onTextareaFocusout()"
        formControlName="comment"
      ></spr-textarea>
    </div>
    <button
      spr-button
      class="form-button submit-button"
      [ngClass]="{ 'button-primary': !isProfileFormInvalid }"
      [disabled]="isProfileFormInvalid || isLoading"
      (click)="onSubmit()"
    >
      {{ SUBMIT_BTN }}
    </button>
  </form>
</spr-cards>

<ng-template #profileTextCard>
  <ng-content select="[slot=profile-text-card]"></ng-content>
</ng-template>
