import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BaseComponent } from '../../../../../base/base.component';
import { ProfileShareFeedbackData } from '../../../../interfaces/profile-share-feedback-data';
import { emailValidator } from '../../../../validators/email.validator';
import {
  ADD_YOUR_EMAIL_PLACEHOLDER,
  SHARE_FEEDBACK_BTN,
  SHARE_YOUR_FEEDBACK_OR_REQUEST_PLACEHOLDER,
  SUBJECT_PLACEHOLDER,
} from '@bdam/shared';

@Component({
  selector: 'app-profile-feedback-card',
  templateUrl: './profile-feedback-card.component.html',
  styleUrls: ['./profile-feedback-card.component.scss'],
})
export class ProfileFeedbackCardComponent extends BaseComponent {
  @Input() isLoading = false;
  @Input() set isShowFeedbackMessage(isShowFeedbackMessage: boolean) {
    this.localIsShowFeedbackMessage = isShowFeedbackMessage;

    if (isShowFeedbackMessage) {
      this.profileForm.reset();
    }
  }

  get isShowFeedbackMessage(): boolean {
    return this.localIsShowFeedbackMessage;
  }

  @Output() emitFormData = new EventEmitter<ProfileShareFeedbackData>();

  readonly SUBJECT_PLACEHOLDER = SUBJECT_PLACEHOLDER;
  readonly ADD_YOUR_EMAIL_PLACEHOLDER = ADD_YOUR_EMAIL_PLACEHOLDER;
  readonly SHARE_YOUR_FEEDBACK_OR_REQUEST_PLACEHOLDER =
    SHARE_YOUR_FEEDBACK_OR_REQUEST_PLACEHOLDER;
  readonly SHARE_FEEDBACK_BTN = SHARE_FEEDBACK_BTN;

  profileForm = this.formBuilder.group({
    subject: ['', Validators.required],
    email: ['', [Validators.required, Validators.email, emailValidator()]],
    comment: ['', Validators.required],
  });

  set textAreaBorderColor(textareaBorderColor: string) {
    this.localTextAreaBorderColor = textareaBorderColor;
  }

  get textAreaBorderColor(): string {
    return this.localTextAreaBorderColor;
  }

  get isProfileFormInvalid(): boolean {
    return this.profileForm.invalid;
  }

  private localTextAreaBorderColor = '';
  private localIsShowFeedbackMessage = false;

  constructor(private readonly formBuilder: FormBuilder) {
    super();
  }

  onTextareaFocusout(): void {
    this.textAreaBorderColor =
      this.profileForm.controls.comment.invalid &&
      this.profileForm.get('comment')?.value?.length === 0
        ? 'danger'
        : '';
  }

  onSubmit(): void {
    const data = { ...this.profileForm.value };

    if (this.isProfileFormInvalid) {
      return;
    }

    this.emitFormData.emit({
      emailAddress: data.email || '',
      subject: data.subject || '',
      body: data.comment || '',
    });
  }
}
