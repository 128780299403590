import { LocalStorageService } from '../../../services/helpers/local-storage/local-storage.service';
import { ACCOUNT_ISSUER_TYPES } from '../../constants/account-issuer-types.constant';
import { AuthService } from '../../../services/api/v1/auth.service';
import { BaseComponent } from '../../../base/base.component';
import { AccountModel } from '../../../models/account.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { UserModel } from '../../../models/user.model';

@Component({
  selector: 'app-account-card',
  templateUrl: './account-card.component.html',
  styleUrls: ['./account-card.component.scss'],
})
export class AccountCardComponent extends BaseComponent implements OnInit {
  @Input() account!: AccountModel;
  @Input() selected = false;

  accountIssuerTypes = ACCOUNT_ISSUER_TYPES;
  authUser!: UserModel;

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly localStorageService: LocalStorageService,
    private readonly authService: AuthService
  ) {
    super();
  }

  ngOnInit(): void {
    this.InitAuthUser();
  }

  private InitAuthUser(): void {
    this.authUser = this.authService.authUser;
  }
}
