import { BaseModel } from './base.model';

export class TickerSearchModel extends BaseModel {
  text: string;
  guids: string[];
  ids: number[];

  constructor(data: any = {}) {
    super();
    this.text = data.text;
    this.guids = data.guids || [];
    this.ids = data.ids || [];
  }

  static override transform(data: any): TickerSearchModel {
    return new TickerSearchModel(data);
  }

  getProcessedData(data: {
    text: string;
    ids: number[] | string[];
  }): TickerSearchModel {
    return new TickerSearchModel({
      text: data.text,
      ...this.setIdsByType(data.ids),
    });
  }

  protected setIdsByType(ids: string[] | number[]): {
    ids: number[];
    guids: string[];
  } {
    ids.forEach((id: string | number) =>
      typeof id === 'string' ? this.guids.push(id) : this.ids.push(id)
    );

    return { ids: this.ids, guids: this.guids };
  }
}
