import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../../../base/base.component';
import { FormBuilder, Validators } from '@angular/forms';
import { ProfileSupportTeamData } from '../../../../interfaces/profile-support-team-data';
import { phoneNumberValidator } from '../../../../validators/phone-number.validator';
import { emailValidator } from '../../../../validators/email.validator';
import {
  ADD_YOUR_EMAIL_PLACEHOLDER,
  ADD_YOUR_PHONE_NUMBER_PLACEHOLDER,
  SHARE_YOUR_ISSUE_OR_THOUGHTS_HERE_PLACEHOLDER,
  SUBMIT_BTN,
} from '@bdam/shared';

@Component({
  selector: 'app-profile-support-form-card',
  templateUrl: './profile-support-form-card.component.html',
  styleUrls: ['./profile-support-form-card.component.scss'],
})
export class ProfileSupportFormCardComponent extends BaseComponent {
  @Input() isLoading = false;
  @Input() set isShowSupportTeamMessage(isShowSupportTeamMessage: boolean) {
    this.localIsShowSupportTeamMessage = isShowSupportTeamMessage;

    if (isShowSupportTeamMessage) {
      this.profileForm.reset();
    }
  }

  get isShowSupportTeamMessage(): boolean {
    return this.localIsShowSupportTeamMessage;
  }

  @Output() emitFormData = new EventEmitter<ProfileSupportTeamData>();

  profileForm = this.formBuilder.group({
    phoneNumber: ['', [Validators.required, phoneNumberValidator()]],
    email: ['', [Validators.required, Validators.email, emailValidator()]],
    comment: ['', Validators.required],
  });

  readonly ADD_YOUR_PHONE_NUMBER_PLACEHOLDER =
    ADD_YOUR_PHONE_NUMBER_PLACEHOLDER;
  readonly ADD_YOUR_EMAIL_PLACEHOLDER = ADD_YOUR_EMAIL_PLACEHOLDER;
  readonly SHARE_YOUR_ISSUE_OR_THOUGHTS_HERE_PLACEHOLDER =
    SHARE_YOUR_ISSUE_OR_THOUGHTS_HERE_PLACEHOLDER;
  readonly SUBMIT_BTN = SUBMIT_BTN;

  set textAreaBorderColor(textareaBorderColor: string) {
    this.localTextAreaBorderColor = textareaBorderColor;
  }

  get textAreaBorderColor(): string {
    return this.localTextAreaBorderColor;
  }

  get isProfileFormInvalid(): boolean {
    return this.profileForm.invalid;
  }

  private localTextAreaBorderColor = '';
  private localIsShowSupportTeamMessage = false;

  constructor(private readonly formBuilder: FormBuilder) {
    super();
  }

  onTextareaFocusout(): void {
    this.textAreaBorderColor =
      this.profileForm.controls.comment.invalid &&
      this.profileForm.get('comment')?.value?.length === 0
        ? 'danger'
        : '';
  }

  onSubmit(): void {
    const data = { ...this.profileForm.value };

    if (this.isProfileFormInvalid) {
      return;
    }

    this.emitFormData.emit({
      emailAddress: data.email || '',
      phoneNumber: data.phoneNumber || '',
      body: data.comment || '',
    });
  }
}
