import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FindBdCrmIdNumberFromUrlService {
  getId(url: string): number | null {
    const urlWithoutParams = url.split('?')[0];
    const match = this.findIdWithDashSymbol(urlWithoutParams);

    if (match === null) {
      return null;
    }

    const numbers = match[1].split('-');

    //remove empty characters
    const filteredNumbersWithoutSymbols = numbers.filter(
      (number: string) => number.length !== 0
    );

    //reverse to always call the first element
    const number: number = parseInt(
      filteredNumbersWithoutSymbols.reverse()[0],
      10
    );

    if (isNaN(number)) {
      return null;
    }

    return number;
  }

  getIdWithDashSymbol(url: string): string | null {
    const urlWithoutParams = url.split('?')[0];
    const match = this.findIdWithDashSymbol(urlWithoutParams);

    if (match === null) {
      return null;
    }

    const id = match[1];

    if (id.indexOf('-') === -1) {
      return null;
    }

    return id;
  }

  /**
   * Get the regular expression that looks for any sequence of alphanumeric characters and hyphens between two slashes,
   * followed by any sequence of alphanumeric characters and hyphens until the end of the string
   *
   * @param {string} url
   * @return RegExpExecArray | null
   */

  private findIdWithDashSymbol(url: string): RegExpExecArray | null {
    const regex = /\/([\w-]*)\/[\w-]*$/;
    const match = regex.exec(url);

    return match;
  }
}
