import { InvestmentApproach } from './investment-approach.model';
import { AccountCoverageModel } from './account-coverage.model';
import { BaseModel } from './base.model';
import { IdModel } from './id.model';
import { UserModel } from './user.model';
import { AssetConfigInterface } from '../shared/interfaces/asset-config.interface';

export class AccountDetailsModel extends BaseModel {
  id: IdModel;
  name: string;
  type: string;
  accountCoverage: AccountCoverageModel[];
  url: string;
  phone: string;
  address: string;
  assets: string;
  totalCompanyAssets: string;
  totalStatedAssets: string;
  equityPortfolioTurnover: number;
  fixedIncomePortfolioTurnover: number;
  style: string;
  orientation: string;
  overview: string;
  investmentApproach: InvestmentApproach;
  currency: string;

  constructor(data: any = {}) {
    super();
    this.id = data.id;
    this.name = data.name;
    this.type = data.type;
    this.accountCoverage = AccountCoverageModel.transformCollection(
      data.accountCoverage || []
    );
    this.url = data.url;
    this.phone = data.phone;
    this.address = data.address;
    this.assets = data.assets;
    this.totalCompanyAssets = data.totalCompanyAssets
      ? this.formatByUserPreference(data.totalCompanyAssets)
      : '0';
    this.totalStatedAssets = data.totalStatedAssets
      ? this.formatByUserPreference(data.totalStatedAssets)
      : '';
    this.equityPortfolioTurnover = data.equityPortfolioTurnover || 0;
    this.fixedIncomePortfolioTurnover = data.fixedIncomePortfolioTurnover || 0;
    this.style = data.style;
    this.orientation = data.orientation;
    this.overview = data.overview;
    this.investmentApproach = data.investmentApproach;
    this.currency = this.getCurrency();
  }

  isInvestmentApproachPresent(): boolean {
    return (
      !!this.overview ||
      !!this.assets?.length ||
      !!this.style ||
      !!this.orientation ||
      !!Object.keys(this.investmentApproach).length
    );
  }

  isCrm(): boolean {
    return this.id.isCrm;
  }

  isPr(): boolean {
    return this.id.isPr;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static override transform(data: any): AccountDetailsModel {
    return new AccountDetailsModel(data);
  }

  private formatByUserPreference(amount: number): string {
    const authUser = new UserModel(this.userJson);
    const configs: AssetConfigInterface = {
      currency: authUser.userPreferences.currency,
      maximumSignificantDigits: 3,
    };

    const formatter = new Intl.NumberFormat(
      authUser.userPreferences.culture,
      configs
    );

    return formatter.format(amount);
  }

  private getCurrency(): string {
    const authUser = new UserModel(this.userJson);

    return authUser.userPreferences.currency;
  }
}
