export const CONTACT_ACCOUNT_INFO_TYPES = {
  none: 0,
  contact: 1,
  fund: 2,
  institution: 3,
  security: 4,
  issuer: 5,
  investorGroup: 6,
  activity: 7,
  radar: 8,
};
