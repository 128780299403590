<div class="list-modal-header-container" [ngStyle]="{'background-color': bgColor, 'box-shadow': boxShadow}">
  <div>
    <i *ngIf="showBackNavigation" class="icon-back" (click)="navigateBack()"></i>
    <div class="title ellipse-text">{{ title }}</div>
    <div class="title" *ngIf="showCount && count !== null">({{ count }})</div>
  </div>
  <div *ngIf="withIcon" (click)="navigationToPage()">
    <i class="icon-{{type}}"></i>
  </div>
</div>
