import { transformer } from '../../../shared/decorators/transformer';
import { BaseService } from '../../http-client/base.service';
import { UserModel } from '../../../models/user.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseService {
  @transformer({
    model: UserModel,
    method: 'transform',
  })
  getCurrentUser(): Observable<UserModel> {
    return this.get('current-user');
  }
}
