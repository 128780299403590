import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TickerSelectorComponent } from './components/ticker-selector/ticker-selector.component';
import {
  ButtonModule,
  CardsModule,
  CheckboxModule,
  IconModule,
  SPRFormsModule,
} from '@ipreo/ngx-sprinkles';
import { ReactiveFormsModule } from '@angular/forms';
import { SkeletonLoaderModule } from '../skeleton-loader/skeleton-loader.module';
import { ModalHeaderModule } from '../modal-header/modal-header.module';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [TickerSelectorComponent],
  imports: [
    CommonModule,
    CardsModule,
    SPRFormsModule,
    ButtonModule,
    CheckboxModule,
    IconModule,
    ReactiveFormsModule,
    SkeletonLoaderModule,
    ModalHeaderModule,
    HttpClientModule,
  ],
  exports: [TickerSelectorComponent],
})
export class TickerSelectorModule {}
