import { Injectable } from '@angular/core';
import { LogActionService } from '../../api/v1';
import { ContactModel } from '../../../models/contact.model';
import { AccountModel } from '../../../models/account.model';
import { Observable } from 'rxjs';
import { LogActionAlert, LogActionAlertRecipient } from '@bdam/models';
import { LogActionStoreService } from '../log-action-store/log-action-store.service';

@Injectable({
  providedIn: 'root',
})
export class LogActionAlertRecipientsService {
  private readonly defaultPrCrmId = 0;

  constructor(
    private readonly logActionService: LogActionService,
    private readonly logActionStoreService: LogActionStoreService
  ) {}

  getAlertRecipients(
    contacts: ContactModel[],
    accounts: AccountModel[],
    searchText = ''
  ): Observable<LogActionAlert> {
    const contactCrmIds: number[] = contacts
      .filter((contact: ContactModel) => contact?.accountInfo?.id?.crmId)
      .map((contact: ContactModel) => contact?.accountInfo?.id?.crmId);
    const accountsCrmIds: number[] = accounts.map(
      (account: Partial<AccountModel>) => {
        const crmAccountID: number | undefined = account?.crmAccountID;
        const prAndCrmId: string | undefined = account.id;
        const crmId: number = +(
          prAndCrmId?.split('-')[1] || this.defaultPrCrmId
        );

        return crmAccountID ? crmAccountID : crmId;
      }
    );

    return this.logActionService.getAlerts(
      [...contactCrmIds, ...accountsCrmIds],
      searchText
    );
  }

  getAlertRecipientsById(
    id: number,
    searchText = ''
  ): Observable<LogActionAlert> {
    const currentId: number[] = id === this.defaultPrCrmId ? [] : [id];

    return this.logActionService.getAlerts(currentId, searchText);
  }

  updateAlertRecipients(logActionAlert: LogActionAlert): void {
    const alertRecipientsCoverage: LogActionAlertRecipient[] = [
      ...logActionAlert.coverage,
    ];
    let alertRecipients: LogActionAlertRecipient[] | [] =
      this.logActionStoreService.restoreAlertRecipients();

    alertRecipients = [...alertRecipients, ...alertRecipientsCoverage].filter(
      (
        logActionAlertRecipient: LogActionAlertRecipient,
        index: number,
        logActionAlertRecipients: LogActionAlertRecipient[]
      ) =>
        index ===
        logActionAlertRecipients.findIndex(
          (currentLogActionAlertRecipient: LogActionAlertRecipient) =>
            currentLogActionAlertRecipient.id === logActionAlertRecipient.id
        )
    );

    this.logActionStoreService.saveAlertRecipients(alertRecipients);
  }
}
