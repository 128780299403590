import { MODAL_HEADER_TYPES } from '../../constants/modal-header-types.constant';
import { SEARCH_INPUT_DEFAULT_PLACEHOLDER } from '../../constants/text.constant';
import { BaseComponent } from '../../../base/base.component';
import { FormControl } from '@angular/forms';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-modal-header',
  templateUrl: './modal-header.component.html',
  styleUrls: ['./modal-header.component.scss'],
})
export class ModalHeaderComponent
  extends BaseComponent
  implements AfterViewInit
{
  @Input() placeholder: string = SEARCH_INPUT_DEFAULT_PLACEHOLDER;
  @Input() title = '';
  @Input() type: string = MODAL_HEADER_TYPES.searchHeader;
  @Input() control = new FormControl();
  @Input() showBackNavigation = true;
  @Input() withCloseIcon = false;
  @Input() autoFocus = true;
  @Output() backNavigation = new EventEmitter<boolean>();
  @Output() inputFocus = new EventEmitter<boolean>();
  types = MODAL_HEADER_TYPES;

  constructor(private element: ElementRef) {
    super();
  }

  ngAfterViewInit(): void {
    this.autofocusInput();
  }

  navigateBack(): void {
    this.backNavigation.emit(true);
  }

  onReset(): void {
    this.control.patchValue('');
  }

  private autofocusInput(): void {
    if (this.element.nativeElement && this.autoFocus) {
      const input =
        this.element.nativeElement.querySelector('input[type=text]');
      input &&
        input.focus({
          preventScroll: true,
        });
    }
  }
}
