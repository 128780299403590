import { BadgeModule, CardsModule } from '@ipreo/ngx-sprinkles';
import { HomeCardComponent } from './home-card.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [HomeCardComponent],
  imports: [CommonModule, CardsModule, BadgeModule],
  exports: [HomeCardComponent],
})
export class HomeCardModule {}
