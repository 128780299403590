import { BaseModel } from './base.model';

export class IdModel extends BaseModel {
  isCrm: boolean;
  isPr: boolean;
  prId: number;
  crmId: number;

  constructor(data: any = {}) {
    super();
    this.isCrm = data.isCrm;
    this.isPr = data.isPr;
    this.prId = data.prId;
    this.crmId = data.crmId;
  }

  static override transform(data: any): IdModel {
    return new IdModel(data);
  }
}
