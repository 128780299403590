import { ContactModel } from '../../models/contact.model';
import { ContactDetailsModel } from '../../models/contact-details.model';

export const getContactDisplayName = (
  contact: Partial<ContactModel> | Partial<ContactDetailsModel>
): string => {
  if (!contact) {
    return '';
  }
  return [
    contact.firstName ? `${contact.firstName}` : '',
    contact.middleName ? `${contact.middleName}` : '',
    contact.lastName ? `${contact.lastName}` : '',
    contact.nickName ? `(${contact.nickName})` : '',
  ]
    .filter((s) => !!s)
    .join(' ');
};
