import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { BaseService } from '../../http-client/base.service';
import { Observable, tap } from 'rxjs';
import { ENVIRONMENTS } from '../../../shared/constants/environments.constants';

@Injectable({
  providedIn: 'root',
})
export class LogOutService extends BaseService {
  logOut(): Observable<unknown> {
    return this.get(`current-user/logout`).pipe(
      tap(() => {
        this.httpHeadersService.removeAuth();
        sessionStorage.clear();
        localStorage.clear();

        location.href = `${environment.baseUrl}/login?r=${
          ENVIRONMENTS.includes(environment.name)
            ? environment.webUrl
            : location.href
        }`;
      })
    );
  }
}
