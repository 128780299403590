import { CONTACT_ACCOUNT_INFO_TYPES } from '../../constants/contact-account-info-types.constant';
import { ContactDetailsModel } from '../../../models/contact-details.model';
import { BaseComponent } from '../../../base/base.component';
import { ContactModel } from '../../../models/contact.model';
import { Component, Input, OnInit } from '@angular/core';
import { getContactDisplayName } from '../../utils/get-contact-display-name.utils';

@Component({
  selector: 'app-contact-card',
  templateUrl: './contact-card.component.html',
  styleUrls: ['./contact-card.component.scss'],
})
export class ContactCardComponent extends BaseComponent implements OnInit {
  @Input() contact!: ContactModel | ContactDetailsModel;
  @Input() contactWithoutBudge = false;
  @Input() selected = false;

  displayName = '';

  public accountInfoTypes = CONTACT_ACCOUNT_INFO_TYPES;

  ngOnInit(): void {
    this.displayName = getContactDisplayName(this.contact);
  }
}
