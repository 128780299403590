<div class="list-account-item-container">
  <div class="header">
    <app-list-modal-header [title]="title"
                           [withIcon]="false"
                           (backNavigation)="onBack()"></app-list-modal-header>
  </div>

  <div class="content" (scroll)="onScroll($event)">
    <div class="list-card"
      appHeaderIntersectionVisibility
      [defaultTitle]="title"
      [title]="listName"
      (visibilityElementChanged)="visibilityListNameChanged($event)"
    >
      <app-list-card [list]="list"
                     [isShared]="shared"
                     [itemsType]="'Accounts'"
                     (toggleFavorites)="toggleFavorites($event)"
                     [showInlineCount]="false"></app-list-card>
    </div>
    <div class="search-container">
      <spr-input [prefixIcon]="'search'"
                 [formControl]="control"
                 [size]="cupcakeSizesSML.lg"
                 [placeholder]="'Search For Accounts'"></spr-input>
      <spr-icon
        class="clear-icon"
        *ngIf="control.value"
        (click)="onReset()"
        [name]="'times-circle'"
        [size]="cupcakeSizes.sm"></spr-icon>
    </div>
    <div class="accounts-container">
      <ng-container *ngIf="showLoader; else showResponse">
        <div class="loader-container">
          <app-skeleton-loader [numberOfRepetitions]="7" [size]="'middle'"></app-skeleton-loader>
        </div>
      </ng-container>

      <ng-template #showResponse>
        <ng-container *ngIf="accounts.length; else noResults">
          <app-account-card *ngFor="let account of accounts"
                            (click)="navigateToAccountDetails(account)"
                            [account]="account"></app-account-card>

          <app-skeleton-loader *ngIf="showPagingLoader"
                               [size]="'middle'"
                               [numberOfRepetitions]="3"></app-skeleton-loader>
        </ng-container>

        <ng-template #noResults>
          <div class="text-center main-margin-top">{{ noResultsMessage }}</div>
        </ng-template>
      </ng-template>
    </div>
  </div>
</div>
