import { AccountModel } from './account.model';
import { ListModel } from './list.model';
import { BaseModel } from './base.model';

export class AccountMemberModel extends BaseModel {
  list: ListModel;
  members: AccountModel[];

  constructor(data: any = {}) {
    super();
    this.list = ListModel.transform(data.list);
    this.members = AccountModel.transformCollection(data.members || []);
  }

  static override transform(data: any): AccountMemberModel {
    return new AccountMemberModel(data);
  }
}
