import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmationModalComponent } from './confirmation-modal.component';
import { ButtonModule } from '@ipreo/ngx-sprinkles';

@NgModule({
  declarations: [ConfirmationModalComponent],
  imports: [CommonModule, ButtonModule],
  exports: [ConfirmationModalComponent],
})
export class ConfirmationModalModule {}
