import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../base/base.component';
import { LIST_MODAL_HEADER_TYPES } from '../../constants/list-modal-header-types.constant';

@Component({
  selector: 'app-list-modal-header',
  templateUrl: './list-modal-header.component.html',
  styleUrls: ['./list-modal-header.component.scss'],
})
export class ListModalHeaderComponent extends BaseComponent {
  @Input() title: string | undefined;
  @Input() type = LIST_MODAL_HEADER_TYPES.threeDots;
  @Input() bgColor = '#F8F8F8FF';
  @Input() boxShadow = '';
  @Input() withIcon = true;
  @Input() showBackNavigation = true;
  @Input() showCount!: boolean;
  @Input() count!: number;
  @Output() backNavigation = new EventEmitter<boolean>();
  @Output() navigation = new EventEmitter<boolean>();

  navigateBack(): void {
    this.backNavigation.emit(true);
  }

  navigationToPage(): void {
    this.navigation.emit(true);
  }
}
