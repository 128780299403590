import { ModalHeaderModule } from '../modal-header/modal-header.module';
import { ContactCardComponent } from './contact-card.component';
import { BadgeModule, CardsModule } from '@ipreo/ngx-sprinkles';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [ContactCardComponent],
  imports: [CommonModule, CardsModule, BadgeModule, ModalHeaderModule],
  exports: [ContactCardComponent],
})
export class ContactCardModule {}
