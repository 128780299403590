import { Params } from '@angular/router';
import {
  ACTION_TYPE_IDS_KEY,
  DEFAULT_DATE_RANGE_DAYS,
  END_DATE_KEY,
  MEETING_TYPE_IDS_KEY,
  NEWEST_FIRST_SORTING_KEY,
  ParamLengthCalculator,
  START_DATE_KEY,
} from '@bdam/shared';
import moment from 'moment/moment';

export class DefaultFiltersParamLengthCalculator
  implements ParamLengthCalculator
{
  calculateLength(params: Params): number {
    let selectedIdsLength = 0;

    if (!params) {
      return 0;
    }

    const actionTypeIds: number[] = params[ACTION_TYPE_IDS_KEY] || [];
    const meetingTypeIds: number[] = params[MEETING_TYPE_IDS_KEY] || [];
    const isNewestFirstSorting: boolean = params[NEWEST_FIRST_SORTING_KEY];
    const startEndDates =
      (params[START_DATE_KEY] && params[END_DATE_KEY]) || false;

    if (actionTypeIds && actionTypeIds.length > 0) {
      selectedIdsLength = actionTypeIds.length;
    }

    if (meetingTypeIds && meetingTypeIds.length > 0) {
      selectedIdsLength = selectedIdsLength + meetingTypeIds.length;
    }

    if (isNewestFirstSorting !== undefined && !isNewestFirstSorting) {
      selectedIdsLength++;
    }

    if (startEndDates) {
      const startDate = moment(params[START_DATE_KEY]);
      const endDate = moment(params[END_DATE_KEY]);

      const diffInDays = endDate.diff(startDate, 'days');

      if (
        diffInDays !== DEFAULT_DATE_RANGE_DAYS &&
        diffInDays !== DEFAULT_DATE_RANGE_DAYS - 1
      ) {
        selectedIdsLength++;
      }
    }

    return selectedIdsLength;
  }
}
