import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../base/base.component';
import { AccountModel } from '../../../models/account.model';
import { ListModel } from '../../../models/list.model';
import { FormControl } from '@angular/forms';
import { NO_RESULTS_FOUND_MESSAGE } from '@bdam/shared';

@Component({
  selector: 'app-list-account-item',
  templateUrl: './list-account-item.component.html',
  styleUrls: ['./list-account-item.component.scss'],
})
export class ListAccountItemComponent extends BaseComponent {
  @Input() set title(title: string) {
    this.localTitle = title;
  }

  get title(): string {
    return this.localTitle;
  }

  @Input() list!: ListModel;
  @Input() showLoader = false;
  @Input() shared!: boolean;
  @Input() accounts: AccountModel[] = [];
  @Input() control = new FormControl();
  @Input() showPagingLoader!: boolean;

  @Output() navigateBack = new EventEmitter<boolean>();
  @Output() favorite = new EventEmitter<boolean>();
  @Output() navigateToDetails = new EventEmitter<AccountModel>();
  @Output() nextPage = new EventEmitter<boolean>();

  noResultsMessage = NO_RESULTS_FOUND_MESSAGE;

  private localTitle!: string;

  get listName(): string {
    return this.list?.name;
  }

  constructor() {
    super();
  }

  onScroll(event: Event): void {
    this.scrolledToBottom(event.target, () => this.nextPage.emit(true));
  }

  onBack(): void {
    this.navigateBack.emit(true);
  }

  toggleFavorites(event: boolean): void {
    this.favorite.emit(event);
  }

  navigateToAccountDetails(account: AccountModel): void {
    this.navigateToDetails.emit(account);
  }

  visibilityListNameChanged(title: string): void {
    this.title = title;
  }

  onReset(): void {
    this.control.patchValue('');
  }
}
