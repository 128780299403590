import { FocusModel } from '../../models/focus.model';
import { MARKET_CAP_KEY } from '@bdam/shared';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const convertFocusData = (focus: any, template: string[]) => {
  if (!focus) {
    return [];
  }

  return focus
    .filter((focus: FocusModel) => {
      return Object.values(focus).some((value) => template.includes(value));
    })
    .map((item: FocusModel) => {
      if (item.key !== MARKET_CAP_KEY) {
        item.sortByName();
      }
      return item;
    })
    .sort((a: FocusModel, b: FocusModel) => {
      const indexA: number = template.indexOf(a.key);
      const indexB: number = template.indexOf(b.key);

      return indexA - indexB;
    });
};
