export const DEFAULT_ACTIVITY_DURATION = 5; // min

export const PUBLIC_COMMENT_MAX_LENGTH = 5000; // symbols

export enum FORM_CLOSE_EVENTS {
  SAVED = 'SAVED',
  CANCELED = 'CANCELED',
  DELETED = 'DELETED',
}

export const MEETING_ACTIVITY_KEY = 2;
export const LOG_ACTION_ACTIVITY_KEY = 16;
export const EVENT_MEETING_ACTIVITY_KEY = 8;

export enum MODAL_DIALOG_CLOSE_EVENTS {
  OK = 'OK',
  CANCEL = 'CANCEL',
}

export const ACCOUNT_DETAILS_BACK_ROUTE_STORAGE_KEY =
  'snp-account-details-back-route';
export const BACK_ROUTE_PARAMS_STORAGE_KEY = 'snp-back-route-params';
