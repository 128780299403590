import { BaseModel } from './base.model';
import moment from 'moment';
import { DEFAULT_DATE_RANGE_DAYS } from '@bdam/shared';

export class ActivitiesModel extends BaseModel {
  actionTypeIds: number[];
  meetingTypeIds: number[];
  newestFirstSorting: boolean;
  pageName: 'recent' | 'upcoming';
  startDate: string;
  endDate: string;
  tickerIds: number[];
  crmSecurityGuids: string[];
  myCoverage: boolean;
  page: number;
  pageSize: number;
  isParticipant: boolean;

  constructor(data: any = {}) {
    super();

    this.actionTypeIds = data.actionTypeIds || [];
    this.meetingTypeIds = data.meetingTypeIds || [];
    this.newestFirstSorting =
      typeof data.newestFirstSorting === 'boolean'
        ? data.newestFirstSorting
        : true;
    this.pageName = data.pageName || 'recent';
    this.startDate = data.startDate ? data.startDate : this.defaultStartDate;
    this.endDate = data.endDate ? data.endDate : this.defaultEndDate;
    this.tickerIds = data.tickerIds || [];
    this.crmSecurityGuids = data.crmSecurityGuids || [];
    this.myCoverage = data.myCoverage || false;
    this.page = data.page || 0;
    this.pageSize = data.pageSize || 20;
    this.isParticipant = data.isParticipant || false;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static override transform(data: any): ActivitiesModel {
    return new ActivitiesModel(data);
  }

  private get defaultStartDate(): string {
    return this.pageName === 'recent'
      ? moment().subtract(DEFAULT_DATE_RANGE_DAYS, 'days').toISOString()
      : moment().toISOString();
  }

  private get defaultEndDate(): string {
    return this.pageName === 'recent'
      ? moment().toISOString()
      : moment().add(DEFAULT_DATE_RANGE_DAYS, 'days').toISOString();
  }
}
