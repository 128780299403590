import { Injectable } from '@angular/core';
import {
  AccountCoverage,
  AccountDetailsModel,
  RecentlyViewedAccountId,
} from '@bdam/models';
import {
  ACCOUNT_COVERAGE_STORAGE_KEY,
  ACCOUNT_DETAILS_ACTIVE_TAB_STORAGE_KEY,
  ACCOUNT_DETAILS_STORAGE_KEY,
  ACCOUNTS_MY_COVERAGE_STORAGE_KEY,
  RECENTLY_VIEWED_ACCOUNT_IDS_STORAGE_KEY,
} from '@bdam/shared';

@Injectable({
  providedIn: 'root',
})
export class AccountStoreService {
  saveAccountDetailsActiveTab(tab: string): void {
    if (tab) {
      sessionStorage.setItem(ACCOUNT_DETAILS_ACTIVE_TAB_STORAGE_KEY, tab);
    }
  }

  restoreAccountDetailsActiveTab(): string {
    return sessionStorage.getItem(ACCOUNT_DETAILS_ACTIVE_TAB_STORAGE_KEY) || '';
  }

  saveAccountCoverage(coverage: AccountCoverage[]): void {
    if (coverage?.length) {
      sessionStorage.setItem(
        ACCOUNT_COVERAGE_STORAGE_KEY,
        JSON.stringify(coverage)
      );
    }
  }

  restoreAccountCoverage(): AccountCoverage[] {
    const coverage = sessionStorage.getItem(ACCOUNT_COVERAGE_STORAGE_KEY);
    return coverage ? JSON.parse(coverage) : [];
  }

  removeAccountCoverage(): void {
    sessionStorage.removeItem(ACCOUNT_COVERAGE_STORAGE_KEY);
  }

  saveAccountDetails(accountDetails: AccountDetailsModel): void {
    if (accountDetails) {
      sessionStorage.setItem(
        ACCOUNT_DETAILS_STORAGE_KEY,
        JSON.stringify(accountDetails)
      );
    }
  }

  restoreAccountDetails(): AccountDetailsModel {
    const accountDetails = sessionStorage.getItem(ACCOUNT_DETAILS_STORAGE_KEY);
    return accountDetails ? JSON.parse(accountDetails) : {};
  }

  removeAccountDetails(): void {
    sessionStorage.removeItem(ACCOUNT_DETAILS_STORAGE_KEY);
  }

  addRecentlyViewedAccountId(id: RecentlyViewedAccountId): void {
    const recentlyViewedAccountIdsRaw = sessionStorage.getItem(
      RECENTLY_VIEWED_ACCOUNT_IDS_STORAGE_KEY
    );

    const recentlyViewedAccountIds: RecentlyViewedAccountId[] =
      recentlyViewedAccountIdsRaw
        ? JSON.parse(recentlyViewedAccountIdsRaw)
        : [];
    recentlyViewedAccountIds.unshift(id);

    sessionStorage.setItem(
      RECENTLY_VIEWED_ACCOUNT_IDS_STORAGE_KEY,
      JSON.stringify(recentlyViewedAccountIds)
    );
  }

  restoreRecentlyViewedAccountIds(): RecentlyViewedAccountId[] {
    const raw = sessionStorage.getItem(RECENTLY_VIEWED_ACCOUNT_IDS_STORAGE_KEY);
    return raw ? JSON.parse(raw) : [];
  }

  resetRecentlyViewedAccounts(): void {
    sessionStorage.removeItem(RECENTLY_VIEWED_ACCOUNT_IDS_STORAGE_KEY);
  }

  storeAccountsMyCoverage(value: boolean): void {
    sessionStorage.setItem(
      ACCOUNTS_MY_COVERAGE_STORAGE_KEY,
      JSON.stringify(value)
    );
  }

  restoreAccountsMyCoverage(): boolean {
    const raw = sessionStorage.getItem(ACCOUNTS_MY_COVERAGE_STORAGE_KEY);
    return raw ? JSON.parse(raw) : false;
  }

  resetStore(): void {
    sessionStorage.removeItem(ACCOUNT_COVERAGE_STORAGE_KEY);
    sessionStorage.removeItem(ACCOUNT_DETAILS_STORAGE_KEY);
    sessionStorage.removeItem(ACCOUNT_DETAILS_ACTIVE_TAB_STORAGE_KEY);
  }
}
