<div class="ticker-container">
  <div class="ticker-header" *ngIf="!headerWithArrow">
    <app-modal-header
      [control]="searchControl"
      [placeholder]="placeholder"
      [showBackNavigation]="false"
      [withCloseIcon]="true"
      (backNavigation)="onClose()"
    ></app-modal-header>
  </div>
  <div class="ticker-header" *ngIf="headerWithArrow">
    <app-modal-header
      [control]="searchControl"
      [placeholder]="placeholder"
      (backNavigation)="onClose()"
    ></app-modal-header>
  </div>

  <div class="main-container">
    <ng-container *ngIf="loading; else showAppliedAndSelectedTickers">
      <app-skeleton-loader
        [size]="'middle'"
        [numberOfRepetitions]="6"
      ></app-skeleton-loader>
    </ng-container>

    <ng-template #showAppliedAndSelectedTickers>
      <ng-container *ngIf="appliedTickers.length">
        <div class="section">
          <div class="section-title">{{ selectedSecuritiesText }}</div>
          <ng-container
            *ngTemplateOutlet="tickerItems; context: { items: appliedTickers }"
          ></ng-container>
        </div>

        <ng-container>
          <div class="section">
            <div class="section-title" *ngIf="isSearchState">
              {{ selectSecurityText }}
            </div>
            <div *ngIf="itemsFound">
              <ng-container
                *ngTemplateOutlet="tickerItems; context: { items: tickers }"
              ></ng-container>
            </div>
            <div class="empty-result" *ngIf="!itemsFound">
              {{ noResultsMessage }}
            </div>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="!appliedTickers.length">
        <ng-container
          *ngTemplateOutlet="tickerItems; context: { items: tickers }"
        ></ng-container>
      </ng-container>
    </ng-template>

    <ng-template #tickerItems let-tickers="items">
      <ng-container *ngIf="tickers && tickers.length">
        <ul>
          <li *ngFor="let ticker of tickers">
            <spr-cards>
              <spr-checkbox
                [color]="cupcakeFlavorsExtended.warningLight"
                (change)="onTickerSelect(ticker)"
                class="card-header"
                [legacy]="false"
                [checked]="ticker.selected"
                [size]="cupcakeSizes.lg"
              >
                <div class="card-title">{{ ticker.securityName }}</div>
              </spr-checkbox>

              <div class="description">
                <span>{{ ticker.primaryTickerName }}</span
                >,
                {{ ticker.primaryExchangeName }}
              </div>
            </spr-cards>
            <hr class="delimiter" />
          </li>
        </ul>
      </ng-container>
    </ng-template>
  </div>

  <div class="footer-buttons-set" *ngIf="!actionFooter">
    <button
      class="button button-neutral"
      (click)="onReset()"
      spr-button
      [size]="cupcakeSizes.lg"
    >
      {{ resetButtonText }}
    </button>
    <button
      class="button button-primary"
      (click)="onApply()"
      spr-button
      [size]="cupcakeSizes.lg"
    >
      {{ applyButtonText }}
    </button>
  </div>

  <div class="footer-buttons-set" *ngIf="actionFooter">
    <button
      class="button button-neutral"
      (click)="closeModal()"
      spr-button
      [size]="cupcakeSizes.lg"
    >
      Cancel
    </button>
    <button
      class="button button-primary"
      (click)="onApply()"
      spr-button
      [size]="cupcakeSizes.lg"
    >
      Update Selection
    </button>
  </div>
</div>
