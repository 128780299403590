<div class="account-contact-ticker-container">
  <ng-container *ngIf="showRecentSearches; else showGlobalSearch">
    <app-recent-searches
      [control]="recentSearchControl"
      [showBackNavigation]="showBackNavigation"
      [withCloseIcon]="withCloseIcon"
      [placeholder]="searchSecuritiesPlaceholder"
      [recentSearches]="recentSearches"
      (selected)="getSelectedRecentSearch($event)"
      (navigateBack)="navigateToContactInformPage()"
      (clearHistory)="clearRecentSearchHistory()"
    ></app-recent-searches>
  </ng-container>

  <ng-template #showGlobalSearch>
    <app-ticker
      [control]="control"
      [selectedTickers]="selectedTickers"
      [appliedTickers]="appliedTickers"
      [tickers]="tickerGroup.other"
      [showLoader]="loading"
      (resetTickers)="onReset()"
      (applyTickers)="onApply()"
      (tickerSelect)="onTickerSelect($event)"
      (backNavigation)="navigateToContactInformPage()"
    ></app-ticker>
  </ng-template>
</div>
