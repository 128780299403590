import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../base/base.component';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-recent-searches',
  templateUrl: './recent-searches.component.html',
  styleUrls: ['./recent-searches.component.scss'],
})
export class RecentSearchesComponent extends BaseComponent {
  @Input() recentSearches: string[] = [];
  @Input() control = new FormControl();
  @Input() placeholder!: string;
  @Input() showBackNavigation!: boolean;
  @Input() withCloseIcon!: boolean;

  @Output() navigateBack = new EventEmitter<boolean>();
  @Output() clearHistory = new EventEmitter<boolean>();
  @Output() selected = new EventEmitter<string>();

  constructor() {
    super();
  }

  clearAll(): void {
    this.clearHistory.emit(true);
  }

  onBackNavigation(): void {
    this.navigateBack.emit(true);
  }

  select(item: string): void {
    this.selected.emit(item);
  }
}
