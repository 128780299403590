import { ActivitiesIdModel } from './activities-id.model';
import { AccountInfoModel } from './account-info.model';
import { BaseModel } from './base.model';

export interface Activity {
  accounts: ActivitiesIdModel[];
  contacts: {
    contact: ActivitiesIdModel;
    accountOfContact: ActivitiesIdModel;
  }[];
  id: number;
  activityKey: number;
  dateTime: string;
  interactionTypeName: string;
  securities: string[];
  subject: string;
  comments: string;
  completers: string[];
  alertRecipients: string[];
  canUpdate: boolean;
  canDelete: boolean;
  defaultDataTime?: string;
}

export class ActivitiesListModel extends BaseModel implements Activity {
  accounts: ActivitiesIdModel[];
  contacts: {
    contact: ActivitiesIdModel;
    accountOfContact: ActivitiesIdModel;
  }[];
  id: number;
  activityKey: number;
  dateTime: string;
  interactionTypeName: string;
  securities: string[];
  subject: string;
  comments: string;
  completers: string[];
  alertRecipients: string[];
  canUpdate: boolean;
  canDelete: boolean;
  defaultDataTime: string;

  constructor(data: any = {}) {
    super();

    this.accounts = AccountInfoModel.transformCollection(data.accounts || []);
    this.contacts = AccountInfoModel.transformCollection(data.contacts || []);
    this.id = data.id;
    this.activityKey = data.activityKey;
    this.dateTime = this.formatByUserTimePreference(data.dateTime);
    this.defaultDataTime = this.defaultFormatByUserTimePreference(
      data.dateTime
    );
    this.interactionTypeName = data.interactionTypeName;
    this.securities = data.securities;
    this.subject = data.subject;
    this.comments = data.comments;
    this.completers = data.completers;
    this.alertRecipients = data.alertRecipients || [];
    this.canUpdate = data.canUpdate;
    this.canDelete = data.canDelete;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static override transform(data: any): ActivitiesListModel {
    return new ActivitiesListModel(data);
  }
}
