import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileFeedbackCardComponent } from './components/profile-feedback-card/profile-feedback-card.component';
import { ProfileLinkCardComponent } from './components/profile-link-card/profile-link-card.component';
import { ProfileSupportFormCardComponent } from './components/profile-support-form-card/profile-support-form-card.component';
import { ProfileTextCardComponent } from './components/profile-text-card/profile-text-card.component';
import {
  ButtonModule,
  CardsModule,
  SPRFormsModule,
} from '@ipreo/ngx-sprinkles';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    ProfileFeedbackCardComponent,
    ProfileLinkCardComponent,
    ProfileSupportFormCardComponent,
    ProfileTextCardComponent,
  ],
  imports: [
    CommonModule,
    CardsModule,
    ReactiveFormsModule,
    SPRFormsModule,
    ButtonModule,
  ],
  exports: [
    ProfileFeedbackCardComponent,
    ProfileLinkCardComponent,
    ProfileSupportFormCardComponent,
    ProfileTextCardComponent,
  ],
})
export class ProfileDetailsModule {}
