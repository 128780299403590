// No Connection notification
export const CONNECTION_LOST_MESSAGE =
  'Connection Error. Please reload the page';
export const ACTION_COULD_NOT_BE_SAVED_MESSAGE =
  'Connection Error. The Action was not saved';

export const NO_RESULTS_FOUND_MESSAGE =
  'No results found. Please update your search criteria';

export const NO_ACTIVITIES_FOUND = 'No Recent Activities';
export const NO_UPCOMING_FOUND = 'No Upcoming Activities';
