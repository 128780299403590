import { UserPermissionsModel } from './user-permissions.model';
import { UserPreferencesModel } from './user-preferences.model';
import { PrimaryContactModel } from './primary-contact.model';

export class UserModel {
  companyId: number;
  email: string;
  firstName: string;
  id: number;
  lastName: string;
  middleName: string;
  phone: string;
  primaryContact: PrimaryContactModel;
  siteId: number;
  status: string;
  teamIds: number[];
  userName: string;
  userPermissions: UserPermissionsModel;
  userPreferences: UserPreferencesModel;

  constructor(data: any = {}) {
    this.companyId = data.companyId;
    this.email = data.email;
    this.firstName = data.firstName;
    this.id = data.id;
    this.lastName = data.lastName;
    this.middleName = data.middleName;
    this.phone = data.phone;
    this.primaryContact = PrimaryContactModel.transform(data.primaryContact);
    this.siteId = data.siteId;
    this.status = data.status;
    this.teamIds = data.teamIds;
    this.userName = data.userName;
    this.userPermissions = UserPermissionsModel.transform(data.userPermissions);
    this.userPreferences = UserPreferencesModel.transform(data.userPreferences);
  }

  static transform(data: any): UserModel {
    return new UserModel(data);
  }
}
