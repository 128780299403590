import { BaseModel } from './base.model';

export class PrimaryContactModel extends BaseModel {
  email: string;
  name: string;
  phoneNumber: string;

  constructor(data: any = {}) {
    super();
    this.email = data.email;
    this.name = data.name;
    this.phoneNumber = data.phoneNumber;
  }

  static override transform(data: any): PrimaryContactModel {
    return new PrimaryContactModel(data);
  }
}
