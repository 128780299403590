import { Injectable } from '@angular/core';
import { BaseService } from '../../http-client/base.service';
import { Observable } from 'rxjs';
import { transformer } from '../../../shared/decorators/transformer';
import { ActivitiesModel } from '../../../models/activities.model';
import {
  ActivitiesListModel,
  Activity,
} from '../../../models/activities-list.model';
import { ActivityTypeModel } from '../../../models/activity-type.model';
import { ActivitiesContactDetailsModel } from '../../../models/activities-contact-details.model';
import {
  ACTIVITIES_ACCOUNT_DETAILS_URL,
  ACTIVITIES_CONTACT_DETAILS_URL,
  ACTIVITIES_EVENT_MEETING_URL,
  ACTIVITIES_LOG_ACTION_URL,
  ACTIVITIES_MEETING_URL,
  ACTIVITIES_SEARCH_URL,
  ACTIVITIES_TYPES_URL,
} from '@bdam/shared';

@Injectable({
  providedIn: 'root',
})
export class ActivitiesService extends BaseService {
  @transformer({
    model: ActivitiesListModel,
    method: 'transformCollection',
  })
  getActivitiesList(
    activities: ActivitiesModel
  ): Observable<ActivitiesListModel[]> {
    return this.post(ACTIVITIES_SEARCH_URL, activities);
  }

  @transformer({
    model: ActivityTypeModel,
    method: 'transform',
  })
  getActivityTypes(): Observable<ActivityTypeModel> {
    return this.get(ACTIVITIES_TYPES_URL);
  }

  @transformer({
    model: ActivitiesListModel,
    method: 'transformCollection',
  })
  getActivitiesContactDetailsList(
    activities: ActivitiesContactDetailsModel
  ): Observable<ActivitiesListModel[]> {
    return this.post(ACTIVITIES_CONTACT_DETAILS_URL, activities);
  }

  @transformer({
    model: ActivitiesListModel,
    method: 'transformCollection',
  })
  getActivitiesAccountDetailsList(
    activities: ActivitiesContactDetailsModel
  ): Observable<ActivitiesListModel[]> {
    return this.post(ACTIVITIES_ACCOUNT_DETAILS_URL, activities);
  }

  getLoggedAction(id: string): Observable<Activity> {
    return this.get(ACTIVITIES_LOG_ACTION_URL(id));
  }

  getMeeting(id: string): Observable<Activity> {
    return this.get(ACTIVITIES_MEETING_URL(id));
  }

  getEventMeeting(id: string): Observable<Activity> {
    return this.get(ACTIVITIES_EVENT_MEETING_URL(id));
  }
}
