import { AlertNotificationService } from '../helpers/alert-notification/alert-notification.service';
import { HttpHelperService } from './helpers/http-helper.service';
import { RequestHandlerService } from './request-handler.service';
import { HttpHeadersService } from './http-headers.service';
import { HttpClient } from '@angular/common/http';
import { Params, Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export abstract class HttpClientService {
  constructor(
    protected router: Router,
    protected httpClient: HttpClient,
    private httpHelpers: HttpHelperService,
    protected httpHeadersService: HttpHeadersService,
    private requestHandlerService: RequestHandlerService,
    protected alertNotificationService: AlertNotificationService
  ) {}

  protected get(
    url: string,
    params: Params = {},
    options: any = {}
  ): Observable<any> {
    return this.httpClient
      .get(
        this.httpHelpers.getUrl(url),
        options.httpOptions
          ? { ...options.httpOptions, params }
          : { ...this.httpHeadersService.httpOptions, params }
      )
      .pipe(
        catchError(
          this.requestHandlerService.errorHandler.bind(
            this.requestHandlerService
          )
        )
      );
  }

  protected post(
    url: string,
    data: any = {},
    options: any = {}
  ): Observable<any> {
    const skipDefaultErrorHandler = options?.skipDefaultErrorHandler;
    return this.httpClient
      .post(
        this.httpHelpers.getUrl(url),
        data,
        options.httpOptions
          ? options.httpOptions
          : this.httpHeadersService.httpOptions
      )
      .pipe(
        catchError((error) => {
          if (!skipDefaultErrorHandler) {
            return this.requestHandlerService.errorHandler(error);
          }
          throw throwError(error);
        })
      );
  }

  protected put(
    url: string,
    data: any = {},
    options: any = {}
  ): Observable<any> {
    return this.httpClient
      .put(
        this.httpHelpers.getUrl(url),
        data,
        options.httpOptions
          ? options.httpOptions
          : this.httpHeadersService.httpOptions
      )
      .pipe(
        catchError(
          this.requestHandlerService.errorHandler.bind(
            this.requestHandlerService
          )
        )
      );
  }

  protected delete(url: string, options: any = {}): Observable<any> {
    return this.httpClient
      .delete(
        this.httpHelpers.getUrl(url),
        options.httpOptions
          ? options.httpOptions
          : this.httpHeadersService.httpOptions
      )
      .pipe(
        catchError(
          this.requestHandlerService.errorHandler.bind(
            this.requestHandlerService
          )
        )
      );
  }

  protected onError(errorResponse: { error: any }): Observable<any> {
    this.alertNotificationService.alert('error', errorResponse.error);
    return throwError(errorResponse.error || errorResponse);
  }
}
