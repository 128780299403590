<spr-cards
  *ngIf="shouldExistCardType(activity?.activityKey)"
  (click)="navigateToLogAction()"
>
  <div class="card-container">
    <div class="card-header">
      <span class="card-date">{{ activity.dateTime }}</span>
      <div
        class="card-badge-container"
        *ngIf="activity.securities && activity.securities.length > 0"
      >
        <div class="card-badge">
          <spr-badge
            [size]="cupcakeSizes.md"
            [color]="cupcakeFlavorsExtended.cyanLight"
            style="background-color: #c8e6e5"
          >
            <span class="ellipse-text">{{ activity.securities[0] }}</span>
          </spr-badge>
        </div>
        <div class="card-badge-count" *ngIf="activity.securities.length > 1">
          <span>+{{ activity.securities.length - 1 }}</span>
        </div>
      </div>
    </div>

    <ng-container [ngSwitch]="activity.activityKey">
      <ng-container *ngSwitchCase="activityType.action">
        <div class="title">{{ activity.interactionTypeName }}</div>
        <ng-container
          *ngTemplateOutlet="
            accountNames;
            context: { $implicit: activity.accounts }
          "
        ></ng-container>
        <div class="card-description-container">
          <ng-container
            *ngTemplateOutlet="
              contactNames;
              context: { $implicit: activity.contacts }
            "
          ></ng-container>
          <div class="card-description">
            <div class="card-text-first-column">Completed by</div>
            <ng-container
              *ngTemplateOutlet="
                completerNames;
                context: { $implicit: activity.completers }
              "
            ></ng-container>
          </div>
        </div>
        <ng-container
          *ngTemplateOutlet="
            detailDescription;
            context: { $implicit: activity.comments }
          "
        ></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="activityType.eventMeeting">
        <div class="title">
          <i class="icon-calendar"></i>
          <span>{{ activity.interactionTypeName }}</span>
        </div>
        <ng-container
          *ngTemplateOutlet="
            accountNames;
            context: { $implicit: activity.accounts }
          "
        ></ng-container>
        <div class="card-description-container">
          <div class="card-description">
            <div class="card-text-first-column">Subject</div>
            <div class="card-text-second-column">{{ activity.subject }}</div>
          </div>
          <ng-container
            *ngTemplateOutlet="
              contactNames;
              context: { $implicit: activity.contacts }
            "
          ></ng-container>
        </div>
        <ng-container
          *ngTemplateOutlet="
            detailDescription;
            context: { $implicit: activity.comments }
          "
        ></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="activityType.meeting">
        <div class="title">{{ activity.interactionTypeName }}</div>
        <ng-container
          *ngTemplateOutlet="
            accountNames;
            context: { $implicit: activity.accounts }
          "
        ></ng-container>
        <div class="card-description-container">
          <div class="card-description">
            <div class="card-text-first-column">Subject</div>
            <div class="card-text-second-column">{{ activity.subject }}</div>
          </div>
          <ng-container
            *ngTemplateOutlet="
              contactNames;
              context: { $implicit: activity.contacts }
            "
          ></ng-container>
          <div class="card-description">
            <div class="card-text-first-column">Organised by</div>
            <ng-container
              *ngTemplateOutlet="
                completerNames;
                context: { $implicit: activity.completers }
              "
            ></ng-container>
          </div>
        </div>
        <ng-container
          *ngTemplateOutlet="
            detailDescription;
            context: { $implicit: activity.comments }
          "
        ></ng-container>
      </ng-container>
    </ng-container>

    <ng-template #accountNames let-accounts>
      <div
        class="detail-description detail-description--alignment"
        *ngIf="shouldExistName(accounts)"
      >
        <i class="icon-accounts"></i>
        <div class="ellipse-text">
          ({{ accounts.length }})
          <ng-container *ngFor="let account of accounts; let isLast = last">
            <span *ngIf="!isLast">{{ account.name }}, </span>
            <span *ngIf="isLast">{{ account.name }}</span>
          </ng-container>
        </div>
      </div>
    </ng-template>

    <ng-template #detailDescription let-comments>
      <div class="detail-description" *ngIf="comments && comments.length > 0">
        <i class="icon-message-lines"></i>
        <div class="multiline-ellipsis">
          {{ comments }}
        </div>
      </div>
    </ng-template>

    <ng-template #contactNames let-contacts>
      <div class="card-description">
        <div class="card-text-first-column" *ngIf="shouldExistName(contacts)">
          Contacts ({{ contacts.length }})
        </div>
        <div class="card-text-second-column ellipse-text">
          <ng-container *ngFor="let contact of contacts; let isLast = last">
            <span *ngIf="!isLast">{{ contact.name }}, </span>
            <span *ngIf="isLast">{{ contact.name }}</span>
          </ng-container>
        </div>
      </div>
    </ng-template>

    <ng-template #completerNames let-completers>
      <ng-container
        *ngIf="activity.completers && activity.completers.length > 0"
      >
        <div class="card-text-second-column ellipse-text">
          <ng-container *ngFor="let completer of completers; let isLast = last">
            <span *ngIf="!isLast">{{ completer }}, </span>
            <span *ngIf="isLast">{{ completer }}</span>
          </ng-container>
        </div>
      </ng-container>
    </ng-template>
  </div>
</spr-cards>
