import { BaseModel } from './base.model';

export class FocusValueModel extends BaseModel {
  key: number;
  name: string;

  constructor(data: any = {}) {
    super();
    this.key = data.key;
    this.name = data.name;
  }

  static override transform(data: any): FocusValueModel {
    return new FocusValueModel(data);
  }
}
