import { TickerModel } from './ticker.model';
import { BaseModel } from './base.model';

export class TickerGroupModel extends BaseModel {
  selected: TickerModel[];
  other: TickerModel[];

  constructor(data: any = {}) {
    super();
    this.selected = this.markAsSelected(
      TickerModel.transformCollection(data.selected || [])
    );
    this.other = TickerModel.transformCollection(data.other || []);
  }

  static override transform(data: any): TickerGroupModel {
    return new TickerGroupModel(data);
  }

  private markAsSelected(tickers: TickerModel[]): TickerModel[] {
    return tickers.map((ticker: TickerModel) => {
      ticker.selected = true;
      return ticker;
    });
  }
}
