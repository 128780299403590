<spr-cards *ngIf="holding">
  <div class="card-container">
    <div class="card-heading">
      <span class="card-title">{{ holding.security.issuerName }}</span>
      <spr-badge [size]="cupcakeSizes.md" [color]="cupcakeFlavorsExtended.cyanLight">
        {{ tickerName }}
      </spr-badge>
    </div>

    <div class="card-info">
      <div>
        <span class="card-info-heading">
          <i class="icon-coins"></i>
          Value ({{ holding.currency }})
        </span>
        <div class="card-info-results">
          <i *ngIf="!holding.isAmountNegative && +holding.originalAmount" class="icon-changes-rise"></i>
          <i *ngIf="holding.isAmountNegative && +holding.originalAmount" class="icon-changes-drop"></i>
          <span>{{ holding.amount }} mm</span>
        </div>
      </div>
      <div>
        <span class="card-info-heading">Shares</span>
        <div class="card-info-results">
          <span>{{ holding.quantity }}</span>
        </div>
      </div>
      <div>
        <span class="card-info-heading">Shares Change</span>
        <div class="card-info-results">
          <i *ngIf="holding.originalQuantity && !holding.isQuantityNegative" class="icon-changes-rise"></i>
          <i *ngIf="holding.originalQuantity && holding.isQuantityNegative" class="icon-changes-drop"></i>
          <span>{{ holding.quarterlyChangeHoldingQuantity }}</span>
        </div>
      </div>
    </div>

    <div class="card-date">
      <span>{{ holding.holdingDateTime }}</span>
    </div>
  </div>
</spr-cards>
