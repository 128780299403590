import { Component, Input, OnInit } from '@angular/core';
import { SEARCH_SECURITIES_PLACEHOLDER } from '../../constants/text.constant';
import { FormControl } from '@angular/forms';
import { TickerGroupModel } from '../../../models/ticker-group.model';
import { TickerModel } from '../../../models/ticker.model';
import {
  debounceTime,
  finalize,
  last,
  Observable,
  Subscription,
  takeUntil,
} from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { GlobalSearchService } from '../../../services/api/v1/global-search.service';
import { LocalStorageService } from '@bdam/services';
import { SessionStorageService } from '../../../services/helpers/session-storage/session-storage.service';
import { FindBdCrmIdNumberFromUrlService } from '../../../services/helpers/find-id-number/find-bd-crm-id-number-from-url.service';
import { BaseComponent } from '../../../base/base.component';
import { TickerSearchModel } from '../../../models/ticker-search.model';
import { T_SEARCH_PARAM, TICKER_IDS_KEY } from '../../constants';

@Component({
  selector: 'app-account-contact-ticker',
  templateUrl: './account-contact-ticker.component.html',
  styleUrls: ['./account-contact-ticker.component.scss'],
})
export class AccountContactTickerComponent
  extends BaseComponent
  implements OnInit
{
  @Input() searchSecuritiesPlaceholder: string = SEARCH_SECURITIES_PLACEHOLDER;
  @Input() showBackNavigation = false;
  @Input() withCloseIcon = true;
  @Input() listTickerIdsFromSessionStorage!: string;
  @Input() listTickerIdsFromLocalStorage!: string;
  @Input() recentSearchKey!: string;

  control = new FormControl();
  tickerGroup: TickerGroupModel = new TickerGroupModel();
  selectedTickers: TickerModel[] = [];
  appliedTickers: TickerModel[] = [];
  appliedTickerIds: (string | number)[] = [];
  showRecentSearches = true;
  recentSearchControl = new FormControl();
  recentSearches: string[] = [];
  contactId = this.getId();

  private tickerSubscription!: Subscription;
  private params!: Params;
  private firstLoad = true;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private globalSearchService: GlobalSearchService,
    private localStorageService: LocalStorageService,
    private sessionStorageService: SessionStorageService,
    private readonly findBdCrmIdNumberFromUrlService: FindBdCrmIdNumberFromUrlService
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscribeToQueryParamsChange();
    this.subscribeToControlValueChange();
    this.subscribeToRecentSearchControl();
    this.getRecentSearches();
    this.executeTickers();
  }

  navigateToContactInformPage(): void {
    const { ...filters } = this.params;

    this.router.navigate([this.getCurrentUrl()], { queryParams: filters });
  }

  onReset(): void {
    const { ...filters } = this.params;

    this.sessionStorageService.removeQueryParamsFor(
      this.listTickerIdsFromSessionStorage
    );

    this.router.navigate([this.getCurrentUrl()], { queryParams: filters });
  }

  onApply(): void {
    const { ...filters } = this.params;

    const tickerIds = this.selectedTickers.map(
      (ticker: TickerModel) => ticker.securityId || ticker.securityGuid
    );
    const idSet = [...new Set([...this.appliedTickerIds, ...tickerIds])];
    const queryParams = {
      ...filters,
      tickerIds: JSON.stringify([...idSet]),
    };

    idSet.length
      ? this.sessionStorageService.setQueryParamsFor(
          this.listTickerIdsFromSessionStorage,
          [...idSet]
        )
      : this.sessionStorageService.removeQueryParamsFor(
          this.listTickerIdsFromSessionStorage
        );

    this.router.navigate([this.getCurrentUrl()], {
      queryParams: queryParams,
    });
  }

  getSelectedRecentSearch(item: string): void {
    this.onRecentSearchValueChange(item);
  }

  clearRecentSearchHistory(): void {
    this.localStorageService.clearRecentSearchesFor(this.recentSearchKey);

    this.getRecentSearches();
  }

  onTickerSelect(tickers: TickerModel[]): void {
    this.selectedTickers = tickers;
    this.appliedTickerIds = this.selectedTickers.map(
      (ticker: TickerModel) => ticker.securityId || ticker.securityGuid
    );

    if (this.control.value) {
      this.localStorageService.setRecentSearchesFor(
        this.recentSearchKey,
        this.control.value
      );
    }

    this.params = {
      ...this.params,
      tickerIds: this.appliedTickerIds.length
        ? JSON.stringify(this.appliedTickerIds)
        : null,
    };

    this.appliedTickerIds.length
      ? this.sessionStorageService.setQueryParamsFor(
          this.listTickerIdsFromSessionStorage,
          this.appliedTickerIds
        )
      : this.sessionStorageService.removeQueryParamsFor(
          this.listTickerIdsFromSessionStorage
        );

    this.router.navigate([], {
      queryParams: this.params,
    });
  }

  executeTickers(): void {
    this.loading = true;
    this.tickerSubscription && this.tickerSubscription.unsubscribe();

    this.tickerSubscription = this.getTickerRequest()
      .pipe(
        takeUntil(this.destroy$),
        last(),
        finalize(() => (this.loading = false))
      )
      .subscribe((res: TickerGroupModel) => {
        this.tickerGroup = res;
        this.appliedTickers = this.tickerGroup.selected;
        this.selectedTickers = this.tickerGroup.selected;
      });
  }

  getTickerRequest(): Observable<TickerGroupModel> {
    const tickerSearch = new TickerSearchModel({}).getProcessedData({
      text: this.control.value,
      ids: this.params[TICKER_IDS_KEY]
        ? JSON.parse(this.params[TICKER_IDS_KEY])
        : [],
    });

    return this.globalSearchService.getTickers(tickerSearch);
  }

  private applyQueryParamsToUrl(value: string): void {
    this.router.navigate([], {
      queryParams: { t_search: value },
      queryParamsHandling: 'merge',
    });
  }

  private subscribeToRecentSearchControl(): void {
    this.recentSearchControl.valueChanges
      .pipe(takeUntil(this.destroy$), debounceTime(500))
      .subscribe((value: string) => {
        this.onRecentSearchValueChange(value);
      });
  }

  private getRecentSearches(): void {
    this.recentSearches = this.localStorageService
      .getResentSearchesFor(this.recentSearchKey)
      .reverse();
  }

  private subscribeToControlValueChange(): void {
    this.control.valueChanges
      .pipe(takeUntil(this.destroy$), debounceTime(500))
      .subscribe((value: string) => {
        this.applyQueryParamsToUrl(value);
        this.getRecentSearches();
        this.executeTickers();
      });
  }

  private subscribeToQueryParamsChange(): void {
    this.activatedRoute.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe((params: Params) => {
        this.showRecentSearches =
          !params[T_SEARCH_PARAM] && !params[TICKER_IDS_KEY];
        this.params = params;

        if (params[T_SEARCH_PARAM] && this.firstLoad) {
          this.control.patchValue(params[T_SEARCH_PARAM], {});
          this.firstLoad = false;
        }
      });
  }

  private onRecentSearchValueChange(value: string): void {
    this.loading = true;
    this.control.patchValue(value);
    this.selectedTickers = [];
    this.appliedTickers = [];

    this.recentSearchControl.patchValue('', {
      emitEvent: false,
      onlySelf: true,
    });

    this.router.navigate([], {
      queryParams: { t_search: value },
      queryParamsHandling: 'merge',
    });
  }

  private getCurrentUrl(): string {
    return (
      this.localStorageService.getUrl(this.listTickerIdsFromLocalStorage) || '/'
    );
  }

  private getId(): number | null {
    if (!this.router.url) {
      return null;
    }

    return this.findBdCrmIdNumberFromUrlService.getId(this.router.url);
  }
}
