import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SecurityModel } from '../../../models/security.model';
import { BaseComponent } from '../../../base/base.component';

@Component({
  selector: 'app-holding-security-item-card',
  templateUrl: './holding-security-item-card.component.html',
  styleUrls: ['./holding-security-item-card.component.scss'],
})
export class HoldingSecurityItemCardComponent extends BaseComponent {
  @Input() securities: SecurityModel[] = [];
  @Input() showTimeIcon = false;

  @Output() selected = new EventEmitter<SecurityModel>();

  onSelect(security: SecurityModel): void {
    this.selected.emit(security);
  }
}
