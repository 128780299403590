import { ModalHeaderModule } from '../modal-header/modal-header.module';
import { RecentSearchesComponent } from './recent-searches.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [RecentSearchesComponent],
  imports: [CommonModule, ModalHeaderModule, ReactiveFormsModule],
  exports: [RecentSearchesComponent],
})
export class RecentSearchesModule {}
