import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ListModel } from '../../../models/list.model';

@Component({
  selector: 'app-list-card',
  templateUrl: './list-card.component.html',
  styleUrls: ['./list-card.component.scss'],
})
export class ListCardComponent {
  @Input() list!: ListModel;
  @Input() showInlineCount = true;
  @Input() isShared = false;
  @Input() itemsType: 'Accounts' | 'Contacts' = 'Accounts';

  @Output() toggleFavorites = new EventEmitter<boolean>();

  toggleFavoriteStatus(): void {
    if (this.list) {
      this.list.favorite = !this.list?.favorite;
      this.toggleFavorites.emit(this.list.favorite);
    }
  }
}
