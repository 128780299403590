<div class="default-tab-page-container">
  <ng-container *ngIf="loading; else showResponse">
    <app-skeleton-loader
      [size]="loaderSize"
      [numberOfRepetitions]="totalNumberOfRepetitions"
    ></app-skeleton-loader>
  </ng-container>
  <ng-template #showResponse>
    <ng-container
      *ngIf="activitiesList.length; else noResponse"
    >
      <div
        class="card-item"
        *ngFor="let activity of activitiesList"
      >
        <app-home-card
          [activity]="activity"
          (openActivity)="navigateToLogAction(activity)"
        ></app-home-card>
      </div>
      <app-skeleton-loader
        *ngIf="pagingInProgress"
        [size]="loaderSize"
        [numberOfRepetitions]="activityNumberOfRepetitions"
      ></app-skeleton-loader>
    </ng-container>
  </ng-template>
  <ng-template #noResponse>
    <div class="text-center main-margin-top">{{ noResponseMessage }}</div>
  </ng-template>
</div>
