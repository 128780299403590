import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '../../../base/base.component';
import {
  SKELETON_LOADER_SIZES,
  SkeletonLoaderSizesConstant,
} from '../../constants/skeleton-loader-sizes.constant';
import { ActivitiesListModel } from '../../../models/activities-list.model';
import { Paginate } from '../../../models/paginate';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ActivitiesService, LogActionStoreService } from '@bdam/services';
import { EmptyObjectService } from '../../../services/helpers/empty-object/empty-object.service';
import { ScrollService } from '../../../services/helpers/scroll/scroll.service';
import { SessionStorageService } from '../../../services/helpers/session-storage/session-storage.service';
import { LogOutService } from '../../../services/api/v1/log-out.service';
import {
  EMPTY,
  exhaustMap,
  filter,
  finalize,
  Observable,
  share,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { ActivitiesModel } from '../../../models/activities.model';
import {
  FILTER_AND_SORT_KEY,
  getLogActionNavigationParams,
  HOME_PATH,
  isLocalStorageEmpty,
  LOG_ACTION_PATH,
  TICKER_IDS_KEY,
} from '@bdam/shared';

@Component({
  selector: 'app-default-tab-page',
  templateUrl: './default-tab-page.component.html',
  styleUrls: ['default-tab-page.component.scss'],
})
export class DefaultTabPageComponent extends BaseComponent implements OnInit {
  @Input() noResponseMessage!: string;
  @Input() pageName!: string;
  @Input() routePathPageName!: string;
  @Input() logActionBackRouteTitle!: string;
  @Input() pageFilterAndSortKey!: string;
  @Input() pageTickerIdsKey!: string;
  @Input() backRouteKey!: string;
  @Input() totalNumberOfRepetitions = 5;
  @Input() activityNumberOfRepetitions = 3;
  @Input() loaderSize =
    SKELETON_LOADER_SIZES.big as SkeletonLoaderSizesConstant;
  @Input() newestFirstSorting: boolean | null = null;

  /**
   * Use default userTime when already have formatted date via timezoneOffset from backend
   *
   * @param {boolean} [hasDefaultFormatByUserTime=false]
   */
  @Input() hasDefaultFormatByUserTime = false;

  activitiesList: ActivitiesListModel[] = [];

  private paginate = new Paginate(this.pageSize, this.page);
  private hasMoreItems!: boolean;
  private filterAndSortParams: Params = {};

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly activitiesService: ActivitiesService,
    private readonly emptyObjectService: EmptyObjectService,
    private readonly scrollService: ScrollService,
    private readonly sessionStorageService: SessionStorageService,
    private readonly router: Router,
    private readonly logActionStoreService: LogActionStoreService,
    private readonly logOutService: LogOutService
  ) {
    super();
  }

  ngOnInit(): void {
    this.setQueryParamsFromSession();
    this.subscribeToQueryParamsChange();
    this.subscribeToScrolledToBottom();
    this.logOutIfLocalStorageEmpty();
  }

  onNextPage(): Observable<ActivitiesListModel[]> | Observable<never> {
    if (!this.hasMoreItems || this.pagingInProgress || this.loading) {
      return EMPTY;
    }

    this.paginate.nextPage();
    this.enablePagingLoader();

    return this.getActivitiesRequest();
  }

  getActivitiesRequest(): Observable<ActivitiesListModel[]> {
    return this.getActivitiesList(this.filterAndSortParams).pipe(
      tap((activitiesList: ActivitiesListModel[]) => {
        this.hasMoreItems = !!activitiesList.length;
        this.activitiesList = [...this.activitiesList, ...activitiesList];
      }),
      tap(() => {
        this.shouldDefaultFormatByUserTime();
      })
    );
  }

  getActivitiesList(params: Params): Observable<ActivitiesListModel[]> {
    this.filterAndSortParams = params;

    this.enableLoader();

    const activities = new ActivitiesModel({
      ...this.getParams(),
      ...this.paginate,
      pageName: this.pageName,
    });

    const isNewestFirstSorting: boolean =
      'newestFirstSorting' in this.getParams();

    if (this.newestFirstSorting !== null && !isNewestFirstSorting) {
      activities.newestFirstSorting = this.newestFirstSorting;
    }

    return this.activitiesService
      .getActivitiesList(activities)
      .pipe(finalize(() => this.disableLoaders()));
  }

  navigateToLogAction(activity: ActivitiesListModel): void {
    if (!activity) {
      return;
    }

    const navigationParams = getLogActionNavigationParams(activity);

    if (navigationParams) {
      this.logActionStoreService.saveLogActionBackRouteTitle(
        this.logActionBackRouteTitle
      );
      this.router
        .navigate([HOME_PATH, LOG_ACTION_PATH, navigationParams])
        .then(() => {
          this.logActionStoreService.saveBackRoute([
            this.backRouteKey,
            this.routePathPageName,
          ]);
        });
    }
  }

  private subscribeToQueryParamsChange(): void {
    this.activatedRoute.queryParams
      .pipe(
        takeUntil(this.destroy$),
        switchMap((params: Params) => this.getActivitiesList(params)),
        tap((activitiesList: ActivitiesListModel[]) => {
          this.hasMoreItems = !!activitiesList.length;
          this.activitiesList = activitiesList;
        }),
        tap(() => {
          this.shouldDefaultFormatByUserTime();
        }),
        share()
      )
      .subscribe();
  }

  private setQueryParamsFromSession(): void {
    this.updateCurrentTime();

    const filterAndSort = this.sessionStorageService.getQueryParamsFor(
      this.pageFilterAndSortKey
    );
    const tickerIds = this.sessionStorageService.getQueryParamsFor(
      this.pageTickerIdsKey
    );
    const query: Params = {};

    filterAndSort &&
      (query[FILTER_AND_SORT_KEY] = JSON.stringify(filterAndSort));
    tickerIds && (query[TICKER_IDS_KEY] = JSON.stringify(tickerIds));

    this.router.navigate([], {
      queryParams: query,
    });
  }

  private getParams(): Params {
    const filterAndSortParams = this.filterAndSortParams[FILTER_AND_SORT_KEY];
    const tickerIdsParams = this.filterAndSortParams[TICKER_IDS_KEY];
    const filterAndSort = filterAndSortParams
      ? JSON.parse(filterAndSortParams)
      : {};
    const tickerIds = tickerIdsParams ? JSON.parse(tickerIdsParams) : [];

    return { ...filterAndSort, ...this.getTickerIdsByType(tickerIds) };
  }

  private subscribeToScrolledToBottom(): void {
    this.scrollService.isScrolledToBottom$
      .pipe(
        takeUntil(this.destroy$),
        filter(Boolean),
        exhaustMap(() => this.onNextPage())
      )
      .subscribe();
  }

  private logOutIfLocalStorageEmpty(): void {
    if (isLocalStorageEmpty()) {
      this.logOutService.logOut().pipe(takeUntil(this.destroy$)).subscribe();

      return;
    }
  }

  private shouldDefaultFormatByUserTime(): void {
    if (!this.hasDefaultFormatByUserTime) {
      return;
    }

    // @ts-ignore
    this.activitiesList = this.activitiesList.map(
      (activity: ActivitiesListModel) => ({
        ...activity,
        dateTime: activity.defaultDataTime,
      })
    );
  }

  private updateCurrentTime(): void {
    const filterAndSort = this.sessionStorageService.getQueryParamsFor(
      this.pageFilterAndSortKey
    );

    if (!filterAndSort) {
      return;
    }

    const { startDate, endDate } = filterAndSort;
    const { updatedStartDate, updatedEndDate } = this.getCurrentTime(
      startDate,
      endDate
    );

    this.sessionStorageService.setQueryParamsFor(this.pageFilterAndSortKey, {
      ...filterAndSort,
      startDate: updatedStartDate,
      endDate: updatedEndDate,
    });
  }
}
