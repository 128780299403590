import { SkeletonLoaderModule } from '../skeleton-loader/skeleton-loader.module';
import { ListCardComponent } from './list-card.component';
import { CardsModule } from '@ipreo/ngx-sprinkles';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [ListCardComponent],
  imports: [CommonModule, CardsModule, SkeletonLoaderModule],
  exports: [ListCardComponent],
})
export class ListCardModule {}
