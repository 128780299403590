<div class="home-filter-container">
  <div class="filter-content">
    <ul *ngIf="form; else showLoader">
      <li *ngFor="let filter of filters; index as index; trackBy: trackByFn">
        <ng-container [ngSwitch]="filter.type">

          <ng-container *ngSwitchCase="'switch'">
            <spr-switch [legacy]="false"
                        [size]="cupcakeSizes.md"
                        [formControl]="form.get(filter.formControlName).get('value')">
              <span class="filter-label">
              {{ filter.name }}
            </span>
            </spr-switch>
          </ng-container>

          <ng-container *ngSwitchCase="'expendable-list'">
            <spr-expandable-container [expanded]="expendedFilters[filter.id]">
              <ng-container title>
                <div class="expendable-container"
                     [attr.id]="filter.id"
                     (click)="onExpend(filter)"
                >
                  <div class="expendable-container-title">
                    <ng-container *ngIf="filter.id !== loggedActionsId && filter.id !== meetingsId">
                      <i [class]="filter.icon"></i>
                      <span class="filter-name">{{ filter.name }}</span>
                    </ng-container>
                    <spr-checkbox *ngIf="filter.id === loggedActionsId"
                                  [legacy]="false"
                                  (click)="$event.stopPropagation(); expendedFilters[filter.id] || (expendedFilters[filter.id] = true)"
                                  [checked]="isLoggedActionsChecked"
                                  (change)="onChangeLoggedActions($event)"
                                  id="checkbox-logged-actions-id">
                    </spr-checkbox>
                    <label *ngIf="filter.id === loggedActionsId"
                           for="checkbox-logged-actions-id" class="filter-name general-checkbox-label">
                      {{ filter.name }}
                    </label>
                    <spr-checkbox *ngIf="filter.id === meetingsId"
                                  [legacy]="false"
                                  (click)="$event.stopPropagation(); expendedFilters[filter.id] || (expendedFilters[filter.id] = true)"
                                  [checked]="isMeetingsChecked"
                                  (change)="onChangeMeetings($event)"
                                  id="checkbox-meetings-id">
                    </spr-checkbox>
                    <label *ngIf="filter.id === meetingsId"
                           for="checkbox-meetings-id" class="filter-name general-checkbox-label">
                      {{ filter.name }}
                    </label>
                    <span  class="filter-name main-margin-small-left" *ngIf="filter.id === loggedActionsId && selectedLogActionsLength">
                        ({{ selectedLogActions.length }})
                    </span>
                    <span  class="filter-name main-margin-small-left" *ngIf="filter.id === meetingsId && selectedMeetingsLength">
                        ({{ selectedMeetings.length }})
                    </span>
                  </div>
                  <i *ngIf="!expendedFilters[filter.id]" class="icon-downArrow"></i>
                  <i *ngIf="expendedFilters[filter.id]" class="icon-upArrow"></i>
                </div>
              </ng-container>

              <ng-container expanded>
                <div>
                  <ng-container [ngSwitch]="filter.itemTypes">
                    <ng-container *ngFor="let extraItem of filter.extraItems;index as extraIndex; trackBy: trackByFn">
                      <ng-container [ngSwitch]="extraItem.type">
                        <ng-container *ngSwitchCase="'checkbox'">
                          <div class="extra-item">
                            <spr-checkbox [legacy]="false"
                                          [formControl]="form.get(extraItem.formControlName)['controls'][extraIndex].get('value')">
                            <span class="filter-label" (click)="$event.stopPropagation();">
                              {{ extraItem.name }}
                            </span>
                            </spr-checkbox>
                          </div>
                        </ng-container>

                        <ng-container *ngSwitchCase="'radio'">
                          <div class="extra-item">
                            <spr-radio-button [name]="'range'" [legacy]="extraItem.value"
                                              [checked]="extraItem.value"
                                              [formControl]="form.get(extraItem.formControlName)['controls'][extraIndex].get('value')">
                              <span class="filter-label" (click)="$event.stopPropagation();">
                                {{ extraItem.name }}
                              </span>
                            </spr-radio-button>
                          </div>
                        </ng-container>

                        <ng-container *ngSwitchCase="'switch'">
                          <div class="extra-item">
                            <spr-switch [legacy]="false"
                                        [size]="cupcakeSizes.md"
                                        [formControl]="form.get(extraItem.formControlName)['controls'][extraIndex].get('value')">
                              <span class="filter-label" (click)="$event.stopPropagation();">
                                {{ extraItem.name }}
                              </span>
                            </spr-switch>
                          </div>
                        </ng-container>
                      </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="'checkbox'">
                      <ul>
                        <li class="filter-row" *ngFor="let item of filter.items; index as index; trackBy: trackByFn" (change)="onCheckboxItemSelect(item, filter.id)">
                          <spr-checkbox [legacy]="false"
                                        [formControl]="form.get(filter.formControlName)['controls'][index].get('value')">
                            <span class="filter-label" (click)="$event.stopPropagation();">
                              {{ item.name }}
                            </span>
                          </spr-checkbox>
                        </li>
                      </ul>
                    </ng-container>

                    <ng-container *ngSwitchCase="'radio'">
                      <ul>
                        <li class="filter-row" *ngFor="let item of filter.items; index as index; trackBy: trackByFn" (click)="onSelect(filter, item)">
                          <spr-radio-button [name]="item.formControlName"
                                            [legacy]="false"
                                            [checked]="item.value"
                                            [formControl]="form.get(filter.formControlName)['controls'][index].get('value')"
                                            (change)="onRadioChange(form.get(filter.formControlName)['controls'], index)">
                             <span class="filter-label" (click)="$event.stopPropagation()">
                              {{ item.name }}
                            </span>
                          </spr-radio-button>
                        </li>
                      </ul>
                    </ng-container>
                  </ng-container>

                </div>
              </ng-container>

            </spr-expandable-container>
          </ng-container>
        </ng-container>
      </li>
    </ul>
    <ng-template #showLoader>
      <app-skeleton-loader [numberOfRepetitions]="5" [size]="'middle'"></app-skeleton-loader>
    </ng-template>
  </div>

  <div class="actions-container">
    <button spr-button
            (click)="onFilterReset()"
            [size]="cupcakeSizes.lg">Reset Filters</button>

    <button spr-button
            (click)="onFilterApply()"
            [size]="cupcakeSizes.lg">Apply Filters</button>
  </div>

</div>
