<div class="account-profile-card-container">
  <div class="cards-heading" *ngIf="accountFocus?.length">{{ title }}</div>
  <ng-container *ngIf="showLoader; else showProfileEducation">
    <app-skeleton-loader [size]="'middle'" [numberOfRepetitions]="2"></app-skeleton-loader>
  </ng-container>
  <ng-template #showProfileEducation>
    <div *ngFor="let focus of accountFocus">
      <spr-cards>
        <div class="card-content card-ellipsis-text">
          {{focus?.focusName}}
        </div>
        <div *ngFor="let item of focus.focuses; let i = index">
          <div class="section-details">
            <div class="details-type">{{item?.key}}</div>
            <div class="details-value">
              <span
                *ngFor="let value of item.value; let last = last"
              >
                {{value.name}}{{ !last ? ', ' : '' }}
              </span>
            </div>
          </div>
        </div>
      </spr-cards>
    </div>
  </ng-template>
</div>
