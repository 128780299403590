import { BaseModel } from './base.model';

export class Paginate extends BaseModel {
  pageSize: number;
  page: number;

  constructor(pageSize: number, page: number) {
    super();
    this.pageSize = pageSize;
    this.page = page;
  }

  nextPage(): { pageSize: number; page: number } {
    this.page++;

    return { pageSize: this.pageSize, page: this.page };
  }

  previewsPage(): { pageSize: number; page: number } {
    this.page--;

    return { pageSize: this.pageSize, page: this.page };
  }

  reset(): { pageSize: number; page: number } {
    this.page = 0;

    return { pageSize: this.pageSize, page: this.page };
  }
}
