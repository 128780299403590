import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountContactTickerComponent } from './account-contact-ticker.component';
import { RecentSearchesModule } from '../recent-searches/recent-searches.module';
import { TickerModule } from '../ticker/ticker.module';

@NgModule({
  declarations: [AccountContactTickerComponent],
  imports: [CommonModule, RecentSearchesModule, TickerModule],
  exports: [AccountContactTickerComponent],
})
export class AccountContactTickerModule {}
