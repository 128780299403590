<div class="ticker-container">
  <div class="ticker-header" *ngIf="!headerWithArrow">
    <app-modal-header
      [control]="control"
      [placeholder]="placeholder"
      [showBackNavigation]="false"
      [withCloseIcon]="true"
      (backNavigation)="onClose()"
    ></app-modal-header>
  </div>
  <div class="ticker-header" *ngIf="headerWithArrow">
    <app-modal-header (backNavigation)="onClose()"></app-modal-header>
  </div>

  <div class="main-container">
    <ng-container *ngIf="showLoader; else showAppliedAndSelectedTickers">
      <app-skeleton-loader [size]="'middle'" [numberOfRepetitions]="6"></app-skeleton-loader>
    </ng-container>

    <ng-template #showAppliedAndSelectedTickers>

      <ng-container *ngIf="appliedTickers.length">
        <div class="section">
          <div class="section-title">Applied Tickers</div>
          <ng-container *ngTemplateOutlet="tickerItems;context:{items: appliedTickers}"></ng-container>
        </div>

        <div class="section">
          <div class="section-title">Select Tickers</div>
          <ng-container *ngTemplateOutlet="tickerItems;context:{items: tickers}"></ng-container>
        </div>
      </ng-container>

      <ng-container *ngIf="!appliedTickers.length">
        <ng-container *ngTemplateOutlet="tickerItems;context:{items: tickers}"></ng-container>
      </ng-container>
    </ng-template>

    <ng-template #tickerItems let-tickers="items">
      <ng-container *ngIf=" tickers && tickers.length; else noTicker">
        <ul>
          <li *ngFor="let ticker of tickers">
            <spr-cards>
              <spr-checkbox [color]="cupcakeFlavorsExtended.warningLight"
                            (change)="onTicketSelect(ticker)"
                            class="card-header"
                            [legacy]="false"
                            [checked]="ticker.selected"
                            [size]="cupcakeSizes.lg">
                <div class="card-title">{{ticker.securityName}}</div>
              </spr-checkbox>

              <div class="description">
                <span>{{ticker.primaryTickerName}}</span>,
                {{ticker.primaryExchangeName}}
              </div>
            </spr-cards>
          </li>
        </ul>
      </ng-container>
      <ng-template #noTicker>
        <div class="text-center" *ngIf="tickers && !tickers.length">
          {{ noResultsMessage }}
        </div>
      </ng-template>
    </ng-template>
  </div>

  <div class="ticker-footer" *ngIf="!actionFooter">
    <button
      (click)="onReset()"
      spr-button
      [size]="cupcakeSizes.lg">
      Reset Filters
    </button>
    <button
      (click)="onApply()"
      spr-button
      [size]="cupcakeSizes.lg">
      Apply Filters
    </button>
  </div>

  <div class="ticker-footer" *ngIf="actionFooter">
    <button
      (click)="onBack()"
      spr-button
      [size]="cupcakeSizes.lg">
      Cancel
    </button>
    <button
      (click)="onApply()"
      spr-button
      [size]="cupcakeSizes.lg">
      Update Selection
    </button>
  </div>
</div>

