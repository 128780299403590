import { BaseModel } from './base.model';

export class HoldingSecurityModel extends BaseModel {
  exchangeName: string;
  issuerName: string;
  securityId: number;
  ticker: string;
  holderCount: number;
  detailedTitle: string;

  constructor(data: any = {}) {
    super();
    this.exchangeName = data.exchangeName;
    this.issuerName = data.issuerName;
    this.securityId = data.securityId;
    this.ticker = data.ticker;
    this.holderCount = data.holderCount || 0;
    this.detailedTitle = `${this.issuerName} (${this.holderCount})`;
  }

  static override transform(data: any): HoldingSecurityModel {
    return new HoldingSecurityModel(data);
  }
}
