export const ACTION_TYPE_IDS_KEY = 'actionTypeIds';
export const MEETING_TYPE_IDS_KEY = 'meetingTypeIds';
export const TICKER_IDS_KEY = 'tickerIds';
export const FILTER_AND_SORT_KEY = 'filterAndSort';
export const NEWEST_FIRST_SORTING_KEY = 'newestFirstSorting';
export const MY_COVERAGE_KEY = 'myCoverage';
export const IS_PARTICIPANT_KEY = 'isParticipant';
export const START_DATE_KEY = 'startDate';
export const END_DATE_KEY = 'endDate';
export const DEFAULT_DATE_RANGE_DAYS = 30;
export const FILTER_AND_SORT_PARAM_KEY = 'filter-and-sort';
export const TICKER_PARAM_KEY = 'ticker';
export const MEETINGS_FILTER_ID = 'meetings';
export const LOGGED_ACTIONS_FILTER_ID = 'logged_actions';
export const DATE_RANGE_KEY = 'date_range';
