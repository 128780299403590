import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';

@Directive({
  selector: '[appHeaderIntersectionVisibility]',
})
export class HeaderIntersectionVisibilityDirective
  implements AfterViewInit, OnDestroy
{
  @Input() set defaultTitle(title: string) {
    if (this.baseTitle.length === 0) {
      this.baseTitle = title;
    }
  }
  @Input() title!: string;
  @Input() threshold = 0.6;

  @Output()
  visibilityElementChanged: EventEmitter<string> = new EventEmitter<string>();

  private baseTitle = '';

  private prevIntersectionRatio = 0;
  private intersectionObserver!: IntersectionObserver;

  constructor(private readonly elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    const options = {
      threshold: this.threshold,
    };

    const handleIntersect: (entries: any[]) => void = (entries: any[]) => {
      entries.forEach((entry: any) => {
        if (entry.intersectionRatio > this.prevIntersectionRatio) {
          this.visibilityElementChanged.emit(this.baseTitle);
        } else if (this.prevIntersectionRatio > entry.intersectionRatio) {
          this.visibilityElementChanged.emit(this.title);
        }

        this.prevIntersectionRatio = entry.intersectionRatio;
      });
    };

    this.intersectionObserver = new IntersectionObserver(
      handleIntersect,
      options
    );
    this.intersectionObserver.observe(this.elementRef.nativeElement);
  }

  ngOnDestroy(): void {
    this.intersectionObserver.disconnect();
  }
}
