import { HoldingsModel } from '../../../models/holdings.model';
import { BaseComponent } from '../../../base/base.component';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-top-holdings-card',
  templateUrl: './top-holdings-card.component.html',
  styleUrls: ['./top-holdings-card.component.scss'],
})
export class TopHoldingsCardComponent extends BaseComponent {
  @Input() tickerName!: string;
  @Input() holding!: HoldingsModel;
}
