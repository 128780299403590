import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '../../../base/base.component';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import {
  Filter,
  FilterGeneralCheckBox,
  FilterItem,
} from '../../interfaces/home-filter.interface';
import { ActivityTypeModel } from '../../../models/activity-type.model';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ActivitiesService } from '@bdam/services';
import { SessionStorageService } from '../../../services/helpers/session-storage/session-storage.service';
import { FindBdCrmIdNumberFromUrlService } from '../../../services/helpers/find-id-number/find-bd-crm-id-number-from-url.service';
import { ActivitiesModel } from '../../../models/activities.model';
import { ActionTypeModel } from '../../../models/action-type.model';
import { takeUntil } from 'rxjs';
import { FILTER_AND_SORT_RECENT_ACTIVITIES_TITLE } from '../../constants/text.constant';
import {
  ACTION_TYPE_IDS_KEY,
  DATE_RANGE_KEY,
  END_DATE_KEY,
  FILTER_AND_SORT_KEY,
  LOGGED_ACTIONS_FILTER_ID,
  MEETING_TYPE_IDS_KEY,
  MEETINGS_FILTER_ID,
  NEWEST_FIRST_SORTING_KEY,
  START_DATE_KEY,
} from '../../constants';

@Component({
  selector: 'app-account-contact-filter-and-sort',
  templateUrl: './account-contact-filter-and-sort.component.html',
  styleUrls: ['./account-contact-filter-and-sort.component.scss'],
})
export class AccountContactFilterAndSortComponent
  extends BaseComponent
  implements OnInit
{
  @Input() detailsFilterAndSort!: Filter[];
  @Input() listInformationRecentActivitiesFilterAndSort!: string;

  form!: FormGroup;
  filters: Filter[] = [];
  contactId = this.findBdCrmIdNumberFromUrlService.getId(this.router.url);
  filterAndSortRecentActivitiesTitle: string =
    FILTER_AND_SORT_RECENT_ACTIVITIES_TITLE;
  typeHeader = 'textHeader';

  private activityTypes!: ActivityTypeModel;
  private formData: any = {};
  private params: Params = {};
  private filterAndSortParams: Params = {};
  private parentPage!: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private activitiesService: ActivitiesService,
    private sessionStorageService: SessionStorageService,
    private readonly findBdCrmIdNumberFromUrlService: FindBdCrmIdNumberFromUrlService
  ) {
    super();
  }

  ngOnInit(): void {
    this.filters = JSON.parse(JSON.stringify(this.detailsFilterAndSort));

    this.initParams();
    this.getActivityTypes();
  }

  navigateToAccountInformationOrContact(): void {
    this.router.navigate(['../'], { relativeTo: this.activatedRoute });
  }

  onFiltersReset(): void {
    this.sessionStorageService.removeQueryParamsFor(
      this.listInformationRecentActivitiesFilterAndSort
    );

    this.router.navigate(['../'], { relativeTo: this.activatedRoute });
  }

  onFiltersApply(): void {
    const activityModel = new ActivitiesModel(this.getProcessedData());

    this.sessionStorageService.setQueryParamsFor(
      this.listInformationRecentActivitiesFilterAndSort,
      activityModel
    );

    this.router.navigate(['../'], { relativeTo: this.activatedRoute });
  }

  initFilters(): void {
    if (!this.filterAndSortParams[ACTION_TYPE_IDS_KEY]) {
      return;
    }
    this.initActionTypeIds();
    this.initMeetingTypeIds();
    this.initSortingFilter();
    this.initDateRangeFilter();
  }

  initActionTypeIds(): void {
    const actionTypeIds = this.filters.find(
      (item: Filter) => item.id === LOGGED_ACTIONS_FILTER_ID
    );
    this.filterAndSortParams[ACTION_TYPE_IDS_KEY].forEach((id: number) => {
      const actionItem = actionTypeIds?.items.find(
        (item: FilterItem) => item.id === id
      );
      actionItem && (actionItem.value = true);
    });
  }

  initMeetingTypeIds(): void {
    const meetingTypeIds = this.filters.find(
      (item: Filter) => item.id === MEETINGS_FILTER_ID
    );
    this.filterAndSortParams[MEETING_TYPE_IDS_KEY].forEach((id: number) => {
      const meetingItem = meetingTypeIds?.items.find(
        (item: FilterItem) => item.id === id
      );
      meetingItem && (meetingItem.value = true);
    });
  }

  initSortingFilter(): void {
    const sorting = this.filters.find((item: Filter) => item.id === 'sorting');
    const newest = sorting?.items.find((item: FilterItem) => item.id === 1);
    const oldest = sorting?.items.find((item: FilterItem) => item.id === 2);

    newest &&
      (newest.value = this.filterAndSortParams[NEWEST_FIRST_SORTING_KEY]);
    oldest &&
      (oldest.value = !this.filterAndSortParams[NEWEST_FIRST_SORTING_KEY]);
  }

  initDateRangeFilter(): void {
    const dateRange = this.filters.find(
      (item: Filter) => item.id === DATE_RANGE_KEY
    );
    const date = dateRange?.items.find(
      (item: FilterItem) =>
        item.startDate === this.filterAndSortParams[START_DATE_KEY] &&
        item.endDate === this.filterAndSortParams[END_DATE_KEY]
    );
    if (date) {
      dateRange?.items.forEach((item: FilterItem) => (item.value = false));
      date.value = true;
    }
  }

  onGeneralCheckBoxFilter(filterGeneralCheckBox: FilterGeneralCheckBox): void {
    const checkBoxControls = this.form.controls[
      filterGeneralCheckBox.id
    ] as FormArray;

    if (checkBoxControls === undefined) {
      return;
    }

    const checkBoxActionTypes: any[] = [];

    checkBoxControls.value.forEach((checkBoxValue: ActionTypeModel) =>
      checkBoxActionTypes.push({
        ...checkBoxValue,
        value: filterGeneralCheckBox.isChecked,
      })
    );

    this.form.controls[filterGeneralCheckBox.id].setValue([
      ...checkBoxActionTypes,
    ]);
  }

  private initParams(): void {
    this.params = this.activatedRoute.snapshot.queryParams;
    this.parentPage = this.params['parent'];

    if (this.params[FILTER_AND_SORT_KEY]) {
      this.filterAndSortParams = JSON.parse(this.params[FILTER_AND_SORT_KEY]);
    }

    this.setFilterByPage();
  }

  private setFilterByPage(): void {
    this.filters = JSON.parse(JSON.stringify(this.detailsFilterAndSort));
  }

  private initForm(): void {
    this.filters.forEach((filter: Filter) => {
      if (filter.expendable) {
        const formArrValues: FormGroup[] = [];

        filter.items.forEach((item: FilterItem) => {
          formArrValues.push(this.fb.group(item));
        });

        if (filter.extraItems.length) {
          const extraItems: FormGroup[] = [];
          filter.extraItems.forEach((item: FilterItem) => {
            extraItems.push(this.fb.group(item));
            this.formData[item.formControlName] = new FormArray(extraItems);
          });
        }
        this.formData[filter.formControlName] = new FormArray(formArrValues);
      } else {
        this.formData[filter.formControlName] = this.fb.group(filter);
      }
    });

    this.form = this.fb.group(this.formData);
  }

  private getActivityTypes(): void {
    this.activitiesService
      .getActivityTypes()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: ActivityTypeModel) => {
        this.activityTypes = res;
        this.setMeetingAndActionTypes();
        this.initFilters();
        this.initForm();
      });
  }

  private setMeetingAndActionTypes(): void {
    const actionsFilter = this.filters.find(
      (filter: Filter) => filter.id === LOGGED_ACTIONS_FILTER_ID
    );
    const meetingsFilter = this.filters.find(
      (filter: Filter) => filter.id === MEETINGS_FILTER_ID
    );
    actionsFilter && (actionsFilter.items = this.activityTypes.actionTypes);
    meetingsFilter && (meetingsFilter.items = this.activityTypes.meetingTypes);
  }

  private getProcessedData(): ActivitiesModel | any {
    const formValue = this.form.value;

    return {
      actionTypeIds: formValue.actionTypeIds
        .filter((action: FilterItem) => action.value)
        .map((action: Filter) => action.id),
      meetingTypeIds: formValue.meetingTypeIds
        .filter((action: FilterItem) => action.value)
        .map((action: Filter) => action.id),
      newestFirstSorting: formValue.newestFirstSorting[0].value,
      startDate: formValue.dateRange.find((range: FilterItem) => range.value)
        .startDate,
      endDate: formValue.dateRange.find((range: FilterItem) => range.value)
        .endDate,
      tickerIds: [],
      page: 0,
      pageSize: 15,
    };
  }
}
