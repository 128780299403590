import { UserModel } from './user.model';
import moment from 'moment';
import { AssetConfigInterface } from '../shared/interfaces/asset-config.interface';
import { UserInterface } from '../shared/interfaces/user.interface';
import { formatDateTimeWithTimezone } from '../shared/utils/format-date-time-with-timezone.utils';
import { formatDateTime } from '../shared/utils/format-date-time.utils';

moment.updateLocale('en', {
  meridiem: function (hour, minute, isLowercase) {
    if (hour >= 12) return isLowercase ? 'p.m' : 'PM';
    else return isLowercase ? 'a.m' : 'AM';
  },
});

export class BaseModel {
  constructor(data: any = {}) {
    //
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static transformCollection(res: any): any {
    if (res.items) {
      res.items = res.items.map((item: any) => this.transform(item));
    } else if (res instanceof Array) {
      res = res.map((item: any) => this.transform(item));
    }
    return res;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static transform(data: any): any {
    return data;
  }

  protected formatByUserTimePreference(dateTime: string): string {
    return formatDateTimeWithTimezone(
      dateTime,
      this.authUser.userPreferences.dateTimePattern,
      this.authUser.userPreferences.dstTimeZone.timezone_offset
    );
  }

  protected defaultFormatByUserTimePreference(dateTime: string): string {
    return formatDateTime(
      dateTime,
      this.authUser.userPreferences.dateTimePattern
    );
  }

  protected formatByUserAssetPreference(asset: number): string {
    const configs: AssetConfigInterface = {
      currency: this.authUser.userPreferences.currency,
      maximumSignificantDigits: 3,
    };

    if (this.authUser.userPreferences.showCurrencySymbol) {
      configs.style = 'currency';
    }

    const formatter: Intl.NumberFormat = new Intl.NumberFormat(
      this.authUser.userPreferences.culture,
      configs
    );

    return formatter.format(asset);
  }

  protected get authUser(): UserModel {
    const userJson = localStorage.getItem('user');
    return new UserModel(JSON.parse(<string>userJson));
  }

  protected get userJson(): UserInterface {
    const userJson = localStorage.getItem('user');
    return JSON.parse(<string>userJson);
  }
}
