<div class="contact-card-container" *ngIf="contact">
  <spr-cards *ngIf="contact" [ngClass]="{'contact-without-budges': contactWithoutBudge === true}">
    <div class="title">
      <div class="name">
        <span *ngIf="selected">(Selected)</span>
        {{ displayName }}
      </div>
      <div class="budges">
        <i class="icon-record-type" *ngIf="contact.accountInfo.type === accountInfoTypes.issuer"></i>
        <spr-badge
          *ngIf="contact.covered"
          class="small-budge"
          [size]="cupcakeSizes.md"
          [square]="true">C</spr-badge>
        <spr-badge
          *ngIf="contact.prContactID || contact.crmContactID"
          class="middle-budge"
          [size]="cupcakeSizes.md"
          [square]="true"
          [ngClass]=" { 'middle-budge-bd-crm': contact.prContactID && contact.crmContactID,
            'middle-budge-bd-only': contact.prContactID && !contact.crmContactID,
            'middle-budge-crm-only': !contact.prContactID && contact.crmContactID
          }"
        >
          <span
            class="middle-budge-bd"
            *ngIf="contact.prContactID"
            [class.main-margin-small-right]="contact.crmContactID"
          >BD</span>
          <span
            class="middle-budge-crm"
            *ngIf="contact.crmContactID"
          >CRM</span>
        </spr-badge>

      </div>
    </div>
    <div class="job-details ellipse-text">
      {{ contact.jobTitle }}
    </div>
    <div class="account-details">
      <i class="icon-accounts"></i>
      <div class="ellipse-text">{{ contact.accountInfo.name }}</div>
    </div>
  </spr-cards>
</div>
