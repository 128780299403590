import { UserModel } from '../../../models/user.model';
import { Injectable } from '@angular/core';
import { HttpHeadersService } from '../../http-client/http-headers.service';
import { HealthCheckService } from '@bdam/services';

export const USER_STORAGE_KEY = 'user';
export const ACCESS_TOKEN_STORAGE_KEY = 'access_token';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  authUser!: UserModel;

  constructor(
    private readonly httpHeadersService: HttpHeadersService,
    private readonly healthCheckService: HealthCheckService
  ) {}

  setAuthUser(user: UserModel): void {
    localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(user));
    this.healthCheckService.runHealthCheck();
    this.authUser = user;
  }

  setAuthUserAccessToken(token: string): void {
    localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, token);
    this.httpHeadersService.setAuth(token);
  }

  getAuthUser(): UserModel {
    const str = localStorage.getItem(USER_STORAGE_KEY);

    return str ? new UserModel(JSON.parse(str)) : new UserModel({});
  }
}
