import { DIVISOR_FOR_CURRENCY_VALUE } from '../constants';

export function formatCurrencyNumber(
  number: number,
  currency: string,
  divisor: number = DIVISOR_FOR_CURRENCY_VALUE
): string {
  const formatter: Intl.NumberFormat = new Intl.NumberFormat(currency, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formatter.format(number / divisor);
}
