import { SkeletonLoaderModule } from '../skeleton-loader/skeleton-loader.module';
import { HomeFilterComponent } from './home-filter.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  ButtonModule,
  CheckboxModule,
  ExpandableContainerModule,
  RadioButtonModule,
  SwitchModule,
} from '@ipreo/ngx-sprinkles';

@NgModule({
  declarations: [HomeFilterComponent],
  imports: [
    CommonModule,
    ExpandableContainerModule,
    RadioButtonModule,
    SwitchModule,
    CheckboxModule,
    ButtonModule,
    ReactiveFormsModule,
    SkeletonLoaderModule,
  ],
  exports: [HomeFilterComponent],
})
export class HomeFilterModule {}
