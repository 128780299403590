<div class="recent-searches-container">
  <app-modal-header [placeholder]="placeholder"
                    [control]="control"
                    [showBackNavigation]="showBackNavigation"
                    [withCloseIcon]="withCloseIcon"
                    (backNavigation)="onBackNavigation()"></app-modal-header>

  <div class="recent-searches">
    <div class="recent-searches-heading">
      <div class="title">Recent Searches</div>
      <p (click)="clearAll()" *ngIf="recentSearches.length">Clear</p>
    </div>

    <div class="recent-searches-content">
      <ng-container *ngIf="recentSearches.length; else noDataAvailable">
        <ul *ngFor="let item of recentSearches">
          <li (click)="select(item)">
            <i class="icon-recentSearchTime"></i>
            <span>{{ item }}</span>
          </li>
        </ul>
      </ng-container>

      <ng-template #noDataAvailable>
        <div>No recent searches</div>
      </ng-template>
    </div>

  </div>
</div>
