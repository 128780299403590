import { HoldingSecurityItemCardModule } from '../holding-security-item-card/holding-security-item-card.module';
import { HoldingsSecurityCardComponent } from './holdings-security-card.component';
import { SkeletonLoaderModule } from '../skeleton-loader/skeleton-loader.module';
import { ModalHeaderModule } from '../modal-header/modal-header.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [HoldingsSecurityCardComponent],
  imports: [
    CommonModule,
    ModalHeaderModule,
    SkeletonLoaderModule,
    HoldingSecurityItemCardModule,
  ],
  exports: [HoldingsSecurityCardComponent],
})
export class HoldingsSecurityCardModule {}
