import { BaseModel } from './base.model';

export class ListModel extends BaseModel {
  id: number;
  name: string;
  description: string;
  listTypeId: number;
  companyId: number;
  ownerId: number;
  isPrivate: boolean;
  isHidden: boolean;
  isDeleted: boolean;
  teamIds: number[];
  membersCount: number;
  lastUpdatedUtc: string;
  lastUpdatedBy: number;
  ownerFullName: string;
  favorite: boolean;

  constructor(data: any = {}) {
    super();
    this.id = data.id;
    this.name = data.name;
    this.description = data.description;
    this.listTypeId = data.listTypeId;
    this.companyId = data.companyId;
    this.ownerId = data.ownerId;
    this.isPrivate = data.isPrivate;
    this.isHidden = data.isHidden;
    this.isDeleted = data.isDeleted;
    this.teamIds = data.teamIds;
    this.membersCount = data.membersCount;
    this.lastUpdatedUtc = data.lastUpdatedUtc;
    this.lastUpdatedBy = data.lastUpdatedBy;
    this.ownerFullName = data.ownerFullName;
    this.favorite = false;
  }

  static override transform(data: any): ListModel {
    return new ListModel(data);
  }
}
