import { AccountCardComponent } from './account-card.component';
import { BadgeModule, CardsModule } from '@ipreo/ngx-sprinkles';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [AccountCardComponent],
  imports: [CommonModule, CardsModule, BadgeModule],
  exports: [AccountCardComponent],
})
export class AccountCardModule {}
