import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountProfileCardComponent } from './account-profile-card.component';
import { CardsModule } from '@ipreo/ngx-sprinkles';
import { SkeletonLoaderModule } from '../skeleton-loader/skeleton-loader.module';

@NgModule({
  declarations: [AccountProfileCardComponent],
  imports: [CommonModule, CardsModule, SkeletonLoaderModule],
  exports: [AccountProfileCardComponent],
})
export class AccountProfileCardModule {}
