<div class="account-contact-filter-and-sort-container">
  <div class="container-content">
    <div class="header">
      <app-modal-header
        [type]="typeHeader"
        [title]="filterAndSortRecentActivitiesTitle"
        (backNavigation)="navigateToAccountInformationOrContact()"
      ></app-modal-header>
    </div>
    <div class="content">
      <app-home-filter
        [form]="form"
        [filters]="filters"
        (resetFilters)="onFiltersReset()"
        (applyFilters)="onFiltersApply()"
        (emitGeneralCheckBoxFilter)="onGeneralCheckBoxFilter($event)"
      ></app-home-filter>
    </div>
  </div>
</div>
