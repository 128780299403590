import { AbstractControl, ValidatorFn } from '@angular/forms';

/**
 * The function return the ValidatorFn for phone number
 * ^\+? - plus (optional)
 * \d+ checks that the value contains only digits
 *
 * @return ValidatorFn
 */

export function phoneNumberValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const phoneNumberPattern = /^\+?\d+$/;
    const value = control.value;

    if (value && !phoneNumberPattern.test(value)) {
      return { phoneNumber: true };
    }

    return null;
  };
}
