import { MeetingTypeModel } from './meeting-type.model';
import { ActionTypeModel } from './action-type.model';
import { BaseModel } from './base.model';

export class ActivityTypeModel extends BaseModel {
  actionTypes: ActionTypeModel[];
  meetingTypes: MeetingTypeModel[];

  constructor(data: any = {}) {
    super();
    this.actionTypes = ActionTypeModel.transformCollection(
      data.actionTypes || []
    );
    this.meetingTypes = MeetingTypeModel.transformCollection(
      data.meetingTypes || []
    );
  }

  static override transform(data: any): ActivityTypeModel {
    return new ActivityTypeModel(data);
  }
}
