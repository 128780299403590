import moment from 'moment/moment';

export const formatDateTimeWithTimezone = (
  dateTime: string,
  dateTimePattern: string,
  timezoneOffset: number
): string => {
  const originalDateTime = moment.utc(dateTime);

  originalDateTime.utcOffset(timezoneOffset);

  return originalDateTime.format(dateTimePattern);
};
