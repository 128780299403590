import { BaseModel } from './base.model';
import { IdModel } from './id.model';

export class AccountInfoModel extends BaseModel {
  id: IdModel;
  name: string;
  type: number;

  constructor(data: any = {}) {
    super();
    this.id = IdModel.transform(data.id || {});
    this.name = data.name;
    this.type = data.type;
  }

  static override transform(data: any): AccountInfoModel {
    return new AccountInfoModel(data);
  }
}
