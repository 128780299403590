import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScrollService {
  private readonly isScrolledToBottom$$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  setScrolledToBottom(value: boolean): void {
    this.isScrolledToBottom$$.next(value);
  }

  get isScrolledToBottom$(): Observable<boolean> {
    return this.isScrolledToBottom$$.asObservable();
  }

  /**
   * @description This method takes as a argument HTML element attribute ID,
   * and moving element to the top of container using scroll, in case element is out of the ViewPort
   * @param elementId
   */
  scrollIntoView(elementId: string): void {
    setTimeout(() => {
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'center',
        });
      }
    }, 300);
  }
}
