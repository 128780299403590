<section class="profile-text-card">
  <div class="profile-text-card-title-container">
    <ng-container *ngTemplateOutlet="title"></ng-container>
  </div>
  <div class="profile-text-card-text-container">
    <ng-container *ngTemplateOutlet="text"></ng-container>
  </div>
  <div class="profile-text-card-reset-text-container">
    <ng-container *ngTemplateOutlet="resetText"></ng-container>
  </div>
  <div class="profile-text-card-reset-button-container">
    <ng-container *ngTemplateOutlet="resetButton"></ng-container>
  </div>
</section>

<ng-template #title>
  <ng-content select="[slot=title]"></ng-content>
</ng-template>
<ng-template #text>
  <ng-content select="[slot=text]"></ng-content>
</ng-template>
<ng-template #resetText>
  <ng-content select="[slot=reset-text]"></ng-content>
</ng-template>
<ng-template #resetButton>
  <ng-content select="[slot=reset-button]"></ng-content>
</ng-template>
