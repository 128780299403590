import { BaseModel } from './base.model';
import { AccountEquityFocusModel } from './account-equity-focus.model';
import { AccountFixedIncomeFocusModel } from './account-fixed-income-focus.model';
import { AccountDerivativeFocusModel } from './account-derivative-focus.model';

export class AccountFocusModel extends BaseModel {
  equity: AccountEquityFocusModel[];
  fixedIncome: AccountFixedIncomeFocusModel[];
  derivative: AccountDerivativeFocusModel[];

  constructor(data: any = {}) {
    super();
    this.equity = AccountEquityFocusModel.transformCollection(
      data.equity || []
    );
    this.fixedIncome = AccountFixedIncomeFocusModel.transformCollection(
      data.fixedIncome || []
    );
    this.derivative = AccountDerivativeFocusModel.transformCollection(
      data.derivative || []
    );
  }

  static override transform(data: any): AccountFocusModel {
    return new AccountFocusModel(data);
  }
}
