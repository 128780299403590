import { Injectable } from '@angular/core';
import { TickerModel } from '../../../models/ticker.model';
import { Securities } from '../../../../workspace/page-modules/log-action/models';
import { ContactModel } from '../../../models/contact.model';
import { AccountModel } from '../../../models/account.model';
import {
  ACCOUNT_ID_REMOVED,
  ACCOUNT_ID_STORAGE_KEY,
  ACCOUNTS_STORAGE_KEY,
  ACTION_TYPE_STORAGE_KEY,
  ALERT_RECIPIENTS_STORAGE_KEY,
  CONTACT_ID_REMOVED,
  CONTACT_ID_STORAGE_KEY,
  CONTACTS_STORAGE_KEY,
  DATE_TIME_STORAGE_KEY,
  DURATION_STORAGE_KEY,
  EVENT_MEETING_ID_STORAGE_KEY,
  LOG_ACTION_BACK_ROUTE_STORAGE_KEY,
  LOG_ACTION_BACK_ROUTE_TITLE_STORAGE_KEY,
  LOG_ACTION_ID_STORAGE_KEY,
  MEETING_ID_STORAGE_KEY,
  PUBLIC_COMMENT_STORAGE_KEY,
  SECURITIES_STORAGE_KEY,
} from '@bdam/shared';
import { LogActionAlertRecipient } from '@bdam/models';

/**
 * Helper service is used to handle LogAction data inputs preserving in sessionStorage to restore them on page refresh
 */
@Injectable({
  providedIn: 'root',
})
export class LogActionStoreService {
  saveLogActionBackRouteTitle(title: string): void {
    if (title) {
      sessionStorage.setItem(LOG_ACTION_BACK_ROUTE_TITLE_STORAGE_KEY, title);
    }
  }

  restoreLogActionBackRouteTitle(): string {
    return (
      sessionStorage.getItem(LOG_ACTION_BACK_ROUTE_TITLE_STORAGE_KEY) || ''
    );
  }

  saveLogActionId(id: string): void {
    if (id) {
      sessionStorage.setItem(LOG_ACTION_ID_STORAGE_KEY, id);
    }
  }

  restoreLogActionId(): string {
    return sessionStorage.getItem(LOG_ACTION_ID_STORAGE_KEY) || '';
  }

  saveMeetingId(id: string): void {
    if (id) {
      sessionStorage.setItem(MEETING_ID_STORAGE_KEY, id);
    }
  }

  restoreMeetingId(): string {
    return sessionStorage.getItem(MEETING_ID_STORAGE_KEY) || '';
  }

  saveEventMeetingId(id: string): void {
    if (id) {
      sessionStorage.setItem(EVENT_MEETING_ID_STORAGE_KEY, id);
    }
  }

  restoreEventMeetingId(): string {
    return sessionStorage.getItem(EVENT_MEETING_ID_STORAGE_KEY) || '';
  }

  saveBackRoute(routes: string[]): void {
    if (routes?.length) {
      sessionStorage.setItem(
        LOG_ACTION_BACK_ROUTE_STORAGE_KEY,
        routes.join(',')
      );
    }
  }

  restoreBackRoute(): string[] {
    const routes =
      sessionStorage.getItem(LOG_ACTION_BACK_ROUTE_STORAGE_KEY) || '';
    return routes ? routes.split(',') : [];
  }

  saveDateTime(dateTime: string): void {
    sessionStorage.setItem(DATE_TIME_STORAGE_KEY, dateTime);
  }

  restoreDateTime(): string {
    return sessionStorage.getItem(DATE_TIME_STORAGE_KEY) || '';
  }

  saveDuration(duration: string | number): void {
    if (duration) {
      sessionStorage.setItem(DURATION_STORAGE_KEY, JSON.stringify(duration));
    }
  }

  restoreDuration(): number | undefined {
    const d = sessionStorage.getItem(DURATION_STORAGE_KEY);
    return d ? Number(d) : void 0;
  }

  saveContactId(contactId: string): void {
    const isThisContactForceRemoved =
      sessionStorage.getItem(CONTACT_ID_REMOVED);
    if (contactId && !isThisContactForceRemoved) {
      sessionStorage.setItem(CONTACT_ID_STORAGE_KEY, contactId);
    }
  }

  restoreContactId(): string {
    return sessionStorage.getItem(CONTACT_ID_STORAGE_KEY) || '';
  }

  removeContactId(): void {
    sessionStorage.removeItem(CONTACT_ID_STORAGE_KEY);
    sessionStorage.setItem(CONTACT_ID_REMOVED, 'do_not_restore');
  }

  saveAccountId(accountId: string): void {
    const isThisAccountForceRemoved =
      sessionStorage.getItem(ACCOUNT_ID_REMOVED);
    if (accountId && !isThisAccountForceRemoved) {
      sessionStorage.setItem(ACCOUNT_ID_STORAGE_KEY, accountId);
    }
  }

  restoreAccountId(): string {
    return sessionStorage.getItem(ACCOUNT_ID_STORAGE_KEY) || '';
  }

  removeAccountId(): void {
    sessionStorage.removeItem(ACCOUNT_ID_STORAGE_KEY);
    sessionStorage.setItem(ACCOUNT_ID_REMOVED, 'do_not_restore');
  }

  saveActionType(actionTypeId: number): void {
    if (actionTypeId) {
      sessionStorage.setItem(
        ACTION_TYPE_STORAGE_KEY,
        JSON.stringify(actionTypeId)
      );
    }
  }

  restoreActionType(): number | undefined {
    const actionTypeRaw = sessionStorage.getItem(ACTION_TYPE_STORAGE_KEY);

    if (actionTypeRaw) {
      return Number(JSON.parse(actionTypeRaw));
    }

    return void 0;
  }

  publicCommentChange(comment: string): void {
    sessionStorage.setItem(PUBLIC_COMMENT_STORAGE_KEY, comment);
  }

  restorePublicComment(): string {
    return sessionStorage.getItem(PUBLIC_COMMENT_STORAGE_KEY) || '';
  }

  securitiesChange(securities: TickerModel[]): void {
    if (securities) {
      sessionStorage.setItem(
        SECURITIES_STORAGE_KEY,
        JSON.stringify(securities)
      );
    }
  }

  restoreSecuritiesIDsAndGuids(): Securities {
    const result: Securities = {
      selectedSecuritiesIds: [],
      selectedSecuritiesGuids: [],
    };

    const securities: TickerModel[] = this.restoreSecurities();

    securities.forEach((s) => {
      if (s.securityId) {
        result.selectedSecuritiesIds.push(s.securityId);
      }
      if (s.securityGuid) {
        result.selectedSecuritiesGuids.push(s.securityGuid);
      }
    });

    return result;
  }

  restoreSecurities(): TickerModel[] {
    const securitiesRaw = sessionStorage.getItem(SECURITIES_STORAGE_KEY);
    if (securitiesRaw) {
      return JSON.parse(securitiesRaw);
    }

    return [];
  }

  saveAlertRecipients(recipients: LogActionAlertRecipient[]): void {
    if (recipients) {
      sessionStorage.setItem(
        ALERT_RECIPIENTS_STORAGE_KEY,
        JSON.stringify(recipients)
      );
    }
  }

  restoreAlertRecipients(): LogActionAlertRecipient[] | [] {
    const recipientsRaw = sessionStorage.getItem(ALERT_RECIPIENTS_STORAGE_KEY);

    return recipientsRaw ? JSON.parse(recipientsRaw) : [];
  }

  saveContacts(contacts: ContactModel[]): void {
    if (contacts) {
      sessionStorage.setItem(CONTACTS_STORAGE_KEY, JSON.stringify(contacts));
    }
  }

  saveAccounts(accounts: AccountModel[]): void {
    if (accounts) {
      sessionStorage.setItem(ACCOUNTS_STORAGE_KEY, JSON.stringify(accounts));
    }
  }

  restoreContacts(): ContactModel[] {
    const contactsRaw = sessionStorage.getItem(CONTACTS_STORAGE_KEY);

    return contactsRaw ? JSON.parse(contactsRaw) : [];
  }

  restoreAccounts(): AccountModel[] {
    const accountsRaw = sessionStorage.getItem(ACCOUNTS_STORAGE_KEY);

    return accountsRaw ? JSON.parse(accountsRaw) : [];
  }

  resetStore(): void {
    sessionStorage.removeItem(ACCOUNTS_STORAGE_KEY);
    sessionStorage.removeItem(CONTACT_ID_STORAGE_KEY);
    sessionStorage.removeItem(CONTACTS_STORAGE_KEY);
    sessionStorage.removeItem(PUBLIC_COMMENT_STORAGE_KEY);
    sessionStorage.removeItem(ALERT_RECIPIENTS_STORAGE_KEY);
    sessionStorage.removeItem(SECURITIES_STORAGE_KEY);
    sessionStorage.removeItem(ACTION_TYPE_STORAGE_KEY);
    sessionStorage.removeItem(CONTACT_ID_REMOVED);
    sessionStorage.removeItem(DATE_TIME_STORAGE_KEY);
    sessionStorage.removeItem(DURATION_STORAGE_KEY);
    sessionStorage.removeItem(LOG_ACTION_BACK_ROUTE_STORAGE_KEY);
    sessionStorage.removeItem(LOG_ACTION_ID_STORAGE_KEY);
    sessionStorage.removeItem(MEETING_ID_STORAGE_KEY);
    sessionStorage.removeItem(EVENT_MEETING_ID_STORAGE_KEY);
    sessionStorage.removeItem(LOG_ACTION_BACK_ROUTE_TITLE_STORAGE_KEY);
    sessionStorage.removeItem(ACCOUNT_ID_STORAGE_KEY);
    sessionStorage.removeItem(ACCOUNT_ID_REMOVED);
  }
}
