import { BaseComponent } from '../../../base/base.component';
import { TickerModel } from '../../../models/ticker.model';
import { FormControl } from '@angular/forms';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { NO_RESULTS_FOUND_MESSAGE } from '@bdam/shared';

@Component({
  selector: 'app-ticker',
  templateUrl: './ticker.component.html',
  styleUrls: ['./ticker.component.scss'],
})
export class TickerComponent extends BaseComponent implements OnChanges {
  @Input() control = new FormControl();
  @Input() showLoader!: boolean;
  @Input() appliedTickers: TickerModel[] = [];
  @Input() availableTickers: TickerModel[] = [];
  @Input() tickers: TickerModel[] = [];
  @Input() selectedTickers: TickerModel[] = [];
  @Input() placeholder = 'Search for Securities';
  @Input() headerWithArrow = false;
  @Input() actionFooter = false;

  @Output() backNavigation = new EventEmitter<boolean>();
  @Output() tickerSelect = new EventEmitter<TickerModel[]>();
  @Output() resetTickers = new EventEmitter<boolean>();
  @Output() applyTickers = new EventEmitter<boolean>();

  noResultsMessage = NO_RESULTS_FOUND_MESSAGE;

  ngOnChanges(): void {
    this.selectedTickers = [...this.selectedTickers];
  }

  onClose(): void {
    this.backNavigation.emit(true);
  }

  onTicketSelect(ticker: TickerModel): void {
    const index = this.selectedTickers.findIndex(
      (item: TickerModel) => item.securityId === ticker.securityId
    );
    index > -1
      ? this.selectedTickers.splice(index, 1)
      : this.selectedTickers.push(ticker);

    this.tickerSelect.emit(this.selectedTickers);
  }

  onReset(): void {
    this.resetTickers.emit(true);
  }

  onApply(): void {
    this.applyTickers.emit(true);
  }

  onBack(): void {
    this.backNavigation.emit(true);
  }
}
