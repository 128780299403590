import { Component, Input } from '@angular/core';
import { BaseComponent } from '../../../base/base.component';
import { Subject } from 'rxjs';
import { MODAL_DIALOG_CLOSE_EVENTS } from '../../constants';

/**
 * A modal dialog with confirmation text (configurable) and Ok, Cancel, Logout & Delete buttons (configurable)
 */
@Component({
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent extends BaseComponent {
  @Input() context!: {
    title?: string;
    text?: string;
    cancelBtnText?: string;
    okBtnText?: string;
    deleteBtnText?: string;
  };
  @Input() response!: Subject<MODAL_DIALOG_CLOSE_EVENTS>;

  onCancel(): void {
    this.response.next(MODAL_DIALOG_CLOSE_EVENTS.CANCEL);
    this.response.complete();
  }

  onOk(): void {
    this.response.next(MODAL_DIALOG_CLOSE_EVENTS.OK);
    this.response.complete();
  }
}
