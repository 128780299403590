import { BaseModel } from './base.model';

export class TickerModel extends BaseModel {
  holderCount: number;
  primaryExchangeName: string;
  primaryTickerName: string;
  securityId: number;
  securityGuid: string;
  securityName: string;
  selected: boolean;

  constructor(data: any = {}) {
    super();
    this.holderCount = data.holderCount;
    this.primaryExchangeName = data.primaryExchangeName;
    this.primaryTickerName = data.primaryTickerName;
    this.securityId = data.securityId;
    this.securityGuid = data.securityGuid;
    this.securityName = data.securityName;
    this.selected = data.selected;
  }

  static override transform(data: any): TickerModel {
    return new TickerModel(data);
  }
}
