import { AfterViewInit, Component, ElementRef, Input } from '@angular/core';
import { NgClass } from '@angular/common';

export const ACCENT_TIMEOUT = 2000; // ms

@Component({
  templateUrl: './connection-lost-overlay.component.html',
  styleUrls: ['./connection-lost-overlay.component.scss'],
  standalone: true,
  imports: [NgClass],
})
export class ConnectionLostOverlayComponent implements AfterViewInit {
  @Input() context!: {
    text: string;
  };

  accentIsShown = false;
  private modalOverlayEl!: HTMLElement;

  constructor(private readonly elRef: ElementRef) {}

  ngAfterViewInit(): void {
    this.modalOverlayEl = this.elRef.nativeElement.closest('div.c-modal');

    if (this.modalOverlayEl) {
      this.modalOverlayEl?.addEventListener('click', (e: Event) => {
        if (e.target !== this.elRef.nativeElement) {
          if (!this.accentIsShown) {
            this.accentIsShown = true;
            setTimeout(() => {
              this.accentIsShown = false;
            }, ACCENT_TIMEOUT);
          }
        }
      });
    }
  }
}
