import { BaseModel } from './base.model';
import { IdModel } from './id.model';

export class AccountIdContactsSearchModel extends BaseModel {
  id: IdModel;
  fullName: string;
  jobTitle: string;

  constructor(data: any = {}) {
    super();
    this.id = IdModel.transform(data.id || {});
    this.fullName = data.fullName;
    this.jobTitle = data.jobTitle;
  }

  static override transform(data: any): AccountIdContactsSearchModel {
    return new AccountIdContactsSearchModel(data);
  }
}
