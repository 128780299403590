import { ScrollService } from '../../../services/helpers/scroll/scroll.service';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import {
  Filter,
  FilterGeneralCheckBox,
  FilterItem,
} from '../../interfaces/home-filter.interface';
import { BaseComponent } from '../../../base/base.component';
import { FormGroup } from '@angular/forms';
import { Params } from '@angular/router';
import { EmptyObjectService } from '../../../services/helpers/empty-object/empty-object.service';

@Component({
  selector: 'app-home-filter',
  templateUrl: './home-filter.component.html',
  styleUrls: ['./home-filter.component.scss'],
})
export class HomeFilterComponent extends BaseComponent implements OnChanges {
  @Input() filters!: Filter[];
  @Input() form = new FormGroup({});

  @Output() applyFilters = new EventEmitter<boolean>();
  @Output() resetFilters = new EventEmitter<boolean>();
  @Output() emitGeneralCheckBoxFilter =
    new EventEmitter<FilterGeneralCheckBox>();

  expendedFilters: { [key: string]: boolean } = {};
  selectedMeetings: FilterItem[] = [];
  selectedLogActions: FilterItem[] = [];

  isLoggedActionsIndeterminateState = false;
  isMeetingsIndeterminateState = false;
  isMeetingsChecked = false;
  isLoggedActionsChecked = false;
  readonly loggedActionsId = 'logged_actions';
  readonly meetingsId = 'meetings';

  get selectedLogActionsLength(): number {
    return this.selectedLogActions.length;
  }

  get selectedMeetingsLength(): number {
    return this.selectedMeetings.length;
  }

  private readonly meetingTypeControlName = 'meetingTypeIds';
  private readonly actionTypeControlName = 'actionTypeIds';

  constructor(
    private scrollService: ScrollService,
    private readonly emptyObjectService: EmptyObjectService
  ) {
    super();
  }

  ngOnChanges(): void {
    this.initSelectedItemsCount();
    this.initSelectedFiltersNames();
    this.setLoggedActionsControlChanges();
    this.setMeetingsControlChanges();
  }

  onChangeLoggedActions(event: any): void {
    this.emitGeneralCheckBoxFilter.emit({
      id: this.actionTypeControlName,
      isChecked: event.target.checked,
    });

    if (this.form && this.actionTypeControlName in this.form.value) {
      this.initSelectedItemsCount();
    }

    this.setLoggedActionsControlChanges();
  }

  onChangeMeetings(event: any): void {
    this.emitGeneralCheckBoxFilter.emit({
      id: this.meetingTypeControlName,
      isChecked: event.target.checked,
    });

    if (this.form && this.meetingTypeControlName in this.form.value) {
      this.initSelectedItemsCount();
    }

    this.setMeetingsControlChanges();
  }

  initSelectedItemsCount(): void {
    if (this.form) {
      const formValue: Params = this.form.value;
      this.selectedLogActions = formValue[this.actionTypeControlName].filter(
        (item: FilterItem) => item.value
      );
      this.selectedMeetings = formValue[this.meetingTypeControlName].filter(
        (item: FilterItem) => item.value
      );
    }
  }

  initSelectedFiltersNames(): void {
    if (this.form && this.filters) {
      const formValue: Params = this.form.value;

      const dateRangeFilter = this.filters.find(
        (filter: Filter) => filter.id === 'date_range'
      );
      const dateRangeFilterItem = formValue['dateRange'].find(
        (item: FilterItem) => item.value
      );
      dateRangeFilter && this.onSelect(dateRangeFilter, dateRangeFilterItem);

      const sortingFilter = this.filters.find(
        (filter: Filter) => filter.id === 'sorting'
      );
      const sortingFilterItem = formValue['newestFirstSorting'].find(
        (item: FilterItem) => item.value
      );
      sortingFilter && this.onSelect(sortingFilter, sortingFilterItem);
    }
  }

  setLoggedActionsControlChanges(): void {
    if (!this.form) {
      return;
    }

    const formValue: Params = this.form.value;
    this.isLoggedActionsIndeterminateState = false;

    if (
      !this.emptyObjectService.isEmptyObject(formValue) &&
      formValue[this.actionTypeControlName].length ===
        this.selectedLogActions.length
    ) {
      this.isLoggedActionsChecked = true;

      return;
    }

    if (this.selectedLogActions.length >= 1) {
      this.isLoggedActionsChecked = false;
      this.isLoggedActionsIndeterminateState = true;

      return;
    }

    this.isLoggedActionsChecked = false;
  }

  setMeetingsControlChanges(): void {
    if (!this.form) {
      return;
    }

    const formValue: Params = this.form.value;
    this.isMeetingsIndeterminateState = false;

    if (
      !this.emptyObjectService.isEmptyObject(formValue) &&
      formValue[this.meetingTypeControlName].length ===
        this.selectedMeetings.length
    ) {
      this.isMeetingsChecked = true;

      return;
    }

    if (this.selectedMeetings.length >= 1) {
      this.isMeetingsChecked = false;
      this.isMeetingsIndeterminateState = true;

      return;
    }

    this.isMeetingsChecked = false;
  }

  onExpend(filter: Filter): void {
    this.expendedFilters[filter.id] = !this.expendedFilters[filter.id];
    this.scrollService.scrollIntoView(filter.id);
  }

  onSelect(filter: Filter, filterItem: FilterItem): void {
    filter.name = `${filter.selectionPrefix} (${filterItem.name})`;
  }

  onRadioChange(formGroups: FormGroup[], index: number): void {
    formGroups.forEach((group: FormGroup) =>
      group.get('value')?.patchValue(false, {
        emitEvent: false,
        onlySelf: true,
      })
    );

    formGroups[index].get('value')?.patchValue(true, {
      emitEvent: false,
      onlySelf: true,
    });
  }

  onCheckboxItemSelect(item: any, filterId: string): void {
    if (filterId === 'logged_actions') {
      const index = this.selectedLogActions.findIndex(
        (action) => action.name === item.name
      );
      index <= -1
        ? this.selectedLogActions.push(item)
        : this.selectedLogActions.splice(index, 1);

      this.setLoggedActionsControlChanges();

      return;
    }

    const index = this.selectedMeetings.findIndex(
      (meeting) => meeting.name === item.name
    );
    index <= -1
      ? this.selectedMeetings.push(item)
      : this.selectedMeetings.splice(index, 1);

    this.setMeetingsControlChanges();
  }

  onFilterApply(): void {
    this.applyFilters.emit(true);
  }

  onFilterReset(): void {
    this.resetFilters.emit(true);
  }
}
