import { map } from 'rxjs/operators';

export function transformer(annotation: {
  model: any;
  method: 'transform' | 'transformCollection';
}): any {
  return function (
    target: object,
    propertyName: string,
    PropertyDescriptor: PropertyDescriptor
  ) {
    const method = PropertyDescriptor.value;

    PropertyDescriptor.value = function (...args: any) {
      return method
        .apply(this, args)
        .pipe(
          map((res: any) =>
            annotation.model[annotation.method](res.data || res)
          )
        );
    };
  };
}
