import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../base/base.component';
import { RECENT_ACTIVITIES_CARD_TYPES } from '../../constants/recent-activitie-card.constant';
import { ActivitiesListModel } from '../../../models/activities-list.model';
import { AccountInfoModel } from '../../../models/account-info.model';

@Component({
  selector: 'app-home-card',
  templateUrl: './home-card.component.html',
  styleUrls: ['./home-card.component.scss'],
})
export class HomeCardComponent extends BaseComponent {
  @Input() type = RECENT_ACTIVITIES_CARD_TYPES.action;

  @Input() activity!: ActivitiesListModel;
  @Output() openActivity: EventEmitter<undefined> =
    new EventEmitter<undefined>();

  activityType = RECENT_ACTIVITIES_CARD_TYPES;

  shouldExistCardType(activityKey: number | undefined): boolean {
    if (activityKey === undefined) {
      return false;
    }

    const cardTypes = Object.values(RECENT_ACTIVITIES_CARD_TYPES);
    const foundCardType = cardTypes.find(
      (type: number) => type === activityKey
    );

    return !!foundCardType;
  }

  shouldExistName(accountInfoModels: AccountInfoModel[]): boolean {
    return accountInfoModels.some(
      ({ name }: AccountInfoModel) => name?.length > 0
    );
  }

  navigateToLogAction(): void {
    this.openActivity.emit();
  }
}
