import { SkeletonLoaderSizesConstant } from '../../constants/skeleton-loader-sizes.constant';
import { BaseComponent } from '../../../base/base.component';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-skeleton-loader',
  templateUrl: './skeleton-loader.component.html',
  styleUrls: ['./skeleton-loader.component.scss'],
})
export class SkeletonLoaderComponent extends BaseComponent implements OnInit {
  @Input() size!: SkeletonLoaderSizesConstant;
  @Input() numberOfRepetitions = 1;

  repetitionsArray: number[] = [];

  ngOnInit(): void {
    this.setRepetitionsArray();
  }

  private setRepetitionsArray(): void {
    this.repetitionsArray = Array(this.numberOfRepetitions)
      .fill(undefined)
      .map((item: undefined, index: number) => index);
  }
}
