import { AccountInfoModel } from './account-info.model';
import { BaseModel } from './base.model';

export class ContactModel extends BaseModel {
  crmContactID: number;
  prContactID: number;
  jobTitle: string;
  jobFunctions: string[];
  accountInfo: AccountInfoModel;
  crmFirstName: string;
  crmLastName: string;
  crmMiddleName: string;
  crmNickName: string;
  prFirstName: string;
  prLastName: string;
  covered: boolean;
  firstName: string;
  lastName: string;
  middleName: string;
  nickName: string;
  id: string;

  constructor(data: any = {}) {
    super();
    this.crmContactID = data.crmContactID;
    this.prContactID = data.prContactID;
    this.jobTitle = data.jobTitle;
    this.jobFunctions = data.jobFunctions || [];
    this.accountInfo = AccountInfoModel.transform(data.accountInfo || {});
    this.crmFirstName = data.crmFirstName;
    this.crmLastName = data.crmLastName;
    this.crmMiddleName = data.crmMiddleName;
    this.crmNickName = data.crmNickName;
    this.prFirstName = data.prFirstName;
    this.prLastName = data.prLastName;
    this.covered = data.covered;
    this.firstName = data.prFirstName || data.crmFirstName;
    this.lastName = data.prLastName || data.crmLastName;
    this.middleName = data.crmMiddleName;
    this.nickName = data.crmNickName;
    this.id = `${data.prContactID || ''}-${data.crmContactID || ''}`;
  }

  static override transform(data: any): ContactModel {
    return new ContactModel(data);
  }
}
