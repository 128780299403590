export class TimeZoneModel {
  timezone_cd: string;
  timezone_id: number;
  timezone_nm: string;
  timezone_offset: number;

  constructor(data: any = {}) {
    this.timezone_cd = data.timezone_cd;
    this.timezone_id = data.timezone_id;
    this.timezone_nm = data.timezone_nm;
    this.timezone_offset = data.timezone_offset;
  }

  static transform(data: any): TimeZoneModel {
    return new TimeZoneModel(data);
  }
}
