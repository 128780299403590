import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-account-profile-card',
  templateUrl: './account-profile-card.component.html',
  styleUrls: ['./account-profile-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountProfileCardComponent {
  @Input() accountFocus: any[] = [];
  @Input() title!: string;
  @Input() showLoader!: boolean;
}
