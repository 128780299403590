// Log Action
export const LOG_ACTION_URL = 'log-action';
export const LOG_ACTION_TYPES_URL = `${LOG_ACTION_URL}/types`;
export const LOG_ACTION_ALERTS_URL = `${LOG_ACTION_URL}/alerts`;
export const LOG_ACTION_PUBLIC_COMMENT_URL = (id: string): string =>
  `${LOG_ACTION_URL}/comments/${id}`;
export const LOG_ACTION_DELETE_URL = (id: string): string =>
  `${LOG_ACTION_URL}/${id}`;

// Activities
export const ACTIVITIES_URL = 'activities';
export const ACTIVITIES_SEARCH_URL = `${ACTIVITIES_URL}/search`;
export const ACTIVITIES_TYPES_URL = `${ACTIVITIES_URL}/types`;
export const ACTIVITIES_CONTACT_DETAILS_URL = `${ACTIVITIES_SEARCH_URL}/contact`;
export const ACTIVITIES_ACCOUNT_DETAILS_URL = `${ACTIVITIES_SEARCH_URL}/account`;
export const ACTIVITIES_LOG_ACTION_URL = (id: string): string =>
  `${ACTIVITIES_URL}/log-action/${id}`;
export const ACTIVITIES_MEETING_URL = (id: string): string =>
  `${ACTIVITIES_URL}/meeting/${id}`;

export const ACTIVITIES_EVENT_MEETING_URL = (id: string): string =>
  `${ACTIVITIES_URL}/event-meeting/${id}`;

// Accounts
export const ACCOUNT_URL = 'account';
export const ACCOUNT_BY_ID_URL = (id: string): string => `${ACCOUNT_URL}/${id}`;
export const ACCOUNT_CONTACTS_URL = (id: string): string =>
  `${ACCOUNT_BY_ID_URL(id)}/contacts`;
export const ACCOUNT_HOLDINGS_URL = (id: string): string =>
  `${ACCOUNT_BY_ID_URL(id)}/holdings`;
export const RECENTLY_VIEWED_ACCOUNTS_URL = `${ACCOUNT_URL}/by-ids`;
