import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';

import {
  LogAction,
  LogActionAlert,
  LogActionType,
  LogActionTypeDto,
  Recipient,
} from '@bdam/models';
import {
  LOG_ACTION_ALERTS_URL,
  LOG_ACTION_DELETE_URL,
  LOG_ACTION_PUBLIC_COMMENT_URL,
  ALERT_RECIPIENTS_STORAGE_KEY,
  LOG_ACTION_TYPES_URL,
  LOG_ACTION_URL,
} from '@bdam/shared';
import { BaseService } from '../../http-client/base.service';

/**
 * Log Action API calls
 */
@Injectable({
  providedIn: 'root',
})
export class LogActionService extends BaseService {
  getLogActionTypes(): Observable<LogActionType[]> {
    return this.get(
      LOG_ACTION_TYPES_URL,
      this.httpHeadersService.httpOptions
    ).pipe(map((response: LogActionTypeDto) => response?.data || []));
  }

  getAlerts(crmIds: number[], text = ''): Observable<LogActionAlert> {
    const headers: HttpHeaders = new HttpHeaders()
      .set('accept', 'text/plain')
      .set('Content-Type', 'application/json-patch+json');

    return this.post(`${LOG_ACTION_ALERTS_URL}?text=${text}`, crmIds, {
      headers,
      responseType: 'text',
    });
  }


  saveLogAction(logAction: LogAction): Observable<number> {
    const recipientsRaw = sessionStorage.getItem(ALERT_RECIPIENTS_STORAGE_KEY);
    const recipients: Recipient[] = recipientsRaw ? JSON.parse(recipientsRaw) : [];

    // Update recipients with numeric IDs to alphabetical values
    recipients.forEach((recipient: Recipient) => {
      if (typeof recipient.id === 'number') {
        const idString = (recipient.id as number).toString(); // Convert number to string
        let newId = '';
        for (let char of idString) {
          const digit = parseInt(char, 10);
          if (digit >= 1 && digit <= 9) {
            newId += String.fromCharCode(96 + digit); // 96 + digit gives ASCII values for a-i
          } else {
            newId += char; // Keep the character as is if it's not between 1 and 9
          }
        }
        recipient.id = newId;
      }
    });

    // Add recipients to the logAction model
    logAction.recipients = recipients;

    return this.post(LOG_ACTION_URL, logAction, {
      skipDefaultErrorHandler: true,
    });
  }


  deleteLogAction(loggedActionId: string): Observable<null> {
    if (loggedActionId) {
      return this.delete(LOG_ACTION_DELETE_URL(loggedActionId));
    }
    return EMPTY;
  }

  updateComment(loggedActionId: string, newComment: string): Observable<null> {
    if (!loggedActionId) {
      return EMPTY;
    }

    const httpOptions = this.httpHeadersService.httpOptions;
    httpOptions.headers = httpOptions.headers.set(
      'Content-Type',
      'application/json-patch+json'
    );
    httpOptions.headers = httpOptions.headers.set('Accept', '*/*');

    return this.post(
      LOG_ACTION_PUBLIC_COMMENT_URL(loggedActionId),
      JSON.stringify(newComment),
      httpOptions
    );
  }
}
