import { ListModalHeaderModule } from '../list-modal-header/list-modal-header.module';
import { SkeletonLoaderModule } from '../skeleton-loader/skeleton-loader.module';
import { ListAccountItemComponent } from './list-account-item.component';
import { AccountCardModule } from '../account-card/account-card.module';
import { ListCardModule } from '../list-card/list-card.module';
import { IconModule, SPRFormsModule } from '@ipreo/ngx-sprinkles';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HeaderIntersectionVisibilityModule } from '../../directives/header-intersection-visibility/header-intersection-visibility.module';

@NgModule({
  declarations: [ListAccountItemComponent],
  imports: [
    CommonModule,
    AccountCardModule,
    ListCardModule,
    ListModalHeaderModule,
    SPRFormsModule,
    ReactiveFormsModule,
    SkeletonLoaderModule,
    HeaderIntersectionVisibilityModule,
    IconModule,
  ],
  exports: [ListAccountItemComponent],
})
export class ListAccountItemModule {}
