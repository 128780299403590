import { AccountLocationModel } from './account-location.model';
import { BaseModel } from './base.model';

export class AccountModel extends BaseModel {
  crmAccountID: number;
  prCompanyID: number;
  crmAccountName: string;
  prCompanyName: string;
  accountLocation: AccountLocationModel;
  covered: boolean;
  bestTotalAssets: number;
  formattedTotalBestAssets: string;
  investorTypeId: number;
  name: string;
  id: string;

  constructor(data: any = {}) {
    super();
    this.crmAccountID = data.crmAccountID;
    this.prCompanyID = data.prCompanyID;
    this.crmAccountName = data.crmAccountName;
    this.prCompanyName = data.prCompanyName;
    this.accountLocation = AccountLocationModel.transform(
      data.accountLocation || {}
    );
    this.covered = data.covered;
    this.bestTotalAssets = data.bestTotalAssets;
    this.formattedTotalBestAssets = this.formatByUserAssetPreference(
      Math.round(data.bestTotalAssets / 1000000)
    );
    this.investorTypeId = data.investorTypeId;
    this.name = data.prCompanyName || data.crmAccountName;
    this.id = `${data.prCompanyID || ''}-${data.crmAccountID || ''}`;
  }

  isCrm(): boolean {
    return !!this.crmAccountID;
  }

  isPr(): boolean {
    return !!this.prCompanyID;
  }

  static override transform(data: any): AccountModel {
    return new AccountModel(data);
  }
}
