import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SecurityModel } from '../../../models/security.model';
import {
  NO_RESULTS_FOUND_MESSAGE,
  SEARCH_FOR_SECURITIES_PLACEHOLDER,
} from '@bdam/shared';

@Component({
  selector: 'app-holdings-security-card',
  templateUrl: './holdings-security-card.component.html',
  styleUrls: ['./holdings-security-card.component.scss'],
})
export class HoldingsSecurityCardComponent {
  @Input() control = new FormControl();
  @Input() showLoader!: boolean;
  @Input() pagingInProgress = false;
  @Input() holdingsSecurities: SecurityModel[] = [];
  @Output() navigateToHoldingsDetails = new EventEmitter<SecurityModel>();

  placeholder = SEARCH_FOR_SECURITIES_PLACEHOLDER;
  noResultsMessage = NO_RESULTS_FOUND_MESSAGE;

  detailsNavigation(data: SecurityModel): void {
    this.navigateToHoldingsDetails.emit(data);
  }
}
