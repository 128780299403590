import { Injectable } from '@angular/core';
import { SessionStorageService } from '../session-storage/session-storage.service';
import { Params } from '@angular/router';
import { ParamLengthCalculator } from '@bdam/shared';

@Injectable({
  providedIn: 'root',
})
export class FiltersService {
  private paramLengthCalculator!: ParamLengthCalculator;

  constructor(private readonly sessionStorageService: SessionStorageService) {}

  setCalculator(calculator: ParamLengthCalculator): void {
    this.paramLengthCalculator = calculator;
  }

  getIdsLength(sessionStorageKey: string): number {
    const params: Params = this.getParams(sessionStorageKey);

    if (!this.paramLengthCalculator || !params) {
      return 0;
    }

    return this.paramLengthCalculator.calculateLength(params);
  }

  private getParams(key: string): Params {
    return this.sessionStorageService.getQueryParamsFor(key);
  }
}
