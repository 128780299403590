import { Injectable } from '@angular/core';
import { Params } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService {
  setQueryParamsFor(pageName: string, params: Params): void {
    sessionStorage.setItem(pageName, JSON.stringify(params));
  }

  getQueryParamsFor(pageName: string): Params {
    const params = sessionStorage.getItem(pageName);

    return params ? JSON.parse(params) : null;
  }

  removeQueryParamsFor(pageName: string): void {
    sessionStorage.removeItem(pageName);
  }

  isSessionStorageEmpty(): boolean {
    return sessionStorage.length === 0;
  }
}
