export class UserPermissionsModel {
  functionPoints: string[];
  functionRules: string[];

  constructor(data: any = {}) {
    this.functionPoints = data.functionPoints;
    this.functionRules = data.functionRules;
  }

  static transform(data: any): UserPermissionsModel {
    return new UserPermissionsModel(data);
  }
}
