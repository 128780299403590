import { ParamLengthCalculator } from '@bdam/shared';
import { Params } from '@angular/router';

export class DefaultTickersParamLengthCalculator
  implements ParamLengthCalculator
{
  calculateLength(params: Params): number {
    const tickerIds: number[] = params as number[];

    if (tickerIds === null) {
      return 0;
    }

    if (tickerIds && tickerIds.length > 0) {
      return tickerIds.length;
    }

    return 0;
  }
}
