<section class="profile-link-card-container" [ngStyle]="{background: backgroundColor}">
  <spr-cards>
    <div class="card-area">
      <span class="card-text">{{ linkCard?.text }}</span>
      <a class="card-link" [href]="linkCard?.href" target="_blank" rel="noopener noreferrer">
        <i class="icon-{{iconLink}} icon-style"></i>
      </a>
    </div>
  </spr-cards>
</section>
