export const PUBLIC_COMMENT_STORAGE_KEY = 'snp-public-comment';
export const SECURITIES_STORAGE_KEY = 'snp-securities';
export const ACTION_TYPE_STORAGE_KEY = 'snp-action-type';
export const ALERT_RECIPIENTS_STORAGE_KEY = 'snp-alert-recipients';
export const CONTACTS_STORAGE_KEY = 'snp-contacts';
export const ACCOUNTS_STORAGE_KEY = 'snp-accounts';
export const CONTACT_ID_STORAGE_KEY = 'snp-contact-id';
export const ACCOUNT_ID_STORAGE_KEY = 'snp-account-id';
// Tells the system that Contact was removed from Contacts&Accounts list, do not restore it on refresh
export const CONTACT_ID_REMOVED = 'snp-contact-id-removed';
// Tells the system that Account was removed from Contacts&Accounts list, do not restore it on refresh
export const ACCOUNT_ID_REMOVED = 'snp-account-id-removed';

export const DATE_TIME_STORAGE_KEY = 'snp-date-time';
export const DURATION_STORAGE_KEY = 'snp-duration';

export const LOG_ACTION_BACK_ROUTE_STORAGE_KEY = 'snp-log-action-back-route';
export const LOG_ACTION_BACK_ROUTE_TITLE_STORAGE_KEY =
  'snp-log-action-back-route-title';
export const LOG_ACTION_ID_STORAGE_KEY = 'snp-log-action-id';
export const MEETING_ID_STORAGE_KEY = 'snp-meeting-id';
export const EVENT_MEETING_ID_STORAGE_KEY = 'snp-event-meeting-id';
