import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { BaseComponent } from '../../../../../base/base.component';
import { TickerModel } from '../../../../../models/ticker.model';
import { FormControl } from '@angular/forms';
import {
  debounceTime,
  distinctUntilChanged,
  Observable,
  switchMap,
  takeUntil,
} from 'rxjs';
import { GlobalSearchService } from '../../../../../services/api/v1/global-search.service';
import { TickerSearchModel } from '../../../../../models/ticker-search.model';
import { TickerGroupModel } from '../../../../../models/ticker-group.model';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NO_RESULTS_FOUND_MESSAGE } from '@bdam/shared';

@Component({
  selector: 'app-ticker-selector',
  templateUrl: './ticker-selector.component.html',
  styleUrls: ['./ticker-selector.component.scss'],
})
export class TickerSelectorComponent
  extends BaseComponent
  implements OnChanges, OnInit
{
  @Input() appliedTickers: TickerModel[] = [];
  @Input() selectedTickers: TickerModel[] = [];
  @Input() placeholder = 'Search for Securities';
  @Input() headerWithArrow = false;
  @Input() actionFooter = false;

  @Input() selectedSecuritiesIds: number[] = [];
  @Input() selectedSecuritiesGuids: string[] = [];

  @Input() selectedSecuritiesText = 'Selected Tickers';
  @Input() selectSecurityText = 'Select Ticker';

  @Input() resetButtonText = 'Reset Selection';
  @Input() applyButtonText = 'Update Selection';

  /**
   * Whether the search should be closed (with applying results) on Reset click
   */
  @Input() closeOnReset = false;

  @Output() backNavigation = new EventEmitter<boolean>();
  @Output() tickerSelect: EventEmitter<TickerModel[]> = new EventEmitter<
    TickerModel[]
  >();
  @Output() applyTickers: EventEmitter<TickerModel[]> = new EventEmitter<
    TickerModel[]
  >();

  tickers: TickerModel[] = [];
  searchControl = new FormControl();

  searchText = '';
  itemsFound = true;
  isSearchState = false;
  noResultsMessage: string = NO_RESULTS_FOUND_MESSAGE;

  constructor(
    private readonly globalSearchService: GlobalSearchService,
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) {
    super();
  }

  ngOnChanges(): void {
    this.selectedTickers = [...this.selectedTickers];
  }

  ngOnInit(): void {
    this.searchTickersByParams();
    this.searchTickersBySecuritiesIds();
    this.searchTickersByDefault();
  }

  onClose(): void {
    this.backNavigation.emit(true);
  }

  onTickerSelect(ticker: TickerModel): void {
    const appliedIndex = this.getAppliedTickerIndex(ticker);

    if (appliedIndex >= 0) {
      this.appliedTickers.splice(appliedIndex, 1);
      return;
    }

    const selectedIndex = this.getSelectedTickerIndex(ticker);

    selectedIndex >= 0
      ? this.selectedTickers.splice(selectedIndex, 1)
      : this.selectedTickers.push(ticker);
  }

  onReset(): void {
    this.tickers = this.tickers.map((t) =>
      Object.assign({}, t, { selected: false })
    );
    this.appliedTickers = [];
    this.selectedTickers = [];

    if (this.closeOnReset) {
      this.onApply();
    }
  }

  onApply(): void {
    const selected = [...this.appliedTickers, ...this.selectedTickers];
    this.applyTickers.emit(selected);
    this.closeModal();
  }

  closeModal(): void {
    this.backNavigation.emit(true);
  }

  private getSelectedTickerIndex(ticker: TickerModel): number {
    return this.selectedTickers.findIndex((item: TickerModel) => {
      if (item.securityId) {
        return item.securityId === ticker.securityId;
      } else if (item.securityGuid) {
        return item.securityGuid === ticker.securityGuid;
      }
      return false;
    });
  }

  private getAppliedTickerIndex(ticker: TickerModel): number {
    return this.appliedTickers.findIndex((item: TickerModel) => {
      if (item.securityId) {
        return item.securityId === ticker.securityId;
      } else if (item.securityGuid) {
        return item.securityGuid === ticker.securityGuid;
      }
      return false;
    });
  }

  private searchTickers(searchText = ''): Observable<TickerGroupModel> {
    this.searchText = searchText.trim();
    this.loading = true;
    const tickerSearch = {
      text: searchText || '',
      ids: this.selectedSecuritiesIds,
      guids: this.selectedSecuritiesGuids,
    };

    return this.globalSearchService.getTickers(
      tickerSearch as TickerSearchModel
    );
  }

  private handleResponse(response: TickerGroupModel): void {
    this.loading = false;
    this.tickers = response?.other || [];
    this.appliedTickers = response?.selected || [];
    this.itemsFound = this.isSearchState ? !!this.tickers.length : true;
  }

  private applyQueryParamsToUrl(value: string): void {
    if (value && value.trim()) {
      this.router.navigate([], {
        queryParams: { search: value },
        queryParamsHandling: 'merge',
      });
    }
  }

  private searchTickersByParams(): void {
    const params: Params = this.route.snapshot.queryParams;
    const searchParam = params['search'] && params['search'].trim();

    if (searchParam) {
      this.searchControl.patchValue(searchParam, {
        emitEvent: false,
        onlySelf: true,
      });

      this.isSearchState = true;

      this.searchTickers(searchParam)
        .pipe(takeUntil(this.destroy$))
        .subscribe(this.handleResponse.bind(this));
    }
  }

  private searchTickersBySecuritiesIds(): void {
    if (
      this.selectedSecuritiesIds.length ||
      this.selectedSecuritiesGuids.length
    ) {
      // preload applied securities
      this.searchTickers()
        .pipe(takeUntil(this.destroy$))
        .subscribe(this.handleResponse.bind(this));
    }
  }

  private searchTickersByDefault(): void {
    this.searchControl.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        distinctUntilChanged(),
        debounceTime(500),
        switchMap(this.searchTickers.bind(this))
      )
      .subscribe((response: TickerGroupModel): void => {
        this.isSearchState = !!this.searchControl.value.length;
        this.applyQueryParamsToUrl(this.searchControl.value);
        this.handleResponse(response);
      });
  }
}
