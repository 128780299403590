import { BaseModel } from './base.model';

export interface AccountCoverage {
  typeName: string;
  users: string[];
}

export class AccountCoverageModel extends BaseModel implements AccountCoverage {
  typeName: string;
  users: string[];

  constructor(data: any = {}) {
    super();
    this.typeName = data.typeName;
    this.users = data.users;
  }

  static override transform(data: any): AccountCoverageModel {
    return new AccountCoverageModel(data);
  }
}
