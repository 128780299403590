<div class="holding-security-item-card-container">
  <ul>
    <li *ngFor="let security of securities">
      <spr-cards (click)="onSelect(security)">
        <div class="card-content">
          <i class="icon-recentSearchTime" *ngIf="showTimeIcon"></i>
          <div>
            <div class="card-title">{{ security.securityName }}</div>
            <div class="description">
              <span>{{ security.primaryTickerName }}</span
              >, {{ security.primaryExchangeName }}
            </div>
          </div>
        </div>
      </spr-cards>
    </li>
  </ul>
</div>
