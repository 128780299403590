<div class="holdings-security-container">
  <div class="holdings-header">
    <app-modal-header
      [control]="control"
      [placeholder]="placeholder"
      [showBackNavigation]="false"
    ></app-modal-header>
  </div>

  <div class="main-container">
    <ng-container *ngIf="showLoader; else showHoldingsSecurities">
      <app-skeleton-loader
        [size]="'middle'"
        [numberOfRepetitions]="6"
      ></app-skeleton-loader>
    </ng-container>
    <ng-template #showHoldingsSecurities>
      <ng-container *ngIf="holdingsSecurities.length; else noSecurity">
        <app-holding-security-item-card
          [securities]="holdingsSecurities"
          (selected)="detailsNavigation($event)"
        ></app-holding-security-item-card>
        <app-skeleton-loader
          *ngIf="pagingInProgress"
          [size]="'middle'"
          [numberOfRepetitions]="3"
        ></app-skeleton-loader>
      </ng-container>
      <ng-template #noSecurity>
        <div
          class="text-center"
          *ngIf="holdingsSecurities && !holdingsSecurities.length"
        >
          {{ noResultsMessage }}
        </div>
      </ng-template>
    </ng-template>
  </div>
</div>
