import { environment } from '../../../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HttpHelperService {
  private restClient = environment.apiUrl;

  /**
   * @deprecated
   * @param url
   */
  getUrl(url: string): string {
    return `${this.restClient}/${url}`;
  }
}
