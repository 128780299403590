import { SKELETON_LOADER_SIZES } from '../shared/constants/skeleton-loader-sizes.constant';
import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import {
  CupcakeFlavorsExtended,
  CupcakeSizes,
  CupcakeSizesSML,
} from '@ipreo/ngx-sprinkles';
import { Paginate } from '../models/paginate';
import { Params } from '@angular/router';
import moment from 'moment';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss'],
})
export class BaseComponent implements OnDestroy {
  cupcakeSizes = CupcakeSizes;
  cupcakeSizesSML = CupcakeSizesSML;
  cupcakeFlavorsExtended = CupcakeFlavorsExtended;
  loaderSizes = SKELETON_LOADER_SIZES;
  loading = false;
  pagingInProgress = false;
  protected page = 0;
  protected pageSize = 15;
  protected destroy$ = new Subject();

  /**
   * Saves app back route when navigation between pages to know where to go back on Back Button click
   * @param storageKey
   * @param route
   * @param queryParams
   */
  setBackRoute(
    storageKey: string,
    route: string[],
    queryParams: Params | null
  ): void {
    if (!storageKey || !route?.length) {
      return;
    }

    sessionStorage.setItem(
      storageKey,
      JSON.stringify({
        route,
        queryParams,
      })
    );
  }

  getBackRoute(storageKey: string): {
    route: string[];
    queryParams?: Params | null;
  } {
    const raw = sessionStorage.getItem(storageKey);
    return raw
      ? JSON.parse(raw)
      : {
          route: [''], // Default navigation in case when URL was copy-pasted to another device, and it hasn't stored back route
        };
  }

  clearBackRoute(storageKey: string): void {
    sessionStorage.removeItem(storageKey);
  }

  trackByFn(index: number): number {
    return index;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  protected sortBy<T>(
    propertyName: string,
    order: 'asc' | 'desc',
    items: T[]
  ): T[] {
    items.sort((a: any, b: any) => {
      if (order === 'asc' ? a.name < b.name : a.name > b.name) {
        return -1;
      }
      if (order === 'asc' ? a.name > b.name : a.name < b.name) {
        return 1;
      }
      return 0;
    });

    return items;
  }

  protected scrolledToBottom(element: any, callback: any): void {
    if (
      element.scrollHeight - Math.floor(element.scrollTop) <=
      element.clientHeight + 30
    ) {
      callback();
    }
  }

  protected disableLoaders(): void {
    this.loading = false;
    this.pagingInProgress = false;
  }

  protected enableLoader(): void {
    this.pagingInProgress || (this.loading = true);
  }

  protected enablePagingLoader(): void {
    this.pagingInProgress = true;
    this.loading = false;
  }

  protected resetPaginationData(items: any[], paginate: Paginate): void {
    paginate.reset();
    items.length = 0;
  }

  protected getTickerIdsByType(ids: number[] | string[]): {
    tickerIds: number[];
    crmSecurityGuids: string[];
  } {
    const tickerIds: number[] = [];
    const crmSecurityGuids: string[] = [];

    if (!Array.isArray(ids) || ids.length === 0) {
      return { tickerIds, crmSecurityGuids };
    }

    ids.forEach((id: number | string) =>
      typeof id === 'number' ? tickerIds.push(id) : crmSecurityGuids.push(id)
    );

    return { tickerIds, crmSecurityGuids };
  }

  protected getCurrentTime(
    startDate: string,
    endDate: string
  ): { updatedStartDate: string; updatedEndDate: string } {
    const currentStartDate: moment.Moment = moment.utc(startDate);
    const currentEndDate: moment.Moment = moment.utc(endDate);
    const updatedStartDate: moment.Moment =
      this.getMomentTime(currentStartDate);
    const updatedEndDate: moment.Moment = this.getMomentTime(currentEndDate);

    return {
      updatedStartDate: updatedStartDate.format(),
      updatedEndDate: updatedEndDate.format(),
    };
  }

  private getMomentTime(date: moment.Moment): moment.Moment {
    const currentTime: moment.Moment = moment().utc();
    const diffMinutes: number = currentTime.diff(date, 'minutes');
    const diffHours: number = currentTime.diff(date, 'hours');
    const diffSeconds: number =
      currentTime.diff(date, 'seconds') - diffMinutes * 60;

    const updatedDatetime: moment.Moment = date
      .hours(date.hours() - diffHours)
      .minutes(date.minutes() + diffMinutes)
      .seconds(date.seconds() + diffSeconds);

    return updatedDatetime;
  }
}
