import { ContactModel } from './contact.model';
import { AccountModel } from './account.model';
import { BaseModel } from './base.model';

export class GlobalSearchModel extends BaseModel {
  contacts: ContactModel[];
  accounts: AccountModel[];
  totalContactsCount: number;
  totalInstitutionsCount: number;
  contactsCount: number;
  accountsCount: number;

  constructor(data: any = {}) {
    super();
    this.contacts = ContactModel.transformCollection(data.contacts || []);
    this.accounts = AccountModel.transformCollection(data.institutions || []);
    this.totalContactsCount = data.totalContactsCount;
    this.totalInstitutionsCount = data.totalInstitutionsCount;
    this.contactsCount = data.totalContactsCount;
    this.accountsCount = data.totalInstitutionsCount;
  }

  static override transform(data: any): GlobalSearchModel {
    return new GlobalSearchModel(data);
  }
}
