import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class QueryParamsFromUrlService {
  getQueryParamsFromUrl(queryParamsFromUrl: string): { [p: string]: any } {
    const params: URLSearchParams = new URLSearchParams(queryParamsFromUrl);

    return Object.fromEntries(params.entries());
  }
}
