import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

export const GOOGLE_MAP_URL = 'https://www.google.com/maps/search/?api=1';
export const APPLE_MAP_URL = 'https://maps.apple.com';

/**
 * A component for representing an address with ability to open that address on a map
 */
@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class AddressComponent {
  /**
   * Address to be shown to the user and used for opening Google Maps or Apple Maps
   */
  @Input() address = '';
  /**
   * Ability to open map with provided address
   */
  @Input() showOnMap = true;

  openMap(): void {
    const addr = encodeURIComponent(this.address);
    if (this.iOS()) {
      window.open(`${APPLE_MAP_URL}/?q=${addr}`, '_system');
    } else {
      window.open(`${GOOGLE_MAP_URL}&query=${addr}`, '_system');
    }
  }

  getNavigatorRef(): Partial<Navigator> {
    return window.navigator;
  }

  private iOS(): boolean {
    const navigatorRef = this.getNavigatorRef();
    if (navigatorRef?.platform || navigatorRef?.userAgent) {
      return (
        [
          'iPad Simulator',
          'iPhone Simulator',
          'iPod Simulator',
          'iPad',
          'iPhone',
          'iPod',
        ].includes(navigatorRef.platform || '') ||
        // iPad on iOS 13 detection
        (navigatorRef.userAgent?.includes('Mac') && 'ontouchend' in document) ||
        false
      );
    }

    return false;
  }
}
