import { TopHoldingsCardComponent } from './top-holdings-card.component';
import { BadgeModule, CardsModule } from '@ipreo/ngx-sprinkles';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [TopHoldingsCardComponent],
  imports: [CommonModule, CardsModule, BadgeModule],
  exports: [TopHoldingsCardComponent],
})
export class TopHoldingsCardModule {}
