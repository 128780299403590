import { AccountIdContactsSearchModel } from '../../../models/account-id-contacts-search-model';
import { AccountMemberModel } from '../../../models/account-member.model';
import { transformer } from '../../../shared/decorators/transformer';
import { HoldingsModel } from '../../../models/holdings.model';
import { BaseService } from '../../http-client/base.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
  AccountDetailsModel,
  AccountFocusModel,
  ListModel,
  RecentlyViewedAccount,
  RecentlyViewedAccountId,
} from '@bdam/models';

import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import {
  ACCOUNT_BY_ID_URL,
  ACCOUNT_CONTACTS_URL,
  ACCOUNT_HOLDINGS_URL,
  RECENTLY_VIEWED_ACCOUNTS_URL,
} from '@bdam/shared';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AccountService extends BaseService {
  loadNextPage = new Subject<boolean>();

  accountDetails = new BehaviorSubject<AccountDetailsModel>(
    {} as AccountDetailsModel
  );
  loadNextPage$ = this.loadNextPage.asObservable();
  accountDetails$ = this.accountDetails.asObservable();

  @transformer({
    model: ListModel,
    method: 'transformCollection',
  })
  getAccountLists(params: Params): Observable<ListModel[]> {
    return this.get('account-lists/search', params);
  }

  @transformer({
    model: AccountMemberModel,
    method: 'transform',
  })
  getAccountListMembers(params: Params): Observable<AccountMemberModel> {
    return this.get('account-lists/members', params);
  }

  @transformer({
    model: AccountDetailsModel,
    method: 'transform',
  })
  getAccount(id: string): Observable<AccountDetailsModel> {
    return this.get(ACCOUNT_BY_ID_URL(id));
  }

  getFavoriteLst(params: Params): Observable<number[]> {
    return this.get('user-favourite-list', params);
  }

  addListToFavorites(id: number): Observable<null> {
    return this.post('user-favourite-list', { listId: id });
  }

  deleteFromTheFavorites(id: number): Observable<null> {
    return this.delete(`user-favourite-list?listId=${id}`);
  }

  @transformer({
    model: AccountIdContactsSearchModel,
    method: 'transformCollection',
  })
  getAccountContactsList(
    id: string,
    params: Params
  ): Observable<AccountIdContactsSearchModel[]> {
    return this.get(ACCOUNT_CONTACTS_URL(id), params);
  }

  @transformer({
    model: HoldingsModel,
    method: 'transformCollection',
  })
  getAccountHoldings(
    prId: string,
    params: Params
  ): Observable<HoldingsModel[]> {
    return this.get(ACCOUNT_HOLDINGS_URL(prId), params);
  }

  getRecentlyViewedAccounts(
    ids: RecentlyViewedAccountId[]
  ): Observable<RecentlyViewedAccount[]> {
    return this.post(RECENTLY_VIEWED_ACCOUNTS_URL, ids).pipe(
      map((res) => res.data)
    );
  }

  onLoadNextPage(value: boolean): void {
    this.loadNextPage.next(value);
  }

  setAccountDetails(accountDetails: AccountDetailsModel): void {
    this.accountDetails.next(accountDetails);
  }

  @transformer({
    model: AccountFocusModel,
    method: 'transform',
  })
  getAccountFocus(id: number): Observable<AccountFocusModel> {
    return this.get(`account/${id}/focus`);
  }
}
