import { ComponentRef, Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { ModalComponent, ModalService, ModalType } from '@ipreo/ngx-sprinkles';
import {
  CONNECTION_LOST_MESSAGE,
  ConnectionLostOverlayComponent,
} from '@bdam/shared';

const HEALTH_CHECK_INTERVAL = 10000; // ms
const HEALTH_CHECK_PENDING_TIMEOUT = 2000; // ms

@Injectable({
  providedIn: 'root',
})
export class HealthCheckService {
  private healthCheckIntervalId!: any | null;
  private modal: ComponentRef<ModalComponent> | null = null;

  constructor(private readonly modalService: ModalService) {}

  runHealthCheck(): void {
    if (!this.healthCheckIntervalId) {
      this.healthCheckIntervalId = setInterval(async () => {
        this.checkOnlineStatusHandler();
      }, HEALTH_CHECK_INTERVAL);
    }
  }

  showNotification(message: string): void {
    // Blur active inputs to prevent continue typing when screen locked
    (document.activeElement as HTMLElement)?.blur();

    this.modal = this.modalService.create({
      type: ModalType.View,
      view: ConnectionLostOverlayComponent,
      showOverlay: false,
      closeOnOverlayClick: false,
      context: {
        text: message,
      },
    });
  }

  private checkOnlineStatusHandler(): void {
    this.checkOnlineStatus().then((isConnected: boolean) => {
      if (isConnected && this.modal) {
        this.modal.destroy();
        this.modal = null;
        location.reload();
      } else {
        if (!isConnected && !this.modal) {
          this.showNotification(CONNECTION_LOST_MESSAGE);
        }
      }
    });
  }

  private async checkOnlineStatus(): Promise<boolean> {
    try {
      const controller = new AbortController();
      const id = setTimeout(() => {
        controller.abort();
      }, HEALTH_CHECK_PENDING_TIMEOUT);

      const online = await fetch(environment.baseUrl + '/mobile/appinfo', {
        method: 'GET',
        signal: controller.signal,
        credentials: 'include',
      });

      clearTimeout(id);

      return online.status >= 200 && online.status < 300;
    } catch (e) {
      return false;
    }
  }
}
