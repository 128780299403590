import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

/**
 * @deprecated
 */
@Injectable({
  providedIn: 'root',
})
export class HttpHeadersService {
  private _httpOptions = {
    headers: new HttpHeaders({}),
    params: new HttpParams(),
    withCredentials: true,
  };

  get httpOptions(): { headers: HttpHeaders; params: HttpParams } {
    return this._httpOptions;
  }

  setAuth(token: string): void {
    this._httpOptions.headers = this.httpOptions.headers.set(
      'Authorization',
      `Bearer ${token}`
    );
  }

  removeAuth(): void {
    this._httpOptions.headers =
      this.httpOptions.headers.delete('Authorization');
  }
}
