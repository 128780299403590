import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountContactFilterAndSortComponent } from './account-contact-filter-and-sort.component';
import { HomeFilterModule } from '../home-filter/home-filter.module';
import { ModalHeaderModule } from '../modal-header/modal-header.module';

@NgModule({
  declarations: [AccountContactFilterAndSortComponent],
  imports: [CommonModule, HomeFilterModule, ModalHeaderModule],
  exports: [AccountContactFilterAndSortComponent],
})
export class AccountContactFilterAndSortModule {}
