<div class="header">
  <i *ngIf="showBackNavigation" class="icon-back" (click)="navigateBack()"></i>

  <ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="types.searchHeader">
      <div class="search-header">
          <spr-input
            style="flex: 1;"
            [prefixIcon]="'search'"
            [type]="'text'"
            [size]="cupcakeSizesSML.lg"
            [formControl]="control"
            [placeholder]="placeholder">
          </spr-input>

          <spr-icon
            class="clear-icon"
            *ngIf="control.value"
            (click)="onReset()"
            [name]="'times-circle'"
            [size]="cupcakeSizes.sm"></spr-icon>
      </div>

      <spr-icon
        *ngIf="withCloseIcon"
        (click)="navigateBack()"
        name="times"
        [size]="cupcakeSizes.sm"
      ></spr-icon>
    </ng-container>

    <ng-container *ngSwitchCase="types.textHeader">
      <div class="title">{{ title }}</div>
    </ng-container>
  </ng-container>
</div>


