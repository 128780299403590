import { GlobalSearchModel } from '../../../models/global-search.model';
import { TickerSearchModel } from '../../../models/ticker-search.model';
import { TickerGroupModel } from '../../../models/ticker-group.model';
import { transformer } from '../../../shared/decorators/transformer';
import { ContactModel } from '../../../models/contact.model';
import { BaseService } from '../../http-client/base.service';
import { AccountModel } from '../../../models/account.model';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GlobalSearchService extends BaseService {
  @transformer({
    model: GlobalSearchModel,
    method: 'transform',
  })
  getContactsAndAccounts(params: Params): Observable<GlobalSearchModel> {
    return this.get('quick-search', params);
  }

  @transformer({
    model: GlobalSearchModel,
    method: 'transform',
  })
  getCoveredContactsAndAccounts(params: Params): Observable<GlobalSearchModel> {
    return this.get('quick-search/covered', params);
  }

  @transformer({
    model: ContactModel,
    method: 'transformCollection',
  })
  getContacts(
    params: Params
  ): Observable<{ items: ContactModel[]; totalContacts: number }> {
    return this.get('quick-search/contacts', params);
  }

  @transformer({
    model: ContactModel,
    method: 'transformCollection',
  })
  getCoveredContacts(
    params: Params
  ): Observable<{ items: ContactModel[]; totalContacts: number }> {
    return this.get('quick-search/contacts/covered', params);
  }

  @transformer({
    model: AccountModel,
    method: 'transformCollection',
  })
  getAccounts(
    params: Params
  ): Observable<{ items: AccountModel[]; totalAccounts: number }> {
    return this.get('quick-search/accounts', params);
  }

  @transformer({
    model: AccountModel,
    method: 'transformCollection',
  })
  getCoveredAccounts(
    params: Params
  ): Observable<{ items: AccountModel[]; totalAccounts: number }> {
    return this.get('quick-search/accounts/covered', params);
  }

  @transformer({
    model: TickerGroupModel,
    method: 'transform',
  })
  getTickers(params: TickerSearchModel): Observable<TickerGroupModel> {
    return this.post('quick-search/tickers', params);
  }
}
