import { LinkCard } from '../interfaces/link-card';
import { CountryDetails } from '../interfaces/country-details';

export const SUPPORT_URL = 'support';
export const TERMS_OF_USE: LinkCard = {
  text: 'Terms of Use',
  href: 'https://www.spglobal.com/en/enterprise/Legal/gcp-products-terms-of-use.html',
};
export const PRIVACY_POLICY: LinkCard = {
  text: 'Privacy Policy',
  href: 'https://www.spglobal.com/en/privacy/privacy-policy-english',
};
export const COUNTRY_DETAILS: CountryDetails[] = [
  {
    countryName: 'Americas',
    phoneNumber: '+1 800 254-1005',
  },
  {
    countryName: 'Europe',
    phoneNumber: '+44 20 7665 9820',
  },
  {
    countryName: 'Asia Pacific',
    phoneNumber: '+852 3742 1066',
  },
  {
    countryName: 'Japan',
    phoneNumber: '+81 3 4579 5698',
  },
];
export const ADD_YOUR_EMAIL_PLACEHOLDER = 'name@email.com';
export const MARKET_CAP_KEY = 'Market Cap';
export const FOCUS_ORDER_TEMPLATE_FOR_CONTACTS: string[] = [
  'Style',
  'Strategy',
  'Orientation',
  'Market Cap',
  'Industry Macro',
  'Industry Mid',
  'Industry Micro',
  'Region',
  'Country',
  'EQ Instrument',
];

export const FOCUS_ORDER_TEMPLATE_FOR_ACCOUNTS: string[] = [
  'Style',
  'Strategy',
  'Orientation',
  'Market Cap',
  'Industry Macro',
  'Industry Mid',
  'Industry',
  'Region',
  'Country',
  'EQ Instrument',
];

export const FIXED_INCOME_FOCUS: string[] = [
  'Title',
  'FI Instrument',
  'SP Rating Value',
  'Maturity Value',
  'Country',
  'Region',
  'Orientation',
  'Currency',
  'Industry Macro',
  'Industry Mid',
  'Industry',
];

export const DERIVATIVE_FOCUS: string[] = [
  'Title',
  'Derivative Product',
  'Derivative Strategy',
  'Contract Type',
  'Commodity',
  'Region',
  'Country',
  'Orientation',
  'FI Instrument',
  'Currency',
  'SP Rating Value',
  'Moodys Rating',
  'Market Cap',
  'Industry Macro',
  'Industry Mid',
  'Industry',
];
