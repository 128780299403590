import { HoldingSecurityModel } from './holding-security.model';
import { BaseModel } from './base.model';
import { UserModel } from './user.model';
import { IdModel } from './id.model';
import moment from 'moment';
import { formatCurrencyNumber } from '@bdam/shared';

export class HoldingsModel extends BaseModel {
  id: IdModel;
  contactId: number;
  companyName: string;
  isAmountNegative: boolean;
  isQuantityNegative: boolean;
  amount: string;
  originalAmount: number;
  quarterlyChangeHoldingAmount: number;
  quantity: string;
  originalQuantity: string;
  quarterlyChangeHoldingQuantity: string;
  holdingDateTime: string;
  holderRank: number;
  portfolioRank: number;
  security: HoldingSecurityModel;
  currency: string;

  constructor(data: any = {}) {
    super();
    this.id = IdModel.transform(data.id);
    this.contactId = data.contactId;
    this.companyName = data.companyName;
    this.isAmountNegative = data.quarterlyChangeHoldingAmount < 0;
    this.isQuantityNegative = data.quarterlyChangeHoldingQuantity < 0;
    this.amount = data.amount ? this.formatCurrencyForValue(data.amount) : '0';
    this.originalAmount = data.quarterlyChangeHoldingAmount;
    this.quarterlyChangeHoldingAmount = data.quarterlyChangeHoldingAmount;
    this.quantity = data.quantity ? this.formatCurrency(data.quantity) : '0';
    this.originalQuantity = data.quarterlyChangeHoldingQuantity;
    this.quarterlyChangeHoldingQuantity = data.quarterlyChangeHoldingQuantity
      ? this.formatCurrency(Math.abs(data.quarterlyChangeHoldingQuantity))
      : '0';
    this.holdingDateTime = data.holdingDateTime
      ? this.formatByUserTimePreference(data.holdingDateTime)
      : '';
    this.holderRank = data.holderRank;
    this.portfolioRank = data.portfolioRank;
    this.security = HoldingSecurityModel.transform(data.security);
    this.currency = this.getCurrency();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static override transform(data: any): HoldingsModel {
    return new HoldingsModel(data);
  }

  protected override formatByUserTimePreference(dateTime: string): string {
    const authUser: UserModel = this.getAuthUserModel();

    return moment(dateTime).format(authUser.userPreferences.shortDatePattern);
  }

  private formatCurrency(value: number): string {
    const formatter: Intl.NumberFormat = new Intl.NumberFormat(
      this.getCulture()
    );

    return formatter.format(value);
  }

  private formatCurrencyForValue(value: number): string {
    return formatCurrencyNumber(value, this.getCulture());
  }

  private getCurrency(): string {
    const authUser: UserModel = this.getAuthUserModel();

    return authUser.userPreferences.currency;
  }

  private getCulture(): string {
    const authUser: UserModel = this.getAuthUserModel();

    return authUser.userPreferences.culture;
  }

  private getAuthUserModel(): UserModel {
    return new UserModel(this.userJson);
  }
}
