<ng-container *ngFor="let rep of repetitionsArray; trackBy: trackByFn">
  <div class="preloader-container" [class.big-size]="size === loaderSizes.big">
    <ngx-skeleton-loader
      class="skeleton-loader-mid"
      [theme]="{
        'margin-bottom': '-4px',
        'border-radius': '4px',
        height: size === loaderSizes.big ? '24px' : '20px',
        width:'45%',
        'background': 'linear-gradient(90deg, #DFDFDF 0%, #F3F3F3 100%)'
      }">
    </ngx-skeleton-loader>

    <ngx-skeleton-loader
      class="skeleton-loader-small"
      [theme]="{
        'margin-bottom': '-4px',
        'border-radius': '4px',
        height: '20px',
        width: '35%',
        'background': 'linear-gradient(90deg, #DFDFDF 0%, #F3F3F3 100%)'
      }">
    </ngx-skeleton-loader>

    <ngx-skeleton-loader
      class="skeleton-loader.progress"
      [theme]="{
        'margin-bottom': '-4px',
        'border-radius': '4px',
        height: size === loaderSizes.big ? '40px' : '20px',
        width:'100%',
        'background': 'linear-gradient(90deg, #DFDFDF 0%, #F3F3F3 100%)'
      }">
    </ngx-skeleton-loader>
  </div>
</ng-container>
